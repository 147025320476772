    /*
Template Name: Admin Template
Author: Niravjoshi / Wrappixel
File: scss
*/
    /**
 * Table Of Content
 *
 *  1. Color system
 *  2. Options
 *  3. Body
 *  4. Typography
 *  5. Breadcrumbs
 *  6. Cards
 *  7. Dropdowns
 *  8. Buttons
 *  9. Typography
 *  10. Progress bars
 *  11. Tables
 *  12. Forms
 *  14. Component
 */
    
    @import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800");
    /*Theme Colors*/
    /*Topbar Colors*/
    
    @import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800");
    @import url(all/icons/font-awesome/css/fontawesome-all.css);
    @import url(all/icons/simple-line-icons/css/simple-line-icons.css);
    @import url(all/icons/weather-icons/css/weather-icons.min.css);
    @import url(all/icons/themify-icons/themify-icons.css);
    @import url(all/icons/material-design-iconic-font/css/materialdesignicons.min.css);
    .topbar {
        box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.05);
        -webkit-box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.05);
        -o-box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.05);
    }
    /*Sidebar Colors*/
    /*Boxed layout width*/
    /*Shadow*/
    /* transitions */
    /* Dark transparent bg */
    
    .search-box .app-search .form-control,
    .topbar .mega-dropdown .dropdown-menu {
        border-radius: 0px;
    }
    
    .topbar .dropdown-menu .with-arrow>span {
        transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
    }
    
    #sdv-container {
        margin-top: 168px;
    }
    
    #calcularDiv {
        height: 100px;
        /*padding-top: 40px;*/
        text-align: center;
        /*padding-right: 20px;*/
    }
    
    #calcular {
        background-color: #44c767;
        border-radius: 28px;
        border: 1px solid #18ab29;
        display: inline-block;
        cursor: pointer;
        color: #ffffff;
        font-family: Arial;
        font-size: 17px;
        padding: 16px 31px;
        text-decoration: none;
        text-shadow: 0px 1px 0px #2f6627;
    }
    
    #sdv-container-controls {
        margin: 10px 10px 40px 10px;
        flex-basis: 238px;
        position: relative;
        border: 2px solid black;
    }
    /* ***************************** */
    /******************************
/* Color system*/
    
    .card {
        box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
        -webkit-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
        -moz-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
        -o-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
    }
    /*!
* Bootstrap v4.1.3 (https://getbootstrap.com/)
* Copyright 2011-2018 The Bootstrap Authors
* Copyright 2011-2018 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
*/
    
     :root {
        --blue: #007bff;
        --indigo: #6610f2;
        --purple: #6f42c1;
        --pink: #e83e8c;
        --red: #dc3545;
        --orange: #fd7e14;
        --yellow: #ffc107;
        --green: #28a745;
        --teal: #20c997;
        --cyan: #4fc3f7;
        --white: #fff;
        --gray: #8898aa;
        --gray-dark: #32325d;
        --primary: #5e72e4;
        --secondary: #8898aa;
        --success: #2dce89;
        --info: #2c6daa;
        --warning: #fb6340;
        --danger: #f62d51;
        --light: #f6f9fc;
        --dark: #32325d;
        --cyan: #4fc3f7;
        --orange: #fb6340;
        --purple: #5e72e4;
        --inverse: #32325d;
        --breakpoint-xs: 0;
        --breakpoint-sm: 576px;
        --breakpoint-md: 800px;
        --breakpoint-lg: 992px;
        --breakpoint-xl: 1200px;
        --breakpoint-xlg: 1600px;
        --font-family-sans-serif: "Nunito Sans", sans-serif;
        --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    }
    
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }
    
    html {
        font-family: sans-serif;
        line-height: 1.15;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        -ms-overflow-style: scrollbar;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    
    @-ms-viewport {
        width: device-width;
    }
    
    article,
    aside,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    nav,
    section {
        display: block;
    }
    
    body {
        margin: 0;
        font-family: "Nunito Sans", sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        color: #3e5569;
        text-align: left;
        background-color: #eef5f9;
    }
    
    [tabindex="-1"]:focus {
        outline: 0 !important;
    }
    
    hr {
        box-sizing: content-box;
        height: 0;
        overflow: visible;
    }
    
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 0;
        margin-bottom: 0.5rem;
    }
    
    p {
        margin-top: 0;
        margin-bottom: 1rem;
    }
    
    abbr[title],
    abbr[data-original-title] {
        text-decoration: underline;
        text-decoration: underline dotted;
        cursor: help;
        border-bottom: 0;
    }
    
    address {
        margin-bottom: 1rem;
        font-style: normal;
        line-height: inherit;
    }
    
    ol,
    ul,
    dl {
        margin-top: 0;
        margin-bottom: 1rem;
    }
    
    ol ol,
    ul ul,
    ol ul,
    ul ol {
        margin-bottom: 0;
    }
    
    dt {
        font-weight: 800;
    }
    
    dd {
        margin-bottom: .5rem;
        margin-left: 0;
    }
    
    blockquote {
        margin: 0 0 1rem;
    }
    
    dfn {
        font-style: italic;
    }
    
    b,
    strong {
        font-weight: bolder;
    }
    
    small {
        font-size: 80%;
    }
    
    sub,
    sup {
        position: relative;
        font-size: 75%;
        line-height: 0;
        vertical-align: baseline;
    }
    
    sub {
        bottom: -.25em;
    }
    
    sup {
        top: -.5em;
    }
    
    a {
        color: #5e72e4;
        text-decoration: none;
        background-color: transparent;
        -webkit-text-decoration-skip: objects;
    }
    
    a:hover {
        color: #233dd2;
        text-decoration: none;
    }
    
    a:not([href]):not([tabindex]) {
        color: inherit;
        text-decoration: none;
    }
    
    a:not([href]):not([tabindex]):hover,
    a:not([href]):not([tabindex]):focus {
        color: inherit;
        text-decoration: none;
    }
    
    a:not([href]):not([tabindex]):focus {
        outline: 0;
    }
    
    pre,
    code,
    kbd,
    samp {
        font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
        font-size: 1em;
    }
    
    pre {
        margin-top: 0;
        margin-bottom: 1rem;
        overflow: auto;
        -ms-overflow-style: scrollbar;
    }
    
    figure {
        margin: 0 0 1rem;
    }
    
    img {
        vertical-align: middle;
        border-style: none;
    }
    
    svg {
        overflow: hidden;
        vertical-align: middle;
    }
    
    table {
        border-collapse: collapse;
    }
    
    caption {
        padding-top: 1rem;
        padding-bottom: 1rem;
        color: #8898aa;
        text-align: left;
        caption-side: bottom;
    }
    
    th {
        text-align: inherit;
    }
    
    label {
        display: inline-block;
        margin-bottom: 0.5rem;
    }
    
    button {
        border-radius: 0;
    }
    
    button:focus {
        outline: 1px dotted;
        outline: 5px auto -webkit-focus-ring-color;
    }
    
    input,
    button,
    select,
    optgroup,
    textarea {
        margin: 0;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
    }
    
    button,
    input {
        overflow: visible;
    }
    
    button,
    select {
        text-transform: none;
    }
    
    button,
    html [type="button"],
    [type="reset"],
    [type="submit"] {
        -webkit-appearance: button;
    }
    
    button::-moz-focus-inner,
    [type="button"]::-moz-focus-inner,
    [type="reset"]::-moz-focus-inner,
    [type="submit"]::-moz-focus-inner {
        padding: 0;
        border-style: none;
    }
    
    input[type="radio"],
    input[type="checkbox"] {
        box-sizing: border-box;
        padding: 0;
    }
    
    input[type="date"],
    input[type="time"],
    input[type="datetime-local"],
    input[type="month"] {
        -webkit-appearance: listbox;
    }
    
    textarea {
        overflow: auto;
        resize: vertical;
    }
    
    fieldset {
        min-width: 0;
        padding: 0;
        margin: 0;
        border: 0;
    }
    
    legend {
        display: block;
        width: 100%;
        max-width: 100%;
        padding: 0;
        margin-bottom: .5rem;
        font-size: 1.5rem;
        line-height: inherit;
        color: inherit;
        white-space: normal;
    }
    
    progress {
        vertical-align: baseline;
    }
    
    [type="number"]::-webkit-inner-spin-button,
    [type="number"]::-webkit-outer-spin-button {
        height: auto;
    }
    
    [type="search"] {
        outline-offset: -2px;
        -webkit-appearance: none;
    }
    
    [type="search"]::-webkit-search-cancel-button,
    [type="search"]::-webkit-search-decoration {
        -webkit-appearance: none;
    }
    
     ::-webkit-file-upload-button {
        font: inherit;
        -webkit-appearance: button;
    }
    
    output {
        display: inline-block;
    }
    
    summary {
        display: list-item;
        cursor: pointer;
    }
    
    template {
        display: none;
    }
    
    [hidden] {
        display: none !important;
    }
    
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        margin-bottom: 0.5rem;
        font-family: inherit;
        font-weight: 700;
        line-height: 1.2;
        color: inherit;
    }
    
    h1,
    .h1 {
        font-size: 36px;
    }
    
    h2,
    .h2 {
        font-size: 30px;
    }
    
    h3,
    .h3 {
        font-size: 24px;
    }
    
    h4,
    .h4 {
        font-size: 18px;
    }
    
    h5,
    .h5 {
        font-size: 16px;
    }
    
    h6,
    .h6 {
        font-size: 14px;
    }
    
    .lead {
        font-size: 1.09375rem;
        font-weight: 300;
    }
    
    .display-1 {
        font-size: 6rem;
        font-weight: 300;
        line-height: 1.2;
    }
    
    .display-2 {
        font-size: 5.5rem;
        font-weight: 300;
        line-height: 1.2;
    }
    
    .display-3 {
        font-size: 4.5rem;
        font-weight: 300;
        line-height: 1.2;
    }
    
    .display-4 {
        font-size: 3.5rem;
        font-weight: 300;
        line-height: 1.2;
    }
    
    hr {
        margin-top: 1rem;
        margin-bottom: 1rem;
        border: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
    
    small,
    .small {
        font-size: 80%;
        font-weight: 400;
    }
    
    mark,
    .mark {
        padding: 0.2em;
        background-color: #fcf8e3;
    }
    
    .list-unstyled {
        padding-left: 0;
        list-style: none;
    }
    
    .list-inline {
        padding-left: 0;
        list-style: none;
    }
    
    .list-inline-item {
        display: inline-block;
    }
    
    .list-inline-item:not(:last-child) {
        margin-right: 0.5rem;
    }
    
    .initialism {
        font-size: 90%;
        text-transform: uppercase;
    }
    
    .blockquote {
        margin-bottom: 1rem;
        font-size: 1.09375rem;
    }
    
    .blockquote-footer {
        display: block;
        font-size: 80%;
        color: #8898aa;
    }
    
    .blockquote-footer::before {
        content: "\2014 \00A0";
    }
    
    .img-fluid {
        max-width: 100%;
        height: auto;
    }
    
    .img-thumbnail {
        padding: 0.25rem;
        background-color: #eef5f9;
        border: 1px solid #dee2e6;
        border-radius: 2px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
        max-width: 100%;
        height: auto;
    }
    
    .figure {
        display: inline-block;
    }
    
    .figure-img {
        margin-bottom: 0.5rem;
        line-height: 1;
    }
    
    .figure-caption {
        font-size: 90%;
        color: #8898aa;
    }
    
    code {
        font-size: 87.5%;
        color: #e83e8c;
        word-break: break-word;
    }
    
    a>code {
        color: inherit;
    }
    
    kbd {
        padding: 0.2rem 0.4rem;
        font-size: 87.5%;
        color: #fff;
        background-color: #212529;
        border-radius: 1px;
        box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25);
    }
    
    kbd kbd {
        padding: 0;
        font-size: 100%;
        font-weight: 800;
        box-shadow: none;
    }
    
    pre {
        display: block;
        font-size: 87.5%;
        color: #212529;
    }
    
    pre code {
        font-size: inherit;
        color: inherit;
        word-break: normal;
    }
    
    .pre-scrollable {
        max-height: 340px;
        overflow-y: scroll;
    }
    
    .container {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
    
    @media (min-width: 576px) {
        .container {
            max-width: 540px;
        }
    }
    
    @media (min-width: 800px) {
        .container {
            max-width: 768px;
        }
    }
    
    @media (min-width: 992px) {
        .container {
            max-width: 960px;
        }
    }
    
    @media (min-width: 1200px) {
        .container {
            max-width: 1140px;
        }
    }
    
    .container-fluid {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
    
    .row {
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }
    
    .no-gutters {
        margin-right: 0;
        margin-left: 0;
    }
    
    .no-gutters>.col,
    .no-gutters>[class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }
    
    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12,
    .col,
    .col-auto,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm,
    .col-sm-auto,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md,
    .col-md-auto,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg,
    .col-lg-auto,
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl,
    .col-xl-auto,
    .col-xlg-1,
    .col-xlg-2,
    .col-xlg-3,
    .col-xlg-4,
    .col-xlg-5,
    .col-xlg-6,
    .col-xlg-7,
    .col-xlg-8,
    .col-xlg-9,
    .col-xlg-10,
    .col-xlg-11,
    .col-xlg-12,
    .col-xlg,
    .col-xlg-auto {
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }
    
    .col {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    
    .col-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }
    
    .col-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    
    .col-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    
    .col-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    
    .col-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    
    .col-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    
    .col-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    
    .col-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    
    .col-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    
    .col-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    
    .col-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    
    .col-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    
    .col-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    
    .order-first {
        order: -1;
    }
    
    .order-last {
        order: 13;
    }
    
    .order-0 {
        order: 0;
    }
    
    .order-1 {
        order: 1;
    }
    
    .order-2 {
        order: 2;
    }
    
    .order-3 {
        order: 3;
    }
    
    .order-4 {
        order: 4;
    }
    
    .order-5 {
        order: 5;
    }
    
    .order-6 {
        order: 6;
    }
    
    .order-7 {
        order: 7;
    }
    
    .order-8 {
        order: 8;
    }
    
    .order-9 {
        order: 9;
    }
    
    .order-10 {
        order: 10;
    }
    
    .order-11 {
        order: 11;
    }
    
    .order-12 {
        order: 12;
    }
    
    .offset-1 {
        margin-left: 8.33333%;
    }
    
    .offset-2 {
        margin-left: 16.66667%;
    }
    
    .offset-3 {
        margin-left: 25%;
    }
    
    .offset-4 {
        margin-left: 33.33333%;
    }
    
    .offset-5 {
        margin-left: 41.66667%;
    }
    
    .offset-6 {
        margin-left: 50%;
    }
    
    .offset-7 {
        margin-left: 58.33333%;
    }
    
    .offset-8 {
        margin-left: 66.66667%;
    }
    
    .offset-9 {
        margin-left: 75%;
    }
    
    .offset-10 {
        margin-left: 83.33333%;
    }
    
    .offset-11 {
        margin-left: 91.66667%;
    }
    
    @media (min-width: 576px) {
        .col-sm {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
        }
        .col-sm-auto {
            flex: 0 0 auto;
            width: auto;
            max-width: none;
        }
        .col-sm-1 {
            flex: 0 0 8.33333%;
            max-width: 8.33333%;
        }
        .col-sm-2 {
            flex: 0 0 16.66667%;
            max-width: 16.66667%;
        }
        .col-sm-3 {
            flex: 0 0 25%;
            max-width: 25%;
        }
        .col-sm-4 {
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
        }
        .col-sm-5 {
            flex: 0 0 41.66667%;
            max-width: 41.66667%;
        }
        .col-sm-6 {
            flex: 0 0 50%;
            max-width: 50%;
        }
        .col-sm-7 {
            flex: 0 0 58.33333%;
            max-width: 58.33333%;
        }
        .col-sm-8 {
            flex: 0 0 66.66667%;
            max-width: 66.66667%;
        }
        .col-sm-9 {
            flex: 0 0 75%;
            max-width: 75%;
        }
        .col-sm-10 {
            flex: 0 0 83.33333%;
            max-width: 83.33333%;
        }
        .col-sm-11 {
            flex: 0 0 91.66667%;
            max-width: 91.66667%;
        }
        .col-sm-12 {
            flex: 0 0 100%;
            max-width: 100%;
        }
        .order-sm-first {
            order: -1;
        }
        .order-sm-last {
            order: 13;
        }
        .order-sm-0 {
            order: 0;
        }
        .order-sm-1 {
            order: 1;
        }
        .order-sm-2 {
            order: 2;
        }
        .order-sm-3 {
            order: 3;
        }
        .order-sm-4 {
            order: 4;
        }
        .order-sm-5 {
            order: 5;
        }
        .order-sm-6 {
            order: 6;
        }
        .order-sm-7 {
            order: 7;
        }
        .order-sm-8 {
            order: 8;
        }
        .order-sm-9 {
            order: 9;
        }
        .order-sm-10 {
            order: 10;
        }
        .order-sm-11 {
            order: 11;
        }
        .order-sm-12 {
            order: 12;
        }
        .offset-sm-0 {
            margin-left: 0;
        }
        .offset-sm-1 {
            margin-left: 8.33333%;
        }
        .offset-sm-2 {
            margin-left: 16.66667%;
        }
        .offset-sm-3 {
            margin-left: 25%;
        }
        .offset-sm-4 {
            margin-left: 33.33333%;
        }
        .offset-sm-5 {
            margin-left: 41.66667%;
        }
        .offset-sm-6 {
            margin-left: 50%;
        }
        .offset-sm-7 {
            margin-left: 58.33333%;
        }
        .offset-sm-8 {
            margin-left: 66.66667%;
        }
        .offset-sm-9 {
            margin-left: 75%;
        }
        .offset-sm-10 {
            margin-left: 83.33333%;
        }
        .offset-sm-11 {
            margin-left: 91.66667%;
        }
    }
    
    @media (min-width: 800px) {
        .col-md {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
        }
        .col-md-auto {
            flex: 0 0 auto;
            width: auto;
            max-width: none;
        }
        .col-md-1 {
            flex: 0 0 8.33333%;
            max-width: 8.33333%;
        }
        .col-md-2 {
            flex: 0 0 16.66667%;
            max-width: 16.66667%;
        }
        .col-md-3 {
            flex: 0 0 25%;
            max-width: 25%;
        }
        .col-md-4 {
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
        }
        .col-md-5 {
            flex: 0 0 41.66667%;
            max-width: 41.66667%;
        }
        .col-md-6 {
            flex: 0 0 50%;
            max-width: 50%;
        }
        .col-md-7 {
            flex: 0 0 58.33333%;
            max-width: 58.33333%;
        }
        .col-md-8 {
            flex: 0 0 66.66667%;
            max-width: 66.66667%;
        }
        .col-md-9 {
            flex: 0 0 75%;
            max-width: 75%;
        }
        .col-md-10 {
            flex: 0 0 83.33333%;
            max-width: 83.33333%;
        }
        .col-md-11 {
            flex: 0 0 91.66667%;
            max-width: 91.66667%;
        }
        .col-md-12 {
            flex: 0 0 100%;
            max-width: 100%;
        }
        .order-md-first {
            order: -1;
        }
        .order-md-last {
            order: 13;
        }
        .order-md-0 {
            order: 0;
        }
        .order-md-1 {
            order: 1;
        }
        .order-md-2 {
            order: 2;
        }
        .order-md-3 {
            order: 3;
        }
        .order-md-4 {
            order: 4;
        }
        .order-md-5 {
            order: 5;
        }
        .order-md-6 {
            order: 6;
        }
        .order-md-7 {
            order: 7;
        }
        .order-md-8 {
            order: 8;
        }
        .order-md-9 {
            order: 9;
        }
        .order-md-10 {
            order: 10;
        }
        .order-md-11 {
            order: 11;
        }
        .order-md-12 {
            order: 12;
        }
        .offset-md-0 {
            margin-left: 0;
        }
        .offset-md-1 {
            margin-left: 8.33333%;
        }
        .offset-md-2 {
            margin-left: 16.66667%;
        }
        .offset-md-3 {
            margin-left: 25%;
        }
        .offset-md-4 {
            margin-left: 33.33333%;
        }
        .offset-md-5 {
            margin-left: 41.66667%;
        }
        .offset-md-6 {
            margin-left: 50%;
        }
        .offset-md-7 {
            margin-left: 58.33333%;
        }
        .offset-md-8 {
            margin-left: 66.66667%;
        }
        .offset-md-9 {
            margin-left: 75%;
        }
        .offset-md-10 {
            margin-left: 83.33333%;
        }
        .offset-md-11 {
            margin-left: 91.66667%;
        }
    }
    
    @media (min-width: 992px) {
        .col-lg {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
        }
        .col-lg-auto {
            flex: 0 0 auto;
            width: auto;
            max-width: none;
        }
        .col-lg-1 {
            flex: 0 0 8.33333%;
            max-width: 8.33333%;
        }
        .col-lg-2 {
            flex: 0 0 16.66667%;
            max-width: 16.66667%;
        }
        .col-lg-3 {
            flex: 0 0 25%;
            max-width: 25%;
        }
        .col-lg-4 {
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
        }
        .col-lg-5 {
            flex: 0 0 41.66667%;
            max-width: 41.66667%;
        }
        .col-lg-6 {
            flex: 0 0 50%;
            max-width: 50%;
        }
        .col-lg-7 {
            flex: 0 0 58.33333%;
            max-width: 58.33333%;
        }
        .col-lg-8 {
            flex: 0 0 66.66667%;
            max-width: 66.66667%;
        }
        .col-lg-9 {
            flex: 0 0 75%;
            max-width: 75%;
        }
        .col-lg-10 {
            flex: 0 0 83.33333%;
            max-width: 83.33333%;
        }
        .col-lg-11 {
            flex: 0 0 91.66667%;
            max-width: 91.66667%;
        }
        .col-lg-12 {
            flex: 0 0 100%;
            max-width: 100%;
        }
        .order-lg-first {
            order: -1;
        }
        .order-lg-last {
            order: 13;
        }
        .order-lg-0 {
            order: 0;
        }
        .order-lg-1 {
            order: 1;
        }
        .order-lg-2 {
            order: 2;
        }
        .order-lg-3 {
            order: 3;
        }
        .order-lg-4 {
            order: 4;
        }
        .order-lg-5 {
            order: 5;
        }
        .order-lg-6 {
            order: 6;
        }
        .order-lg-7 {
            order: 7;
        }
        .order-lg-8 {
            order: 8;
        }
        .order-lg-9 {
            order: 9;
        }
        .order-lg-10 {
            order: 10;
        }
        .order-lg-11 {
            order: 11;
        }
        .order-lg-12 {
            order: 12;
        }
        .offset-lg-0 {
            margin-left: 0;
        }
        .offset-lg-1 {
            margin-left: 8.33333%;
        }
        .offset-lg-2 {
            margin-left: 16.66667%;
        }
        .offset-lg-3 {
            margin-left: 25%;
        }
        .offset-lg-4 {
            margin-left: 33.33333%;
        }
        .offset-lg-5 {
            margin-left: 41.66667%;
        }
        .offset-lg-6 {
            margin-left: 50%;
        }
        .offset-lg-7 {
            margin-left: 58.33333%;
        }
        .offset-lg-8 {
            margin-left: 66.66667%;
        }
        .offset-lg-9 {
            margin-left: 75%;
        }
        .offset-lg-10 {
            margin-left: 83.33333%;
        }
        .offset-lg-11 {
            margin-left: 91.66667%;
        }
    }
    
    @media (min-width: 1200px) {
        .col-xl {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
        }
        .col-xl-auto {
            flex: 0 0 auto;
            width: auto;
            max-width: none;
        }
        .col-xl-1 {
            flex: 0 0 8.33333%;
            max-width: 8.33333%;
        }
        .col-xl-2 {
            flex: 0 0 16.66667%;
            max-width: 16.66667%;
        }
        .col-xl-3 {
            flex: 0 0 25%;
            max-width: 25%;
        }
        .col-xl-4 {
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
        }
        .col-xl-5 {
            flex: 0 0 41.66667%;
            max-width: 41.66667%;
        }
        .col-xl-6 {
            flex: 0 0 50%;
            max-width: 50%;
        }
        .col-xl-7 {
            flex: 0 0 58.33333%;
            max-width: 58.33333%;
        }
        .col-xl-8 {
            flex: 0 0 66.66667%;
            max-width: 66.66667%;
        }
        .col-xl-9 {
            flex: 0 0 75%;
            max-width: 75%;
        }
        .col-xl-10 {
            flex: 0 0 83.33333%;
            max-width: 83.33333%;
        }
        .col-xl-11 {
            flex: 0 0 91.66667%;
            max-width: 91.66667%;
        }
        .col-xl-12 {
            flex: 0 0 100%;
            max-width: 100%;
        }
        .order-xl-first {
            order: -1;
        }
        .order-xl-last {
            order: 13;
        }
        .order-xl-0 {
            order: 0;
        }
        .order-xl-1 {
            order: 1;
        }
        .order-xl-2 {
            order: 2;
        }
        .order-xl-3 {
            order: 3;
        }
        .order-xl-4 {
            order: 4;
        }
        .order-xl-5 {
            order: 5;
        }
        .order-xl-6 {
            order: 6;
        }
        .order-xl-7 {
            order: 7;
        }
        .order-xl-8 {
            order: 8;
        }
        .order-xl-9 {
            order: 9;
        }
        .order-xl-10 {
            order: 10;
        }
        .order-xl-11 {
            order: 11;
        }
        .order-xl-12 {
            order: 12;
        }
        .offset-xl-0 {
            margin-left: 0;
        }
        .offset-xl-1 {
            margin-left: 8.33333%;
        }
        .offset-xl-2 {
            margin-left: 16.66667%;
        }
        .offset-xl-3 {
            margin-left: 25%;
        }
        .offset-xl-4 {
            margin-left: 33.33333%;
        }
        .offset-xl-5 {
            margin-left: 41.66667%;
        }
        .offset-xl-6 {
            margin-left: 50%;
        }
        .offset-xl-7 {
            margin-left: 58.33333%;
        }
        .offset-xl-8 {
            margin-left: 66.66667%;
        }
        .offset-xl-9 {
            margin-left: 75%;
        }
        .offset-xl-10 {
            margin-left: 83.33333%;
        }
        .offset-xl-11 {
            margin-left: 91.66667%;
        }
    }
    
    @media (min-width: 1600px) {
        .col-xlg {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
        }
        .col-xlg-auto {
            flex: 0 0 auto;
            width: auto;
            max-width: none;
        }
        .col-xlg-1 {
            flex: 0 0 8.33333%;
            max-width: 8.33333%;
        }
        .col-xlg-2 {
            flex: 0 0 16.66667%;
            max-width: 16.66667%;
        }
        .col-xlg-3 {
            flex: 0 0 25%;
            max-width: 25%;
        }
        .col-xlg-4 {
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
        }
        .col-xlg-5 {
            flex: 0 0 41.66667%;
            max-width: 41.66667%;
        }
        .col-xlg-6 {
            flex: 0 0 50%;
            max-width: 50%;
        }
        .col-xlg-7 {
            flex: 0 0 58.33333%;
            max-width: 58.33333%;
        }
        .col-xlg-8 {
            flex: 0 0 66.66667%;
            max-width: 66.66667%;
        }
        .col-xlg-9 {
            flex: 0 0 75%;
            max-width: 75%;
        }
        .col-xlg-10 {
            flex: 0 0 83.33333%;
            max-width: 83.33333%;
        }
        .col-xlg-11 {
            flex: 0 0 91.66667%;
            max-width: 91.66667%;
        }
        .col-xlg-12 {
            flex: 0 0 100%;
            max-width: 100%;
        }
        .order-xlg-first {
            order: -1;
        }
        .order-xlg-last {
            order: 13;
        }
        .order-xlg-0 {
            order: 0;
        }
        .order-xlg-1 {
            order: 1;
        }
        .order-xlg-2 {
            order: 2;
        }
        .order-xlg-3 {
            order: 3;
        }
        .order-xlg-4 {
            order: 4;
        }
        .order-xlg-5 {
            order: 5;
        }
        .order-xlg-6 {
            order: 6;
        }
        .order-xlg-7 {
            order: 7;
        }
        .order-xlg-8 {
            order: 8;
        }
        .order-xlg-9 {
            order: 9;
        }
        .order-xlg-10 {
            order: 10;
        }
        .order-xlg-11 {
            order: 11;
        }
        .order-xlg-12 {
            order: 12;
        }
        .offset-xlg-0 {
            margin-left: 0;
        }
        .offset-xlg-1 {
            margin-left: 8.33333%;
        }
        .offset-xlg-2 {
            margin-left: 16.66667%;
        }
        .offset-xlg-3 {
            margin-left: 25%;
        }
        .offset-xlg-4 {
            margin-left: 33.33333%;
        }
        .offset-xlg-5 {
            margin-left: 41.66667%;
        }
        .offset-xlg-6 {
            margin-left: 50%;
        }
        .offset-xlg-7 {
            margin-left: 58.33333%;
        }
        .offset-xlg-8 {
            margin-left: 66.66667%;
        }
        .offset-xlg-9 {
            margin-left: 75%;
        }
        .offset-xlg-10 {
            margin-left: 83.33333%;
        }
        .offset-xlg-11 {
            margin-left: 91.66667%;
        }
    }
    
    .table {
        width: 100%;
        margin-bottom: 1rem;
        background-color: transparent;
    }
    
    .table th,
    .table td {
        padding: 1rem;
        vertical-align: top;
        border-top: 1px solid #dee2e6;
    }
    
    .table thead th {
        vertical-align: bottom;
        border-bottom: 2px solid #dee2e6;
    }
    
    .table tbody+tbody {
        border-top: 2px solid #dee2e6;
    }
    
    .table .table {
        background-color: #eef5f9;
    }
    
    .table-sm th,
    .table-sm td {
        padding: 0.3rem;
    }
    
    .table-bordered {
        border: 1px solid #dee2e6;
    }
    
    .table-bordered th,
    .table-bordered td {
        border: 1px solid #dee2e6;
    }
    
    .table-bordered thead th,
    .table-bordered thead td {
        border-bottom-width: 2px;
    }
    
    .table-borderless th,
    .table-borderless td,
    .table-borderless thead th,
    .table-borderless tbody+tbody {
        border: 0;
    }
    
    .table-striped tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.05);
    }
    
    .table-hover tbody tr:hover {
        background-color: #f6f9fc;
    }
    
    .table-primary,
    .table-primary>th,
    .table-primary>td {
        background-color: #d2d8f7;
    }
    
    .table-hover .table-primary:hover {
        background-color: #bcc5f3;
    }
    
    .table-hover .table-primary:hover>td,
    .table-hover .table-primary:hover>th {
        background-color: #bcc5f3;
    }
    
    .table-secondary,
    .table-secondary>th,
    .table-secondary>td {
        background-color: #dee2e7;
    }
    
    .table-hover .table-secondary:hover {
        background-color: #cfd5dc;
    }
    
    .table-hover .table-secondary:hover>td,
    .table-hover .table-secondary:hover>th {
        background-color: #cfd5dc;
    }
    
    .table-success,
    .table-success>th,
    .table-success>td {
        background-color: #c4f1de;
    }
    
    .table-hover .table-success:hover {
        background-color: #afecd2;
    }
    
    .table-hover .table-success:hover>td,
    .table-hover .table-success:hover>th {
        background-color: #afecd2;
    }
    
    .table-info,
    .table-info>th,
    .table-info>td {
        background-color: #c3d3ff;
    }
    
    .table-hover .table-info:hover {
        background-color: #aac0ff;
    }
    
    .table-hover .table-info:hover>td,
    .table-hover .table-info:hover>th {
        background-color: #aac0ff;
    }
    
    .table-warning,
    .table-warning>th,
    .table-warning>td {
        background-color: #fed3ca;
    }
    
    .table-hover .table-warning:hover {
        background-color: #febeb1;
    }
    
    .table-hover .table-warning:hover>td,
    .table-hover .table-warning:hover>th {
        background-color: #febeb1;
    }
    
    .table-danger,
    .table-danger>th,
    .table-danger>td {
        background-color: #fcc4ce;
    }
    
    .table-hover .table-danger:hover {
        background-color: #fbacba;
    }
    
    .table-hover .table-danger:hover>td,
    .table-hover .table-danger:hover>th {
        background-color: #fbacba;
    }
    
    .table-light,
    .table-light>th,
    .table-light>td {
        background-color: #fcfdfe;
    }
    
    .table-hover .table-light:hover {
        background-color: #e9f0f8;
    }
    
    .table-hover .table-light:hover>td,
    .table-hover .table-light:hover>th {
        background-color: #e9f0f8;
    }
    
    .table-dark,
    .table-dark>th,
    .table-dark>td {
        background-color: #c6c6d2;
    }
    
    .table-hover .table-dark:hover {
        background-color: #b8b8c7;
    }
    
    .table-hover .table-dark:hover>td,
    .table-hover .table-dark:hover>th {
        background-color: #b8b8c7;
    }
    
    .table-cyan,
    .table-cyan>th,
    .table-cyan>td {
        background-color: #ceeefd;
    }
    
    .table-hover .table-cyan:hover {
        background-color: #b6e6fc;
    }
    
    .table-hover .table-cyan:hover>td,
    .table-hover .table-cyan:hover>th {
        background-color: #b6e6fc;
    }
    
    .table-orange,
    .table-orange>th,
    .table-orange>td {
        background-color: #fed3ca;
    }
    
    .table-hover .table-orange:hover {
        background-color: #febeb1;
    }
    
    .table-hover .table-orange:hover>td,
    .table-hover .table-orange:hover>th {
        background-color: #febeb1;
    }
    
    .table-purple,
    .table-purple>th,
    .table-purple>td {
        background-color: #d2d8f7;
    }
    
    .table-hover .table-purple:hover {
        background-color: #bcc5f3;
    }
    
    .table-hover .table-purple:hover>td,
    .table-hover .table-purple:hover>th {
        background-color: #bcc5f3;
    }
    
    .table-inverse,
    .table-inverse>th,
    .table-inverse>td {
        background-color: #c6c6d2;
    }
    
    .table-hover .table-inverse:hover {
        background-color: #b8b8c7;
    }
    
    .table-hover .table-inverse:hover>td,
    .table-hover .table-inverse:hover>th {
        background-color: #b8b8c7;
    }
    
    .table-active,
    .table-active>th,
    .table-active>td {
        background-color: #f6f9fc;
    }
    
    .table-hover .table-active:hover {
        background-color: #e3ecf6;
    }
    
    .table-hover .table-active:hover>td,
    .table-hover .table-active:hover>th {
        background-color: #e3ecf6;
    }
    
    .table .thead-dark th {
        color: #eef5f9;
        background-color: #212529;
        border-color: #32383e;
    }
    
    .table .thead-light th {
        color: #525f7f;
        background-color: #e9ecef;
        border-color: #dee2e6;
    }
    
    .table-dark {
        color: #eef5f9;
        background-color: #212529;
    }
    
    .table-dark th,
    .table-dark td,
    .table-dark thead th {
        border-color: #32383e;
    }
    
    .table-dark.table-bordered {
        border: 0;
    }
    
    .table-dark.table-striped tbody tr:nth-of-type(odd) {
        background-color: rgba(255, 255, 255, 0.05);
    }
    
    .table-dark.table-hover tbody tr:hover {
        background-color: rgba(255, 255, 255, 0.075);
    }
    
    @media (max-width: 575.98px) {
        .table-responsive-sm {
            display: block;
            width: 100%;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
        }
        .table-responsive-sm>.table-bordered {
            border: 0;
        }
    }
    
    @media (max-width: 799.98px) {
        .table-responsive-md {
            display: block;
            width: 100%;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
        }
        .table-responsive-md>.table-bordered {
            border: 0;
        }
    }
    
    @media (max-width: 991.98px) {
        .table-responsive-lg {
            display: block;
            width: 100%;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
        }
        .table-responsive-lg>.table-bordered {
            border: 0;
        }
    }
    
    @media (max-width: 1199.98px) {
        .table-responsive-xl {
            display: block;
            width: 100%;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
        }
        .table-responsive-xl>.table-bordered {
            border: 0;
        }
    }
    
    @media (max-width: 1599.98px) {
        .table-responsive-xlg {
            display: block;
            width: 100%;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
        }
        .table-responsive-xlg>.table-bordered {
            border: 0;
        }
    }
    
    .table-responsive {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    
    .table-responsive>.table-bordered {
        border: 0;
    }
    
    .form-control {
        display: block;
        width: 100%;
        height: calc(2.0625rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 0.875rem;
        line-height: 1.5;
        color: #525f7f;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #e9ecef;
        border-radius: 2px;
        box-shadow: none;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    
    @media screen and (prefers-reduced-motion: reduce) {
        .form-control {
            transition: none;
        }
    }
    
    .form-control::-ms-expand {
        background-color: transparent;
        border: 0;
    }
    
    .form-control:focus {
        color: #525f7f;
        background-color: #fff;
        border-color: rgba(0, 0, 0, 0.25);
        outline: 0;
        box-shadow: none, none;
    }
    
    .form-control::placeholder {
        color: #8898aa;
        opacity: 1;
    }
    
    .form-control:disabled,
    .form-control[readonly] {
        background-color: #e9ecef;
        opacity: 1;
    }
    
    select.form-control:focus::-ms-value {
        color: #525f7f;
        background-color: #fff;
    }
    
    .form-control-file,
    .form-control-range {
        display: block;
        width: 100%;
    }
    
    .col-form-label {
        padding-top: calc(0.375rem + 1px);
        padding-bottom: calc(0.375rem + 1px);
        margin-bottom: 0;
        font-size: inherit;
        line-height: 1.5;
    }
    
    .col-form-label-lg {
        padding-top: calc(0.5rem + 1px);
        padding-bottom: calc(0.5rem + 1px);
        font-size: 1.09375rem;
        line-height: 1.5;
    }
    
    .col-form-label-sm {
        padding-top: calc(0.25rem + 1px);
        padding-bottom: calc(0.25rem + 1px);
        font-size: 0.76563rem;
        line-height: 1.5;
    }
    
    .form-control-plaintext {
        display: block;
        width: 100%;
        padding-top: 0.375rem;
        padding-bottom: 0.375rem;
        margin-bottom: 0;
        line-height: 1.5;
        color: #3e5569;
        background-color: transparent;
        border: solid transparent;
        border-width: 1px 0;
    }
    
    .form-control-plaintext.form-control-sm,
    .form-control-plaintext.form-control-lg {
        padding-right: 0;
        padding-left: 0;
    }
    
    .form-control-sm {
        height: calc(1.64844rem + 2px);
        padding: 0.25rem 0.5rem;
        font-size: 0.76563rem;
        line-height: 1.5;
        border-radius: 1px;
    }
    
    .form-control-lg {
        height: calc(2.64063rem + 2px);
        padding: 0.5rem 1rem;
        font-size: 1.09375rem;
        line-height: 1.5;
        border-radius: 4px;
    }
    
    select.form-control[size],
    select.form-control[multiple] {
        height: auto;
    }
    
    textarea.form-control {
        height: auto;
    }
    
    .form-group {
        margin-bottom: 1rem;
    }
    
    .form-text {
        display: block;
        margin-top: 0.25rem;
    }
    
    .form-row {
        display: flex;
        flex-wrap: wrap;
        margin-right: -5px;
        margin-left: -5px;
    }
    
    .form-row>.col,
    .form-row>[class*="col-"] {
        padding-right: 5px;
        padding-left: 5px;
    }
    
    .form-check {
        position: relative;
        display: block;
        padding-left: 1.25rem;
    }
    
    .form-check-input {
        position: absolute;
        margin-top: 0.3rem;
        margin-left: -1.25rem;
    }
    
    .form-check-input:disabled~.form-check-label {
        color: #8898aa;
    }
    
    .form-check-label {
        margin-bottom: 0;
    }
    
    .form-check-inline {
        display: inline-flex;
        align-items: center;
        padding-left: 0;
        margin-right: 0.75rem;
    }
    
    .form-check-inline .form-check-input {
        position: static;
        margin-top: 0;
        margin-right: 0.3125rem;
        margin-left: 0;
    }
    
    .valid-feedback {
        display: none;
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #2dce89;
    }
    
    .valid-tooltip {
        position: absolute;
        top: 100%;
        z-index: 5;
        display: none;
        max-width: 100%;
        padding: 0.25rem 0.5rem;
        margin-top: .1rem;
        font-size: 0.76563rem;
        line-height: 1.5;
        color: #fff;
        background-color: rgba(45, 206, 137, 0.9);
        border-radius: 2px;
    }
    
    .was-validated .form-control:valid,
    .form-control.is-valid,
    .was-validated .custom-select:valid,
    .custom-select.is-valid {
        border-color: #2dce89;
    }
    
    .was-validated .form-control:valid:focus,
    .form-control.is-valid:focus,
    .was-validated .custom-select:valid:focus,
    .custom-select.is-valid:focus {
        border-color: #2dce89;
        box-shadow: 0 0 0 0.2rem rgba(45, 206, 137, 0.25);
    }
    
    .was-validated .form-control:valid~.valid-feedback,
    .was-validated .form-control:valid~.valid-tooltip,
    .form-control.is-valid~.valid-feedback,
    .form-control.is-valid~.valid-tooltip,
    .was-validated .custom-select:valid~.valid-feedback,
    .was-validated .custom-select:valid~.valid-tooltip,
    .custom-select.is-valid~.valid-feedback,
    .custom-select.is-valid~.valid-tooltip {
        display: block;
    }
    
    .was-validated .form-control-file:valid~.valid-feedback,
    .was-validated .form-control-file:valid~.valid-tooltip,
    .form-control-file.is-valid~.valid-feedback,
    .form-control-file.is-valid~.valid-tooltip {
        display: block;
    }
    
    .was-validated .form-check-input:valid~.form-check-label,
    .form-check-input.is-valid~.form-check-label {
        color: #2dce89;
    }
    
    .was-validated .form-check-input:valid~.valid-feedback,
    .was-validated .form-check-input:valid~.valid-tooltip,
    .form-check-input.is-valid~.valid-feedback,
    .form-check-input.is-valid~.valid-tooltip {
        display: block;
    }
    
    .was-validated .custom-control-input:valid~.custom-control-label,
    .custom-control-input.is-valid~.custom-control-label {
        color: #2dce89;
    }
    
    .was-validated .custom-control-input:valid~.custom-control-label::before,
    .custom-control-input.is-valid~.custom-control-label::before {
        background-color: #93e7c3;
    }
    
    .was-validated .custom-control-input:valid~.valid-feedback,
    .was-validated .custom-control-input:valid~.valid-tooltip,
    .custom-control-input.is-valid~.valid-feedback,
    .custom-control-input.is-valid~.valid-tooltip {
        display: block;
    }
    
    .was-validated .custom-control-input:valid:checked~.custom-control-label::before,
    .custom-control-input.is-valid:checked~.custom-control-label::before {
        background-color: #54daa1;
    }
    
    .was-validated .custom-control-input:valid:focus~.custom-control-label::before,
    .custom-control-input.is-valid:focus~.custom-control-label::before {
        box-shadow: 0 0 0 1px #eef5f9, 0 0 0 0.2rem rgba(45, 206, 137, 0.25);
    }
    
    .was-validated .custom-file-input:valid~.custom-file-label,
    .custom-file-input.is-valid~.custom-file-label {
        border-color: #2dce89;
    }
    
    .was-validated .custom-file-input:valid~.custom-file-label::after,
    .custom-file-input.is-valid~.custom-file-label::after {
        border-color: inherit;
    }
    
    .was-validated .custom-file-input:valid~.valid-feedback,
    .was-validated .custom-file-input:valid~.valid-tooltip,
    .custom-file-input.is-valid~.valid-feedback,
    .custom-file-input.is-valid~.valid-tooltip {
        display: block;
    }
    
    .was-validated .custom-file-input:valid:focus~.custom-file-label,
    .custom-file-input.is-valid:focus~.custom-file-label {
        box-shadow: 0 0 0 0.2rem rgba(45, 206, 137, 0.25);
    }
    
    .invalid-feedback {
        display: none;
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #f62d51;
    }
    
    .invalid-tooltip {
        position: absolute;
        top: 100%;
        z-index: 5;
        display: none;
        max-width: 100%;
        padding: 0.25rem 0.5rem;
        margin-top: .1rem;
        font-size: 0.76563rem;
        line-height: 1.5;
        color: #fff;
        background-color: rgba(246, 45, 81, 0.9);
        border-radius: 2px;
    }
    
    .was-validated .form-control:invalid,
    .form-control.is-invalid,
    .was-validated .custom-select:invalid,
    .custom-select.is-invalid {
        border-color: #f62d51;
    }
    
    .was-validated .form-control:invalid:focus,
    .form-control.is-invalid:focus,
    .was-validated .custom-select:invalid:focus,
    .custom-select.is-invalid:focus {
        border-color: #f62d51;
        box-shadow: 0 0 0 0.2rem rgba(246, 45, 81, 0.25);
    }
    
    .was-validated .form-control:invalid~.invalid-feedback,
    .was-validated .form-control:invalid~.invalid-tooltip,
    .form-control.is-invalid~.invalid-feedback,
    .form-control.is-invalid~.invalid-tooltip,
    .was-validated .custom-select:invalid~.invalid-feedback,
    .was-validated .custom-select:invalid~.invalid-tooltip,
    .custom-select.is-invalid~.invalid-feedback,
    .custom-select.is-invalid~.invalid-tooltip {
        display: block;
    }
    
    .was-validated .form-control-file:invalid~.invalid-feedback,
    .was-validated .form-control-file:invalid~.invalid-tooltip,
    .form-control-file.is-invalid~.invalid-feedback,
    .form-control-file.is-invalid~.invalid-tooltip {
        display: block;
    }
    
    .was-validated .form-check-input:invalid~.form-check-label,
    .form-check-input.is-invalid~.form-check-label {
        color: #f62d51;
    }
    
    .was-validated .form-check-input:invalid~.invalid-feedback,
    .was-validated .form-check-input:invalid~.invalid-tooltip,
    .form-check-input.is-invalid~.invalid-feedback,
    .form-check-input.is-invalid~.invalid-tooltip {
        display: block;
    }
    
    .was-validated .custom-control-input:invalid~.custom-control-label,
    .custom-control-input.is-invalid~.custom-control-label {
        color: #f62d51;
    }
    
    .was-validated .custom-control-input:invalid~.custom-control-label::before,
    .custom-control-input.is-invalid~.custom-control-label::before {
        background-color: #fba7b6;
    }
    
    .was-validated .custom-control-input:invalid~.invalid-feedback,
    .was-validated .custom-control-input:invalid~.invalid-tooltip,
    .custom-control-input.is-invalid~.invalid-feedback,
    .custom-control-input.is-invalid~.invalid-tooltip {
        display: block;
    }
    
    .was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
    .custom-control-input.is-invalid:checked~.custom-control-label::before {
        background-color: #f85e7a;
    }
    
    .was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
    .custom-control-input.is-invalid:focus~.custom-control-label::before {
        box-shadow: 0 0 0 1px #eef5f9, 0 0 0 0.2rem rgba(246, 45, 81, 0.25);
    }
    
    .was-validated .custom-file-input:invalid~.custom-file-label,
    .custom-file-input.is-invalid~.custom-file-label {
        border-color: #f62d51;
    }
    
    .was-validated .custom-file-input:invalid~.custom-file-label::after,
    .custom-file-input.is-invalid~.custom-file-label::after {
        border-color: inherit;
    }
    
    .was-validated .custom-file-input:invalid~.invalid-feedback,
    .was-validated .custom-file-input:invalid~.invalid-tooltip,
    .custom-file-input.is-invalid~.invalid-feedback,
    .custom-file-input.is-invalid~.invalid-tooltip {
        display: block;
    }
    
    .was-validated .custom-file-input:invalid:focus~.custom-file-label,
    .custom-file-input.is-invalid:focus~.custom-file-label {
        box-shadow: 0 0 0 0.2rem rgba(246, 45, 81, 0.25);
    }
    
    .form-inline {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
    }
    
    .form-inline .form-check {
        width: 100%;
    }
    
    @media (min-width: 576px) {
        .form-inline label {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0;
        }
        .form-inline .form-group {
            display: flex;
            flex: 0 0 auto;
            flex-flow: row wrap;
            align-items: center;
            margin-bottom: 0;
        }
        .form-inline .form-control {
            display: inline-block;
            width: auto;
            vertical-align: middle;
        }
        .form-inline .form-control-plaintext {
            display: inline-block;
        }
        .form-inline .input-group,
        .form-inline .custom-select {
            width: auto;
        }
        .form-inline .form-check {
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            padding-left: 0;
        }
        .form-inline .form-check-input {
            position: relative;
            margin-top: 0;
            margin-right: 0.25rem;
            margin-left: 0;
        }
        .form-inline .custom-control {
            align-items: center;
            justify-content: center;
        }
        .form-inline .custom-control-label {
            margin-bottom: 0;
        }
    }
    
    .btn {
        display: inline-block;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        user-select: none;
        border: 1px solid transparent;
        padding: 0.375rem 0.75rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 2px;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    
    @media screen and (prefers-reduced-motion: reduce) {
        .btn {
            transition: none;
        }
    }
    
    .btn:hover,
    .btn:focus {
        text-decoration: none;
    }
    
    .btn:focus,
    .btn.focus {
        outline: 0;
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    }
    
    .btn.disabled,
    .btn:disabled {
        opacity: 0.65;
        box-shadow: none;
    }
    
    .btn:not(:disabled):not(.disabled) {
        cursor: pointer;
    }
    
    .btn:not(:disabled):not(.disabled):active,
    .btn:not(:disabled):not(.disabled).active {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
    
    .btn:not(:disabled):not(.disabled):active:focus,
    .btn:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08), inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
    
    a.btn.disabled,
    fieldset:disabled a.btn {
        pointer-events: none;
    }
    
    .btn-primary {
        color: #fff;
        background-color: #5e72e4;
        border-color: #5e72e4;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    
    .btn-primary:hover {
        color: #fff;
        background-color: #3d55df;
        border-color: #324cdd;
    }
    
    .btn-primary:focus,
    .btn-primary.focus {
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0.2rem rgba(94, 114, 228, 0.5);
    }
    
    .btn-primary.disabled,
    .btn-primary:disabled {
        color: #fff;
        background-color: #5e72e4;
        border-color: #5e72e4;
    }
    
    .btn-primary:not(:disabled):not(.disabled):active,
    .btn-primary:not(:disabled):not(.disabled).active,
    .show>.btn-primary.dropdown-toggle {
        color: #fff;
        background-color: #324cdd;
        border-color: #2742db;
    }
    
    .btn-primary:not(:disabled):not(.disabled):active:focus,
    .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show>.btn-primary.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(94, 114, 228, 0.5);
    }
    
    .btn-secondary {
        color: #fff;
        background-color: #8898aa;
        border-color: #8898aa;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    
    .btn-secondary:hover {
        color: #fff;
        background-color: #72859a;
        border-color: #6a7e95;
    }
    
    .btn-secondary:focus,
    .btn-secondary.focus {
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0.2rem rgba(136, 152, 170, 0.5);
    }
    
    .btn-secondary.disabled,
    .btn-secondary:disabled {
        color: #fff;
        background-color: #8898aa;
        border-color: #8898aa;
    }
    
    .btn-secondary:not(:disabled):not(.disabled):active,
    .btn-secondary:not(:disabled):not(.disabled).active,
    .show>.btn-secondary.dropdown-toggle {
        color: #fff;
        background-color: #6a7e95;
        border-color: #65788d;
    }
    
    .btn-secondary:not(:disabled):not(.disabled):active:focus,
    .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show>.btn-secondary.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(136, 152, 170, 0.5);
    }
    
    .btn-success {
        color: #fff;
        background-color: #2dce89;
        border-color: #2dce89;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    
    .btn-success:hover {
        color: #fff;
        background-color: #26af74;
        border-color: #24a46d;
    }
    
    .btn-success:focus,
    .btn-success.focus {
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0.2rem rgba(45, 206, 137, 0.5);
    }
    
    .btn-success.disabled,
    .btn-success:disabled {
        color: #fff;
        background-color: #2dce89;
        border-color: #2dce89;
    }
    
    .btn-success:not(:disabled):not(.disabled):active,
    .btn-success:not(:disabled):not(.disabled).active,
    .show>.btn-success.dropdown-toggle {
        color: #fff;
        background-color: #24a46d;
        border-color: #229a66;
    }
    
    .btn-success:not(:disabled):not(.disabled):active:focus,
    .btn-success:not(:disabled):not(.disabled).active:focus,
    .show>.btn-success.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(45, 206, 137, 0.5);
    }
    
    .btn-info {
        color: #fff;
        background-color: #2c6daa;
        border-color: #2c6daa;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    
    .btn-info:hover {
        color: #fff;
        background-color: #0346ff;
        border-color: #0041f5;
    }
    
    .btn-info:focus,
    .btn-info.focus {
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0.2rem rgba(41, 98, 255, 0.5);
    }
    
    .btn-info.disabled,
    .btn-info:disabled {
        color: #fff;
        background-color: #2c6daa;
        border-color: #2c6daa;
    }
    
    .btn-info:not(:disabled):not(.disabled):active,
    .btn-info:not(:disabled):not(.disabled).active,
    .show>.btn-info.dropdown-toggle {
        color: #fff;
        background-color: #0041f5;
        border-color: #003ee8;
    }
    
    .btn-info:not(:disabled):not(.disabled):active:focus,
    .btn-info:not(:disabled):not(.disabled).active:focus,
    .show>.btn-info.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(41, 98, 255, 0.5);
    }
    
    .btn-warning {
        color: #fff;
        background-color: #fb6340;
        border-color: #fb6340;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    
    .btn-warning:hover {
        color: #fff;
        background-color: #fa441b;
        border-color: #fa3a0e;
    }
    
    .btn-warning:focus,
    .btn-warning.focus {
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0.2rem rgba(251, 99, 64, 0.5);
    }
    
    .btn-warning.disabled,
    .btn-warning:disabled {
        color: #fff;
        background-color: #fb6340;
        border-color: #fb6340;
    }
    
    .btn-warning:not(:disabled):not(.disabled):active,
    .btn-warning:not(:disabled):not(.disabled).active,
    .show>.btn-warning.dropdown-toggle {
        color: #fff;
        background-color: #fa3a0e;
        border-color: #f63205;
    }
    
    .btn-warning:not(:disabled):not(.disabled):active:focus,
    .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show>.btn-warning.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(251, 99, 64, 0.5);
    }
    
    .btn-danger {
        color: #fff;
        background-color: #f62d51;
        border-color: #f62d51;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    
    .btn-danger:hover {
        color: #fff;
        background-color: #f20a34;
        border-color: #e60a31;
    }
    
    .btn-danger:focus,
    .btn-danger.focus {
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0.2rem rgba(246, 45, 81, 0.5);
    }
    
    .btn-danger.disabled,
    .btn-danger:disabled {
        color: #fff;
        background-color: #f62d51;
        border-color: #f62d51;
    }
    
    .btn-danger:not(:disabled):not(.disabled):active,
    .btn-danger:not(:disabled):not(.disabled).active,
    .show>.btn-danger.dropdown-toggle {
        color: #fff;
        background-color: #e60a31;
        border-color: #da092f;
    }
    
    .btn-danger:not(:disabled):not(.disabled):active:focus,
    .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show>.btn-danger.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(246, 45, 81, 0.5);
    }
    
    .btn-light {
        color: #212529;
        background-color: #f6f9fc;
        border-color: #f6f9fc;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    
    .btn-light:hover {
        color: #212529;
        background-color: #d9e6f2;
        border-color: #d0e0ef;
    }
    
    .btn-light:focus,
    .btn-light.focus {
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0.2rem rgba(246, 249, 252, 0.5);
    }
    
    .btn-light.disabled,
    .btn-light:disabled {
        color: #212529;
        background-color: #f6f9fc;
        border-color: #f6f9fc;
    }
    
    .btn-light:not(:disabled):not(.disabled):active,
    .btn-light:not(:disabled):not(.disabled).active,
    .show>.btn-light.dropdown-toggle {
        color: #212529;
        background-color: #d0e0ef;
        border-color: #c6d9ec;
    }
    
    .btn-light:not(:disabled):not(.disabled):active:focus,
    .btn-light:not(:disabled):not(.disabled).active:focus,
    .show>.btn-light.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(246, 249, 252, 0.5);
    }
    
    .btn-dark {
        color: #fff;
        background-color: #32325d;
        border-color: #32325d;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    
    .btn-dark:hover {
        color: #fff;
        background-color: #252544;
        border-color: #20203c;
    }
    
    .btn-dark:focus,
    .btn-dark.focus {
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0.2rem rgba(50, 50, 93, 0.5);
    }
    
    .btn-dark.disabled,
    .btn-dark:disabled {
        color: #fff;
        background-color: #32325d;
        border-color: #32325d;
    }
    
    .btn-dark:not(:disabled):not(.disabled):active,
    .btn-dark:not(:disabled):not(.disabled).active,
    .show>.btn-dark.dropdown-toggle {
        color: #fff;
        background-color: #20203c;
        border-color: #1c1c34;
    }
    
    .btn-dark:not(:disabled):not(.disabled):active:focus,
    .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show>.btn-dark.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(50, 50, 93, 0.5);
    }
    
    .btn-cyan {
        color: #212529;
        background-color: #4fc3f7;
        border-color: #4fc3f7;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    
    .btn-cyan:hover {
        color: #fff;
        background-color: #2ab7f5;
        border-color: #1eb2f5;
    }
    
    .btn-cyan:focus,
    .btn-cyan.focus {
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0.2rem rgba(79, 195, 247, 0.5);
    }
    
    .btn-cyan.disabled,
    .btn-cyan:disabled {
        color: #212529;
        background-color: #4fc3f7;
        border-color: #4fc3f7;
    }
    
    .btn-cyan:not(:disabled):not(.disabled):active,
    .btn-cyan:not(:disabled):not(.disabled).active,
    .show>.btn-cyan.dropdown-toggle {
        color: #fff;
        background-color: #1eb2f5;
        border-color: #12aef4;
    }
    
    .btn-cyan:not(:disabled):not(.disabled):active:focus,
    .btn-cyan:not(:disabled):not(.disabled).active:focus,
    .show>.btn-cyan.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(79, 195, 247, 0.5);
    }
    
    .btn-orange {
        color: #fff;
        background-color: #fb6340;
        border-color: #fb6340;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    
    .btn-orange:hover {
        color: #fff;
        background-color: #fa441b;
        border-color: #fa3a0e;
    }
    
    .btn-orange:focus,
    .btn-orange.focus {
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0.2rem rgba(251, 99, 64, 0.5);
    }
    
    .btn-orange.disabled,
    .btn-orange:disabled {
        color: #fff;
        background-color: #fb6340;
        border-color: #fb6340;
    }
    
    .btn-orange:not(:disabled):not(.disabled):active,
    .btn-orange:not(:disabled):not(.disabled).active,
    .show>.btn-orange.dropdown-toggle {
        color: #fff;
        background-color: #fa3a0e;
        border-color: #f63205;
    }
    
    .btn-orange:not(:disabled):not(.disabled):active:focus,
    .btn-orange:not(:disabled):not(.disabled).active:focus,
    .show>.btn-orange.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(251, 99, 64, 0.5);
    }
    
    .btn-purple {
        color: #fff;
        background-color: #5e72e4;
        border-color: #5e72e4;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    
    .btn-purple:hover {
        color: #fff;
        background-color: #3d55df;
        border-color: #324cdd;
    }
    
    .btn-purple:focus,
    .btn-purple.focus {
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0.2rem rgba(94, 114, 228, 0.5);
    }
    
    .btn-purple.disabled,
    .btn-purple:disabled {
        color: #fff;
        background-color: #5e72e4;
        border-color: #5e72e4;
    }
    
    .btn-purple:not(:disabled):not(.disabled):active,
    .btn-purple:not(:disabled):not(.disabled).active,
    .show>.btn-purple.dropdown-toggle {
        color: #fff;
        background-color: #324cdd;
        border-color: #2742db;
    }
    
    .btn-purple:not(:disabled):not(.disabled):active:focus,
    .btn-purple:not(:disabled):not(.disabled).active:focus,
    .show>.btn-purple.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(94, 114, 228, 0.5);
    }
    
    .btn-inverse {
        color: #fff;
        background-color: #32325d;
        border-color: #32325d;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    
    .btn-inverse:hover {
        color: #fff;
        background-color: #252544;
        border-color: #20203c;
    }
    
    .btn-inverse:focus,
    .btn-inverse.focus {
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0.2rem rgba(50, 50, 93, 0.5);
    }
    
    .btn-inverse.disabled,
    .btn-inverse:disabled {
        color: #fff;
        background-color: #32325d;
        border-color: #32325d;
    }
    
    .btn-inverse:not(:disabled):not(.disabled):active,
    .btn-inverse:not(:disabled):not(.disabled).active,
    .show>.btn-inverse.dropdown-toggle {
        color: #fff;
        background-color: #20203c;
        border-color: #1c1c34;
    }
    
    .btn-inverse:not(:disabled):not(.disabled):active:focus,
    .btn-inverse:not(:disabled):not(.disabled).active:focus,
    .show>.btn-inverse.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(50, 50, 93, 0.5);
    }
    
    .btn-outline-primary {
        color: #5e72e4;
        background-color: transparent;
        background-image: none;
        border-color: #5e72e4;
    }
    
    .btn-outline-primary:hover {
        color: #fff;
        background-color: #5e72e4;
        border-color: #5e72e4;
    }
    
    .btn-outline-primary:focus,
    .btn-outline-primary.focus {
        box-shadow: 0 0 0 0.2rem rgba(94, 114, 228, 0.5);
    }
    
    .btn-outline-primary.disabled,
    .btn-outline-primary:disabled {
        color: #5e72e4;
        background-color: transparent;
    }
    
    .btn-outline-primary:not(:disabled):not(.disabled):active,
    .btn-outline-primary:not(:disabled):not(.disabled).active,
    .show>.btn-outline-primary.dropdown-toggle {
        color: #fff;
        background-color: #5e72e4;
        border-color: #5e72e4;
    }
    
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
    .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show>.btn-outline-primary.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(94, 114, 228, 0.5);
    }
    
    .btn-outline-secondary {
        color: #8898aa;
        background-color: transparent;
        background-image: none;
        border-color: #8898aa;
    }
    
    .btn-outline-secondary:hover {
        color: #fff;
        background-color: #8898aa;
        border-color: #8898aa;
    }
    
    .btn-outline-secondary:focus,
    .btn-outline-secondary.focus {
        box-shadow: 0 0 0 0.2rem rgba(136, 152, 170, 0.5);
    }
    
    .btn-outline-secondary.disabled,
    .btn-outline-secondary:disabled {
        color: #8898aa;
        background-color: transparent;
    }
    
    .btn-outline-secondary:not(:disabled):not(.disabled):active,
    .btn-outline-secondary:not(:disabled):not(.disabled).active,
    .show>.btn-outline-secondary.dropdown-toggle {
        color: #fff;
        background-color: #8898aa;
        border-color: #8898aa;
    }
    
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
    .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show>.btn-outline-secondary.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(136, 152, 170, 0.5);
    }
    
    .btn-outline-success {
        color: #2dce89;
        background-color: transparent;
        background-image: none;
        border-color: #2dce89;
    }
    
    .btn-outline-success:hover {
        color: #fff;
        background-color: #2dce89;
        border-color: #2dce89;
    }
    
    .btn-outline-success:focus,
    .btn-outline-success.focus {
        box-shadow: 0 0 0 0.2rem rgba(45, 206, 137, 0.5);
    }
    
    .btn-outline-success.disabled,
    .btn-outline-success:disabled {
        color: #2dce89;
        background-color: transparent;
    }
    
    .btn-outline-success:not(:disabled):not(.disabled):active,
    .btn-outline-success:not(:disabled):not(.disabled).active,
    .show>.btn-outline-success.dropdown-toggle {
        color: #fff;
        background-color: #2dce89;
        border-color: #2dce89;
    }
    
    .btn-outline-success:not(:disabled):not(.disabled):active:focus,
    .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show>.btn-outline-success.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(45, 206, 137, 0.5);
    }
    
    .btn-outline-info {
        color: #2c6daa;
        background-color: transparent;
        background-image: none;
        border-color: #2c6daa;
    }
    
    .btn-outline-info:hover {
        color: #fff;
        background-color: #2c6daa;
        border-color: #2c6daa;
    }
    
    .btn-outline-info:focus,
    .btn-outline-info.focus {
        box-shadow: 0 0 0 0.2rem rgba(41, 98, 255, 0.5);
    }
    
    .btn-outline-info.disabled,
    .btn-outline-info:disabled {
        color: #2c6daa;
        background-color: transparent;
    }
    
    .btn-outline-info:not(:disabled):not(.disabled):active,
    .btn-outline-info:not(:disabled):not(.disabled).active,
    .show>.btn-outline-info.dropdown-toggle {
        color: #fff;
        background-color: #2c6daa;
        border-color: #2c6daa;
    }
    
    .btn-outline-info:not(:disabled):not(.disabled):active:focus,
    .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show>.btn-outline-info.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(41, 98, 255, 0.5);
    }
    
    .btn-outline-warning {
        color: #fb6340;
        background-color: transparent;
        background-image: none;
        border-color: #fb6340;
    }
    
    .btn-outline-warning:hover {
        color: #fff;
        background-color: #fb6340;
        border-color: #fb6340;
    }
    
    .btn-outline-warning:focus,
    .btn-outline-warning.focus {
        box-shadow: 0 0 0 0.2rem rgba(251, 99, 64, 0.5);
    }
    
    .btn-outline-warning.disabled,
    .btn-outline-warning:disabled {
        color: #fb6340;
        background-color: transparent;
    }
    
    .btn-outline-warning:not(:disabled):not(.disabled):active,
    .btn-outline-warning:not(:disabled):not(.disabled).active,
    .show>.btn-outline-warning.dropdown-toggle {
        color: #fff;
        background-color: #fb6340;
        border-color: #fb6340;
    }
    
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus,
    .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show>.btn-outline-warning.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(251, 99, 64, 0.5);
    }
    
    .btn-outline-danger {
        color: #f62d51;
        background-color: transparent;
        background-image: none;
        border-color: #f62d51;
    }
    
    .btn-outline-danger:hover {
        color: #fff;
        background-color: #f62d51;
        border-color: #f62d51;
    }
    
    .btn-outline-danger:focus,
    .btn-outline-danger.focus {
        box-shadow: 0 0 0 0.2rem rgba(246, 45, 81, 0.5);
    }
    
    .btn-outline-danger.disabled,
    .btn-outline-danger:disabled {
        color: #f62d51;
        background-color: transparent;
    }
    
    .btn-outline-danger:not(:disabled):not(.disabled):active,
    .btn-outline-danger:not(:disabled):not(.disabled).active,
    .show>.btn-outline-danger.dropdown-toggle {
        color: #fff;
        background-color: #f62d51;
        border-color: #f62d51;
    }
    
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus,
    .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show>.btn-outline-danger.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(246, 45, 81, 0.5);
    }
    
    .btn-outline-light {
        color: #f6f9fc;
        background-color: transparent;
        background-image: none;
        border-color: #f6f9fc;
    }
    
    .btn-outline-light:hover {
        color: #212529;
        background-color: #f6f9fc;
        border-color: #f6f9fc;
    }
    
    .btn-outline-light:focus,
    .btn-outline-light.focus {
        box-shadow: 0 0 0 0.2rem rgba(246, 249, 252, 0.5);
    }
    
    .btn-outline-light.disabled,
    .btn-outline-light:disabled {
        color: #f6f9fc;
        background-color: transparent;
    }
    
    .btn-outline-light:not(:disabled):not(.disabled):active,
    .btn-outline-light:not(:disabled):not(.disabled).active,
    .show>.btn-outline-light.dropdown-toggle {
        color: #212529;
        background-color: #f6f9fc;
        border-color: #f6f9fc;
    }
    
    .btn-outline-light:not(:disabled):not(.disabled):active:focus,
    .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show>.btn-outline-light.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(246, 249, 252, 0.5);
    }
    
    .btn-outline-dark {
        color: #32325d;
        background-color: transparent;
        background-image: none;
        border-color: #32325d;
    }
    
    .btn-outline-dark:hover {
        color: #fff;
        background-color: #32325d;
        border-color: #32325d;
    }
    
    .btn-outline-dark:focus,
    .btn-outline-dark.focus {
        box-shadow: 0 0 0 0.2rem rgba(50, 50, 93, 0.5);
    }
    
    .btn-outline-dark.disabled,
    .btn-outline-dark:disabled {
        color: #32325d;
        background-color: transparent;
    }
    
    .btn-outline-dark:not(:disabled):not(.disabled):active,
    .btn-outline-dark:not(:disabled):not(.disabled).active,
    .show>.btn-outline-dark.dropdown-toggle {
        color: #fff;
        background-color: #32325d;
        border-color: #32325d;
    }
    
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus,
    .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show>.btn-outline-dark.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(50, 50, 93, 0.5);
    }
    
    .btn-outline-cyan {
        color: #4fc3f7;
        background-color: transparent;
        background-image: none;
        border-color: #4fc3f7;
    }
    
    .btn-outline-cyan:hover {
        color: #212529;
        background-color: #4fc3f7;
        border-color: #4fc3f7;
    }
    
    .btn-outline-cyan:focus,
    .btn-outline-cyan.focus {
        box-shadow: 0 0 0 0.2rem rgba(79, 195, 247, 0.5);
    }
    
    .btn-outline-cyan.disabled,
    .btn-outline-cyan:disabled {
        color: #4fc3f7;
        background-color: transparent;
    }
    
    .btn-outline-cyan:not(:disabled):not(.disabled):active,
    .btn-outline-cyan:not(:disabled):not(.disabled).active,
    .show>.btn-outline-cyan.dropdown-toggle {
        color: #212529;
        background-color: #4fc3f7;
        border-color: #4fc3f7;
    }
    
    .btn-outline-cyan:not(:disabled):not(.disabled):active:focus,
    .btn-outline-cyan:not(:disabled):not(.disabled).active:focus,
    .show>.btn-outline-cyan.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(79, 195, 247, 0.5);
    }
    
    .btn-outline-orange {
        color: #fb6340;
        background-color: transparent;
        background-image: none;
        border-color: #fb6340;
    }
    
    .btn-outline-orange:hover {
        color: #fff;
        background-color: #fb6340;
        border-color: #fb6340;
    }
    
    .btn-outline-orange:focus,
    .btn-outline-orange.focus {
        box-shadow: 0 0 0 0.2rem rgba(251, 99, 64, 0.5);
    }
    
    .btn-outline-orange.disabled,
    .btn-outline-orange:disabled {
        color: #fb6340;
        background-color: transparent;
    }
    
    .btn-outline-orange:not(:disabled):not(.disabled):active,
    .btn-outline-orange:not(:disabled):not(.disabled).active,
    .show>.btn-outline-orange.dropdown-toggle {
        color: #fff;
        background-color: #fb6340;
        border-color: #fb6340;
    }
    
    .btn-outline-orange:not(:disabled):not(.disabled):active:focus,
    .btn-outline-orange:not(:disabled):not(.disabled).active:focus,
    .show>.btn-outline-orange.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(251, 99, 64, 0.5);
    }
    
    .btn-outline-purple {
        color: #5e72e4;
        background-color: transparent;
        background-image: none;
        border-color: #5e72e4;
    }
    
    .btn-outline-purple:hover {
        color: #fff;
        background-color: #5e72e4;
        border-color: #5e72e4;
    }
    
    .btn-outline-purple:focus,
    .btn-outline-purple.focus {
        box-shadow: 0 0 0 0.2rem rgba(94, 114, 228, 0.5);
    }
    
    .btn-outline-purple.disabled,
    .btn-outline-purple:disabled {
        color: #5e72e4;
        background-color: transparent;
    }
    
    .btn-outline-purple:not(:disabled):not(.disabled):active,
    .btn-outline-purple:not(:disabled):not(.disabled).active,
    .show>.btn-outline-purple.dropdown-toggle {
        color: #fff;
        background-color: #5e72e4;
        border-color: #5e72e4;
    }
    
    .btn-outline-purple:not(:disabled):not(.disabled):active:focus,
    .btn-outline-purple:not(:disabled):not(.disabled).active:focus,
    .show>.btn-outline-purple.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(94, 114, 228, 0.5);
    }
    
    .btn-outline-inverse {
        color: #32325d;
        background-color: transparent;
        background-image: none;
        border-color: #32325d;
    }
    
    .btn-outline-inverse:hover {
        color: #fff;
        background-color: #32325d;
        border-color: #32325d;
    }
    
    .btn-outline-inverse:focus,
    .btn-outline-inverse.focus {
        box-shadow: 0 0 0 0.2rem rgba(50, 50, 93, 0.5);
    }
    
    .btn-outline-inverse.disabled,
    .btn-outline-inverse:disabled {
        color: #32325d;
        background-color: transparent;
    }
    
    .btn-outline-inverse:not(:disabled):not(.disabled):active,
    .btn-outline-inverse:not(:disabled):not(.disabled).active,
    .show>.btn-outline-inverse.dropdown-toggle {
        color: #fff;
        background-color: #32325d;
        border-color: #32325d;
    }
    
    .btn-outline-inverse:not(:disabled):not(.disabled):active:focus,
    .btn-outline-inverse:not(:disabled):not(.disabled).active:focus,
    .show>.btn-outline-inverse.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(50, 50, 93, 0.5);
    }
    
    .btn-link {
        font-weight: 400;
        color: #5e72e4;
        background-color: transparent;
    }
    
    .btn-link:hover {
        color: #233dd2;
        text-decoration: none;
        background-color: transparent;
        border-color: transparent;
    }
    
    .btn-link:focus,
    .btn-link.focus {
        text-decoration: none;
        border-color: transparent;
        box-shadow: none;
    }
    
    .btn-link:disabled,
    .btn-link.disabled {
        color: #8898aa;
        pointer-events: none;
    }
    
    .btn-lg,
    .btn-group-lg>.btn {
        padding: 0.5rem 1rem;
        font-size: 1.09375rem;
        line-height: 1.5;
        border-radius: 4px;
    }
    
    .btn-sm,
    .btn-group-sm>.btn {
        padding: 0.25rem 0.5rem;
        font-size: 0.76563rem;
        line-height: 1.5;
        border-radius: 1px;
    }
    
    .btn-block {
        display: block;
        width: 100%;
    }
    
    .btn-block+.btn-block {
        margin-top: 0.5rem;
    }
    
    input[type="submit"].btn-block,
    input[type="reset"].btn-block,
    input[type="button"].btn-block {
        width: 100%;
    }
    
    .fade {
        transition: opacity 0.15s linear;
    }
    
    @media screen and (prefers-reduced-motion: reduce) {
        .fade {
            transition: none;
        }
    }
    
    .fade:not(.show) {
        opacity: 0;
    }
    
    .collapse:not(.show) {
        display: none;
    }
    
    .collapsing {
        position: relative;
        height: 0;
        overflow: hidden;
        transition: height 0.35s ease;
    }
    
    @media screen and (prefers-reduced-motion: reduce) {
        .collapsing {
            transition: none;
        }
    }
    
    .dropup,
    .dropright,
    .dropdown,
    .dropleft {
        position: relative;
    }
    
    .dropdown-toggle::after {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
    }
    
    .dropdown-toggle:empty::after {
        margin-left: 0;
    }
    
    .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        display: none;
        float: left;
        min-width: 10rem;
        padding: 0.5rem 0;
        margin: 0.125rem 0 0;
        font-size: 0.875rem;
        color: #3e5569;
        text-align: left;
        list-style: none;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #e9ecef;
        border-radius: 2px;
        box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
    }
    
    .dropdown-menu-right {
        right: 0;
        left: auto;
    }
    
    .dropup .dropdown-menu {
        top: auto;
        bottom: 100%;
        margin-top: 0;
        margin-bottom: 0.125rem;
    }
    
    .dropup .dropdown-toggle::after {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0;
        border-right: 0.3em solid transparent;
        border-bottom: 0.3em solid;
        border-left: 0.3em solid transparent;
    }
    
    .dropup .dropdown-toggle:empty::after {
        margin-left: 0;
    }
    
    .dropright .dropdown-menu {
        top: 0;
        right: auto;
        left: 100%;
        margin-top: 0;
        margin-left: 0.125rem;
    }
    
    .dropright .dropdown-toggle::after {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid transparent;
        border-right: 0;
        border-bottom: 0.3em solid transparent;
        border-left: 0.3em solid;
    }
    
    .dropright .dropdown-toggle:empty::after {
        margin-left: 0;
    }
    
    .dropright .dropdown-toggle::after {
        vertical-align: 0;
    }
    
    .dropleft .dropdown-menu {
        top: 0;
        right: 100%;
        left: auto;
        margin-top: 0;
        margin-right: 0.125rem;
    }
    
    .dropleft .dropdown-toggle::after {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
    }
    
    .dropleft .dropdown-toggle::after {
        display: none;
    }
    
    .dropleft .dropdown-toggle::before {
        display: inline-block;
        width: 0;
        height: 0;
        margin-right: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid transparent;
        border-right: 0.3em solid;
        border-bottom: 0.3em solid transparent;
    }
    
    .dropleft .dropdown-toggle:empty::after {
        margin-left: 0;
    }
    
    .dropleft .dropdown-toggle::before {
        vertical-align: 0;
    }
    
    .dropdown-menu[x-placement^="top"],
    .dropdown-menu[x-placement^="right"],
    .dropdown-menu[x-placement^="bottom"],
    .dropdown-menu[x-placement^="left"] {
        right: auto;
        bottom: auto;
    }
    
    .dropdown-divider {
        height: 0;
        margin: 0.5rem 0;
        overflow: hidden;
        border-top: 1px solid #f6f9fc;
    }
    
    .dropdown-item {
        display: block;
        width: 100%;
        padding: 0.65rem 1rem;
        clear: both;
        font-weight: 400;
        color: #212529;
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
    }
    
    .dropdown-item:hover,
    .dropdown-item:focus {
        color: #16181b;
        text-decoration: none;
        background-color: #f6f9fc;
    }
    
    .dropdown-item.active,
    .dropdown-item:active {
        color: #fff;
        text-decoration: none;
        background-color: #2c6daa;
    }
    
    .dropdown-item.disabled,
    .dropdown-item:disabled {
        color: #8898aa;
        background-color: transparent;
    }
    
    .dropdown-menu.show {
        display: block;
    }
    
    .dropdown-header {
        display: block;
        padding: 0.5rem 1rem;
        margin-bottom: 0;
        font-size: 0.76563rem;
        color: #8898aa;
        white-space: nowrap;
    }
    
    .dropdown-item-text {
        display: block;
        padding: 0.65rem 1rem;
        color: #212529;
    }
    
    .btn-group,
    .btn-group-vertical {
        position: relative;
        display: inline-flex;
        vertical-align: middle;
    }
    
    .btn-group>.btn,
    .btn-group-vertical>.btn {
        position: relative;
        flex: 0 1 auto;
    }
    
    .btn-group>.btn:hover,
    .btn-group-vertical>.btn:hover {
        z-index: 1;
    }
    
    .btn-group>.btn:focus,
    .btn-group>.btn:active,
    .btn-group>.btn.active,
    .btn-group-vertical>.btn:focus,
    .btn-group-vertical>.btn:active,
    .btn-group-vertical>.btn.active {
        z-index: 1;
    }
    
    .btn-group .btn+.btn,
    .btn-group .btn+.btn-group,
    .btn-group .btn-group+.btn,
    .btn-group .btn-group+.btn-group,
    .btn-group-vertical .btn+.btn,
    .btn-group-vertical .btn+.btn-group,
    .btn-group-vertical .btn-group+.btn,
    .btn-group-vertical .btn-group+.btn-group {
        margin-left: -1px;
    }
    
    .btn-toolbar {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    
    .btn-toolbar .input-group {
        width: auto;
    }
    
    .btn-group>.btn:first-child {
        margin-left: 0;
    }
    
    .btn-group>.btn:not(:last-child):not(.dropdown-toggle),
    .btn-group>.btn-group:not(:last-child)>.btn {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    
    .btn-group>.btn:not(:first-child),
    .btn-group>.btn-group:not(:first-child)>.btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    
    .dropdown-toggle-split {
        padding-right: 0.5625rem;
        padding-left: 0.5625rem;
    }
    
    .dropdown-toggle-split::after,
    .dropup .dropdown-toggle-split::after,
    .dropright .dropdown-toggle-split::after {
        margin-left: 0;
    }
    
    .dropleft .dropdown-toggle-split::before {
        margin-right: 0;
    }
    
    .btn-sm+.dropdown-toggle-split,
    .btn-group-sm>.btn+.dropdown-toggle-split {
        padding-right: 0.375rem;
        padding-left: 0.375rem;
    }
    
    .btn-lg+.dropdown-toggle-split,
    .btn-group-lg>.btn+.dropdown-toggle-split {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
    }
    
    .btn-group.show .dropdown-toggle {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
    
    .btn-group.show .dropdown-toggle.btn-link {
        box-shadow: none;
    }
    
    .btn-group-vertical {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    
    .btn-group-vertical .btn,
    .btn-group-vertical .btn-group {
        width: 100%;
    }
    
    .btn-group-vertical>.btn+.btn,
    .btn-group-vertical>.btn+.btn-group,
    .btn-group-vertical>.btn-group+.btn,
    .btn-group-vertical>.btn-group+.btn-group {
        margin-top: -1px;
        margin-left: 0;
    }
    
    .btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
    .btn-group-vertical>.btn-group:not(:last-child)>.btn {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    
    .btn-group-vertical>.btn:not(:first-child),
    .btn-group-vertical>.btn-group:not(:first-child)>.btn {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    
    .btn-group-toggle>.btn,
    .btn-group-toggle>.btn-group>.btn {
        margin-bottom: 0;
    }
    
    .btn-group-toggle>.btn input[type="radio"],
    .btn-group-toggle>.btn input[type="checkbox"],
    .btn-group-toggle>.btn-group>.btn input[type="radio"],
    .btn-group-toggle>.btn-group>.btn input[type="checkbox"] {
        position: absolute;
        clip: rect(0, 0, 0, 0);
        pointer-events: none;
    }
    
    .input-group {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;
    }
    
    .input-group>.form-control,
    .input-group>.custom-select,
    .input-group>.custom-file {
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        margin-bottom: 0;
    }
    
    .input-group>.form-control+.form-control,
    .input-group>.form-control+.custom-select,
    .input-group>.form-control+.custom-file,
    .input-group>.custom-select+.form-control,
    .input-group>.custom-select+.custom-select,
    .input-group>.custom-select+.custom-file,
    .input-group>.custom-file+.form-control,
    .input-group>.custom-file+.custom-select,
    .input-group>.custom-file+.custom-file {
        margin-left: -1px;
    }
    
    .input-group>.form-control:focus,
    .input-group>.custom-select:focus,
    .input-group>.custom-file .custom-file-input:focus~.custom-file-label {
        z-index: 3;
    }
    
    .input-group>.custom-file .custom-file-input:focus {
        z-index: 4;
    }
    
    .input-group>.form-control:not(:last-child),
    .input-group>.custom-select:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    
    .input-group>.form-control:not(:first-child),
    .input-group>.custom-select:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    
    .input-group>.custom-file {
        display: flex;
        align-items: center;
    }
    
    .input-group>.custom-file:not(:last-child) .custom-file-label,
    .input-group>.custom-file:not(:last-child) .custom-file-label::after {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    
    .input-group>.custom-file:not(:first-child) .custom-file-label {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    
    .input-group-prepend,
    .input-group-append {
        display: flex;
    }
    
    .input-group-prepend .btn,
    .input-group-append .btn {
        position: relative;
        z-index: 2;
    }
    
    .input-group-prepend .btn+.btn,
    .input-group-prepend .btn+.input-group-text,
    .input-group-prepend .input-group-text+.input-group-text,
    .input-group-prepend .input-group-text+.btn,
    .input-group-append .btn+.btn,
    .input-group-append .btn+.input-group-text,
    .input-group-append .input-group-text+.input-group-text,
    .input-group-append .input-group-text+.btn {
        margin-left: -1px;
    }
    
    .input-group-prepend {
        margin-right: -1px;
    }
    
    .input-group-append {
        margin-left: -1px;
    }
    
    .input-group-text {
        display: flex;
        align-items: center;
        padding: 0.375rem 0.75rem;
        margin-bottom: 0;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        color: #525f7f;
        text-align: center;
        white-space: nowrap;
        background-color: #f6f9fc;
        border: 1px solid #e9ecef;
        border-radius: 2px;
    }
    
    .input-group-text input[type="radio"],
    .input-group-text input[type="checkbox"] {
        margin-top: 0;
    }
    
    .input-group-lg>.form-control,
    .input-group-lg>.input-group-prepend>.input-group-text,
    .input-group-lg>.input-group-append>.input-group-text,
    .input-group-lg>.input-group-prepend>.btn,
    .input-group-lg>.input-group-append>.btn {
        height: calc(2.64063rem + 2px);
        padding: 0.5rem 1rem;
        font-size: 1.09375rem;
        line-height: 1.5;
        border-radius: 4px;
    }
    
    .input-group-sm>.form-control,
    .input-group-sm>.input-group-prepend>.input-group-text,
    .input-group-sm>.input-group-append>.input-group-text,
    .input-group-sm>.input-group-prepend>.btn,
    .input-group-sm>.input-group-append>.btn {
        height: calc(1.64844rem + 2px);
        padding: 0.25rem 0.5rem;
        font-size: 0.76563rem;
        line-height: 1.5;
        border-radius: 1px;
    }
    
    .input-group>.input-group-prepend>.btn,
    .input-group>.input-group-prepend>.input-group-text,
    .input-group>.input-group-append:not(:last-child)>.btn,
    .input-group>.input-group-append:not(:last-child)>.input-group-text,
    .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
    .input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    
    .input-group>.input-group-append>.btn,
    .input-group>.input-group-append>.input-group-text,
    .input-group>.input-group-prepend:not(:first-child)>.btn,
    .input-group>.input-group-prepend:not(:first-child)>.input-group-text,
    .input-group>.input-group-prepend:first-child>.btn:not(:first-child),
    .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    
    .custom-control {
        position: relative;
        display: block;
        min-height: 1.3125rem;
        padding-left: 1.5rem;
    }
    
    .custom-control-inline {
        display: inline-flex;
        margin-right: 1rem;
    }
    
    .custom-control-input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    
    .custom-control-input:checked~.custom-control-label::before {
        color: #fff;
        background-color: #2c6daa;
        box-shadow: none;
    }
    
    .custom-control-input:focus~.custom-control-label::before {
        box-shadow: none;
    }
    
    .custom-control-input:active~.custom-control-label::before {
        color: #fff;
        background-color: black;
        box-shadow: none;
    }
    
    .custom-control-input:disabled~.custom-control-label {
        color: #8898aa;
    }
    
    .custom-control-input:disabled~.custom-control-label::before {
        background-color: #e9ecef;
    }
    
    .custom-control-label {
        position: relative;
        margin-bottom: 0;
    }
    
    .custom-control-label::before {
        position: absolute;
        top: 0.15625rem;
        left: -1.5rem;
        display: block;
        width: 1rem;
        height: 1rem;
        pointer-events: none;
        content: "";
        user-select: none;
        background-color: #dee2e6;
        box-shadow: none;
    }
    
    .custom-control-label::after {
        position: absolute;
        top: 0.15625rem;
        left: -1.5rem;
        display: block;
        width: 1rem;
        height: 1rem;
        content: "";
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
    }
    
    .custom-checkbox .custom-control-label::before {
        border-radius: 2px;
    }
    
    .custom-checkbox .custom-control-input:checked~.custom-control-label::before {
        background-color: #2c6daa;
    }
    
    .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
    }
    
    .custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
        background-color: #2c6daa;
        box-shadow: none;
    }
    
    .custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
    }
    
    .custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
        background-color: rgba(94, 114, 228, 0.5);
    }
    
    .custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
        background-color: rgba(94, 114, 228, 0.5);
    }
    
    .custom-radio .custom-control-label::before {
        border-radius: 50%;
    }
    
    .custom-radio .custom-control-input:checked~.custom-control-label::before {
        background-color: #2c6daa;
    }
    
    .custom-radio .custom-control-input:checked~.custom-control-label::after {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
    }
    
    .custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
        background-color: rgba(94, 114, 228, 0.5);
    }
    
    .custom-select {
        display: inline-block;
        width: 100%;
        height: calc(2.0625rem + 2px);
        padding: 0.375rem 1.75rem 0.375rem 0.75rem;
        line-height: 1.5;
        color: #525f7f;
        vertical-align: middle;
        background: #fff url(../../assets/images/custom-select.png) no-repeat right 0.75rem center;
        background-size: 0px -1px;
        border: 1px solid #e9ecef;
        border-radius: 2px;
        box-shadow: none;
        appearance: none;
    }
    
    .custom-select:focus {
        border-color: rgba(0, 0, 0, 0.25);
        outline: 0;
        box-shadow: none, transparent;
    }
    
    .custom-select:focus::-ms-value {
        color: #525f7f;
        background-color: #fff;
    }
    
    .custom-select[multiple],
    .custom-select[size]:not([size="1"]) {
        height: auto;
        padding-right: 0.75rem;
        background-image: none;
    }
    
    .custom-select:disabled {
        color: #8898aa;
        background-color: #e9ecef;
    }
    
    .custom-select::-ms-expand {
        opacity: 0;
    }
    
    .custom-select-sm {
        height: calc(1.64844rem + 2px);
        padding-top: 0.375rem;
        padding-bottom: 0.375rem;
        font-size: 75%;
    }
    
    .custom-select-lg {
        height: calc(2.64063rem + 2px);
        padding-top: 0.375rem;
        padding-bottom: 0.375rem;
        font-size: 125%;
    }
    
    .custom-file {
        position: relative;
        display: inline-block;
        width: 100%;
        height: calc(2.0625rem + 2px);
        margin-bottom: 0;
    }
    
    .custom-file-input {
        position: relative;
        z-index: 2;
        width: 100%;
        height: calc(2.0625rem + 2px);
        margin: 0;
        opacity: 0;
    }
    
    .custom-file-input:focus~.custom-file-label {
        border-color: rgba(0, 0, 0, 0.25);
        box-shadow: none;
    }
    
    .custom-file-input:focus~.custom-file-label::after {
        border-color: rgba(0, 0, 0, 0.25);
    }
    
    .custom-file-input:disabled~.custom-file-label {
        background-color: #e9ecef;
    }
    
    .custom-file-input:lang(en)~.custom-file-label::after {
        content: "Browse";
    }
    
    .custom-file-label {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1;
        height: calc(2.0625rem + 2px);
        padding: 0.375rem 0.75rem;
        line-height: 1.5;
        color: #525f7f;
        background-color: #fff;
        border: 1px solid #e9ecef;
        border-radius: 2px;
        box-shadow: none;
    }
    
    .custom-file-label::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
        display: block;
        height: 2.0625rem;
        padding: 0.375rem 0.75rem;
        line-height: 1.5;
        color: #525f7f;
        content: "Browse";
        background-color: #f6f9fc;
        border-left: 1px solid #e9ecef;
        border-radius: 0 2px 2px 0;
    }
    
    .custom-range {
        width: 100%;
        padding-left: 0;
        background-color: transparent;
        appearance: none;
    }
    
    .custom-range:focus {
        outline: none;
    }
    
    .custom-range:focus::-webkit-slider-thumb {
        box-shadow: 0 0 0 1px #eef5f9, none;
    }
    
    .custom-range:focus::-moz-range-thumb {
        box-shadow: 0 0 0 1px #eef5f9, none;
    }
    
    .custom-range:focus::-ms-thumb {
        box-shadow: 0 0 0 1px #eef5f9, none;
    }
    
    .custom-range::-moz-focus-outer {
        border: 0;
    }
    
    .custom-range::-webkit-slider-thumb {
        width: 1rem;
        height: 1rem;
        margin-top: -0.25rem;
        background-color: #2c6daa;
        border: 0;
        border-radius: 1rem;
        box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        appearance: none;
    }
    
    @media screen and (prefers-reduced-motion: reduce) {
        .custom-range::-webkit-slider-thumb {
            transition: none;
        }
    }
    
    .custom-range::-webkit-slider-thumb:active {
        background-color: #dce5ff;
    }
    
    .custom-range::-webkit-slider-runnable-track {
        width: 100%;
        height: 0.5rem;
        color: transparent;
        cursor: pointer;
        background-color: #dee2e6;
        border-color: transparent;
        border-radius: 1rem;
        box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
    }
    
    .custom-range::-moz-range-thumb {
        width: 1rem;
        height: 1rem;
        background-color: #2c6daa;
        border: 0;
        border-radius: 1rem;
        box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        appearance: none;
    }
    
    @media screen and (prefers-reduced-motion: reduce) {
        .custom-range::-moz-range-thumb {
            transition: none;
        }
    }
    
    .custom-range::-moz-range-thumb:active {
        background-color: #dce5ff;
    }
    
    .custom-range::-moz-range-track {
        width: 100%;
        height: 0.5rem;
        color: transparent;
        cursor: pointer;
        background-color: #dee2e6;
        border-color: transparent;
        border-radius: 1rem;
        box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
    }
    
    .custom-range::-ms-thumb {
        width: 1rem;
        height: 1rem;
        margin-top: 0;
        margin-right: 0.2rem;
        margin-left: 0.2rem;
        background-color: #2c6daa;
        border: 0;
        border-radius: 1rem;
        box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        appearance: none;
    }
    
    @media screen and (prefers-reduced-motion: reduce) {
        .custom-range::-ms-thumb {
            transition: none;
        }
    }
    
    .custom-range::-ms-thumb:active {
        background-color: #dce5ff;
    }
    
    .custom-range::-ms-track {
        width: 100%;
        height: 0.5rem;
        color: transparent;
        cursor: pointer;
        background-color: transparent;
        border-color: transparent;
        border-width: 0.5rem;
        box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
    }
    
    .custom-range::-ms-fill-lower {
        background-color: #dee2e6;
        border-radius: 1rem;
    }
    
    .custom-range::-ms-fill-upper {
        margin-right: 15px;
        background-color: #dee2e6;
        border-radius: 1rem;
    }
    
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    
    @media screen and (prefers-reduced-motion: reduce) {
        .custom-control-label::before,
        .custom-file-label,
        .custom-select {
            transition: none;
        }
    }
    
    .nav {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }
    
    .nav-link {
        display: block;
        padding: 0.5rem 1rem;
    }
    
    .nav-link:hover,
    .nav-link:focus {
        text-decoration: none;
    }
    
    .nav-link.disabled {
        color: #8898aa;
    }
    
    .nav-tabs {
        border-bottom: 1px solid #dee2e6;
    }
    
    .nav-tabs .nav-item {
        margin-bottom: -1px;
    }
    
    .nav-tabs .nav-link {
        border: 1px solid transparent;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
    }
    
    .nav-tabs .nav-link:hover,
    .nav-tabs .nav-link:focus {
        border-color: #e9ecef #e9ecef #dee2e6;
    }
    
    .nav-tabs .nav-link.disabled {
        color: #8898aa;
        background-color: transparent;
        border-color: transparent;
    }
    
    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
        color: #525f7f;
        background-color: #fff;
        border-color: #dee2e6 #dee2e6 #fff;
    }
    
    .nav-tabs .dropdown-menu {
        margin-top: -1px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    
    .nav-pills .nav-link {
        border-radius: 2px;
    }
    
    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link {
        color: #fff;
        background-color: #2c6daa;
    }
    
    .nav-fill .nav-item {
        flex: 1 1 auto;
        text-align: center;
    }
    
    .nav-justified .nav-item {
        flex-basis: 0;
        flex-grow: 1;
        text-align: center;
    }
    
    .tab-content>.tab-pane {
        display: none;
    }
    
    .tab-content>.active {
        display: block;
    }
    
    .navbar {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 1rem;
    }
    
    .navbar>.container,
    .navbar>.container-fluid {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
    
    .navbar-brand {
        display: inline-block;
        padding-top: 0.33594rem;
        padding-bottom: 0.33594rem;
        margin-right: 1rem;
        font-size: 1.09375rem;
        line-height: inherit;
        white-space: nowrap;
    }
    
    .navbar-brand:hover,
    .navbar-brand:focus {
        text-decoration: none;
    }
    
    .navbar-nav {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }
    
    .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0;
    }
    
    .navbar-nav .dropdown-menu {
        position: static;
        float: none;
    }
    
    .navbar-text {
        display: inline-block;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    
    .navbar-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center;
    }
    
    .navbar-toggler {
        padding: 0.25rem 0.75rem;
        font-size: 1.09375rem;
        line-height: 1;
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 2px;
    }
    
    .navbar-toggler:hover,
    .navbar-toggler:focus {
        text-decoration: none;
    }
    
    .navbar-toggler:not(:disabled):not(.disabled) {
        cursor: pointer;
    }
    
    .navbar-toggler-icon {
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        vertical-align: middle;
        content: "";
        background: no-repeat center center;
        background-size: 100% 100%;
    }
    
    @media (max-width: 575.98px) {
        .navbar-expand-sm>.container,
        .navbar-expand-sm>.container-fluid {
            padding-right: 0;
            padding-left: 0;
        }
    }
    
    @media (min-width: 576px) {
        .navbar-expand-sm {
            flex-flow: row nowrap;
            justify-content: flex-start;
        }
        .navbar-expand-sm .navbar-nav {
            flex-direction: row;
        }
        .navbar-expand-sm .navbar-nav .dropdown-menu {
            position: absolute;
        }
        .navbar-expand-sm .navbar-nav .nav-link {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }
        .navbar-expand-sm>.container,
        .navbar-expand-sm>.container-fluid {
            flex-wrap: nowrap;
        }
        .navbar-expand-sm .navbar-collapse {
            display: flex !important;
            flex-basis: auto;
        }
        .navbar-expand-sm .navbar-toggler {
            display: none;
        }
    }
    
    @media (max-width: 799.98px) {
        .navbar-expand-md>.container,
        .navbar-expand-md>.container-fluid {
            padding-right: 0;
            padding-left: 0;
        }
    }
    
    @media (min-width: 800px) {
        .navbar-expand-md {
            flex-flow: row nowrap;
            justify-content: flex-start;
        }
        .navbar-expand-md .navbar-nav {
            flex-direction: row;
        }
        .navbar-expand-md .navbar-nav .dropdown-menu {
            position: absolute;
        }
        .navbar-expand-md .navbar-nav .nav-link {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }
        .navbar-expand-md>.container,
        .navbar-expand-md>.container-fluid {
            flex-wrap: nowrap;
        }
        .navbar-expand-md .navbar-collapse {
            display: flex !important;
            flex-basis: auto;
        }
        .navbar-expand-md .navbar-toggler {
            display: none;
        }
    }
    
    @media (max-width: 991.98px) {
        .navbar-expand-lg>.container,
        .navbar-expand-lg>.container-fluid {
            padding-right: 0;
            padding-left: 0;
        }
    }
    
    @media (min-width: 992px) {
        .navbar-expand-lg {
            flex-flow: row nowrap;
            justify-content: flex-start;
        }
        .navbar-expand-lg .navbar-nav {
            flex-direction: row;
        }
        .navbar-expand-lg .navbar-nav .dropdown-menu {
            position: absolute;
        }
        .navbar-expand-lg .navbar-nav .nav-link {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }
        .navbar-expand-lg>.container,
        .navbar-expand-lg>.container-fluid {
            flex-wrap: nowrap;
        }
        .navbar-expand-lg .navbar-collapse {
            display: flex !important;
            flex-basis: auto;
        }
        .navbar-expand-lg .navbar-toggler {
            display: none;
        }
    }
    
    @media (max-width: 1199.98px) {
        .navbar-expand-xl>.container,
        .navbar-expand-xl>.container-fluid {
            padding-right: 0;
            padding-left: 0;
        }
    }
    
    @media (min-width: 1200px) {
        .navbar-expand-xl {
            flex-flow: row nowrap;
            justify-content: flex-start;
        }
        .navbar-expand-xl .navbar-nav {
            flex-direction: row;
        }
        .navbar-expand-xl .navbar-nav .dropdown-menu {
            position: absolute;
        }
        .navbar-expand-xl .navbar-nav .nav-link {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }
        .navbar-expand-xl>.container,
        .navbar-expand-xl>.container-fluid {
            flex-wrap: nowrap;
        }
        .navbar-expand-xl .navbar-collapse {
            display: flex !important;
            flex-basis: auto;
        }
        .navbar-expand-xl .navbar-toggler {
            display: none;
        }
    }
    
    @media (max-width: 1599.98px) {
        .navbar-expand-xlg>.container,
        .navbar-expand-xlg>.container-fluid {
            padding-right: 0;
            padding-left: 0;
        }
    }
    
    @media (min-width: 1600px) {
        .navbar-expand-xlg {
            flex-flow: row nowrap;
            justify-content: flex-start;
        }
        .navbar-expand-xlg .navbar-nav {
            flex-direction: row;
        }
        .navbar-expand-xlg .navbar-nav .dropdown-menu {
            position: absolute;
        }
        .navbar-expand-xlg .navbar-nav .nav-link {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }
        .navbar-expand-xlg>.container,
        .navbar-expand-xlg>.container-fluid {
            flex-wrap: nowrap;
        }
        .navbar-expand-xlg .navbar-collapse {
            display: flex !important;
            flex-basis: auto;
        }
        .navbar-expand-xlg .navbar-toggler {
            display: none;
        }
    }
    
    .navbar-expand {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    
    .navbar-expand>.container,
    .navbar-expand>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
    
    .navbar-expand .navbar-nav {
        flex-direction: row;
    }
    
    .navbar-expand .navbar-nav .dropdown-menu {
        position: absolute;
    }
    
    .navbar-expand .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    
    .navbar-expand>.container,
    .navbar-expand>.container-fluid {
        flex-wrap: nowrap;
    }
    
    .navbar-expand .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    
    .navbar-expand .navbar-toggler {
        display: none;
    }
    
    .navbar-light .navbar-brand {
        color: rgba(0, 0, 0, 0.9);
    }
    
    .navbar-light .navbar-brand:hover,
    .navbar-light .navbar-brand:focus {
        color: rgba(0, 0, 0, 0.9);
    }
    
    .navbar-light .navbar-nav .nav-link {
        color: rgba(0, 0, 0, 0.5);
    }
    
    .navbar-light .navbar-nav .nav-link:hover,
    .navbar-light .navbar-nav .nav-link:focus {
        color: rgba(0, 0, 0, 0.7);
    }
    
    .navbar-light .navbar-nav .nav-link.disabled {
        color: rgba(0, 0, 0, 0.3);
    }
    
    .navbar-light .navbar-nav .show>.nav-link,
    .navbar-light .navbar-nav .active>.nav-link,
    .navbar-light .navbar-nav .nav-link.show,
    .navbar-light .navbar-nav .nav-link.active {
        color: rgba(0, 0, 0, 0.9);
    }
    
    .navbar-light .navbar-toggler {
        color: rgba(0, 0, 0, 0.5);
        border-color: rgba(0, 0, 0, 0.1);
    }
    
    .navbar-light .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }
    
    .navbar-light .navbar-text {
        color: rgba(0, 0, 0, 0.5);
    }
    
    .navbar-light .navbar-text a {
        color: rgba(0, 0, 0, 0.9);
    }
    
    .navbar-light .navbar-text a:hover,
    .navbar-light .navbar-text a:focus {
        color: rgba(0, 0, 0, 0.9);
    }
    
    .navbar-dark .navbar-brand {
        color: #fff;
    }
    
    .navbar-dark .navbar-brand:hover,
    .navbar-dark .navbar-brand:focus {
        color: #fff;
    }
    
    .navbar-dark .navbar-nav .nav-link {
        color: rgba(255, 255, 255, 0.8);
    }
    
    .navbar-dark .navbar-nav .nav-link:hover,
    .navbar-dark .navbar-nav .nav-link:focus {
        color: white;
    }
    
    .navbar-dark .navbar-nav .nav-link.disabled {
        color: rgba(255, 255, 255, 0.25);
    }
    
    .navbar-dark .navbar-nav .show>.nav-link,
    .navbar-dark .navbar-nav .active>.nav-link,
    .navbar-dark .navbar-nav .nav-link.show,
    .navbar-dark .navbar-nav .nav-link.active {
        color: #fff;
    }
    
    .navbar-dark .navbar-toggler {
        color: rgba(255, 255, 255, 0.8);
        border-color: rgba(255, 255, 255, 0.1);
    }
    
    .navbar-dark .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }
    
    .navbar-dark .navbar-text {
        color: rgba(255, 255, 255, 0.8);
    }
    
    .navbar-dark .navbar-text a {
        color: #fff;
    }
    
    .navbar-dark .navbar-text a:hover,
    .navbar-dark .navbar-text a:focus {
        color: #fff;
    }
    
    .card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 0px solid transparent;
        border-radius: 4px;
    }
    
    .card>hr {
        margin-right: 0;
        margin-left: 0;
    }
    
    .card>.list-group:first-child .list-group-item:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    
    .card>.list-group:last-child .list-group-item:last-child {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    
    .card-body {
        flex: 1 1 auto;
        padding: 1.25rem;
    }
    
    .card-title {
        margin-bottom: 0.75rem;
    }
    
    .card-subtitle {
        margin-top: -0.375rem;
        margin-bottom: 0;
    }
    
    .card-text:last-child {
        margin-bottom: 0;
    }
    
    .card-link:hover {
        text-decoration: none;
    }
    
    .card-link+.card-link {
        margin-left: 1.25rem;
    }
    
    .card-header {
        padding: 0.75rem 1.25rem;
        margin-bottom: 0;
        background-color: rgba(0, 0, 0, 0.03);
        border-bottom: 0px solid transparent;
    }
    
    .card-header:first-child {
        border-radius: calc(4px - 0px) calc(4px - 0px) 0 0;
    }
    
    .card-header+.list-group .list-group-item:first-child {
        border-top: 0;
    }
    
    .card-footer {
        padding: 0.75rem 1.25rem;
        background-color: rgba(0, 0, 0, 0.03);
        border-top: 0px solid transparent;
    }
    
    .card-footer:last-child {
        border-radius: 0 0 calc(4px - 0px) calc(4px - 0px);
    }
    
    .card-header-tabs {
        margin-right: -0.625rem;
        margin-bottom: -0.75rem;
        margin-left: -0.625rem;
        border-bottom: 0;
    }
    
    .card-header-pills {
        margin-right: -0.625rem;
        margin-left: -0.625rem;
    }
    
    .card-img-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 1.25rem;
    }
    
    .card-img {
        width: 100%;
        border-radius: calc(4px - 0px);
    }
    
    .card-img-top {
        width: 100%;
        border-top-left-radius: calc(4px - 0px);
        border-top-right-radius: calc(4px - 0px);
    }
    
    .card-img-bottom {
        width: 100%;
        border-bottom-right-radius: calc(4px - 0px);
        border-bottom-left-radius: calc(4px - 0px);
    }
    
    .card-deck {
        display: flex;
        flex-direction: column;
    }
    
    .card-deck .card {
        margin-bottom: 15px;
    }
    
    @media (min-width: 576px) {
        .card-deck {
            flex-flow: row wrap;
            margin-right: -15px;
            margin-left: -15px;
        }
        .card-deck .card {
            display: flex;
            flex: 1 0 0%;
            flex-direction: column;
            margin-right: 15px;
            margin-bottom: 0;
            margin-left: 15px;
        }
    }
    
    .card-group {
        display: flex;
        flex-direction: column;
    }
    
    .card-group>.card {
        margin-bottom: 15px;
    }
    
    @media (min-width: 576px) {
        .card-group {
            flex-flow: row wrap;
        }
        .card-group>.card {
            flex: 1 0 0%;
            margin-bottom: 0;
        }
        .card-group>.card+.card {
            margin-left: 0;
            border-left: 0;
        }
        .card-group>.card:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .card-group>.card:first-child .card-img-top,
        .card-group>.card:first-child .card-header {
            border-top-right-radius: 0;
        }
        .card-group>.card:first-child .card-img-bottom,
        .card-group>.card:first-child .card-footer {
            border-bottom-right-radius: 0;
        }
        .card-group>.card:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        .card-group>.card:last-child .card-img-top,
        .card-group>.card:last-child .card-header {
            border-top-left-radius: 0;
        }
        .card-group>.card:last-child .card-img-bottom,
        .card-group>.card:last-child .card-footer {
            border-bottom-left-radius: 0;
        }
        .card-group>.card:only-child {
            border-radius: 4px;
        }
        .card-group>.card:only-child .card-img-top,
        .card-group>.card:only-child .card-header {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }
        .card-group>.card:only-child .card-img-bottom,
        .card-group>.card:only-child .card-footer {
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
        }
        .card-group>.card:not(:first-child):not(:last-child):not(:only-child) {
            border-radius: 0;
        }
        .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
        .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
        .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-header,
        .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0;
        }
    }
    
    .card-columns .card {
        margin-bottom: 0.75rem;
    }
    
    @media (min-width: 576px) {
        .card-columns {
            column-count: 3;
            column-gap: 1.25rem;
            orphans: 1;
            widows: 1;
        }
        .card-columns .card {
            display: inline-block;
            width: 100%;
        }
    }
    
    .accordion .card:not(:first-of-type):not(:last-of-type) {
        border-bottom: 0;
        border-radius: 0;
    }
    
    .accordion .card:not(:first-of-type) .card-header:first-child {
        border-radius: 0;
    }
    
    .accordion .card:first-of-type {
        border-bottom: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    
    .accordion .card:last-of-type {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    
    .breadcrumb {
        display: flex;
        flex-wrap: wrap;
        padding: 0.75rem 1rem;
        margin-bottom: 1.5rem;
        list-style: none;
        background-color: #eef5f9;
        border-radius: 2px;
    }
    
    .breadcrumb-item+.breadcrumb-item {
        padding-left: 0.5rem;
    }
    
    .breadcrumb-item+.breadcrumb-item::before {
        display: inline-block;
        padding-right: 0.5rem;
        color: #8898aa;
        content: "/";
    }
    
    .breadcrumb-item+.breadcrumb-item:hover::before {
        text-decoration: underline;
    }
    
    .breadcrumb-item+.breadcrumb-item:hover::before {
        text-decoration: none;
    }
    
    .breadcrumb-item.active {
        color: #8898aa;
    }
    
    .pagination {
        display: flex;
        padding-left: 0;
        list-style: none;
        border-radius: 2px;
    }
    
    .page-link {
        position: relative;
        display: block;
        padding: 0.5rem 0.75rem;
        margin-left: -1px;
        line-height: 1.25;
        color: #5e72e4;
        background-color: #fff;
        border: 1px solid #dee2e6;
    }
    
    .page-link:hover {
        z-index: 2;
        color: #233dd2;
        text-decoration: none;
        background-color: #e9ecef;
        border-color: #dee2e6;
    }
    
    .page-link:focus {
        z-index: 2;
        outline: 0;
        box-shadow: none;
    }
    
    .page-link:not(:disabled):not(.disabled) {
        cursor: pointer;
    }
    
    .page-item:first-child .page-link {
        margin-left: 0;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
    }
    
    .page-item:last-child .page-link {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }
    
    .page-item.active .page-link {
        z-index: 1;
        color: #fff;
        background-color: #2c6daa;
        border-color: #2c6daa;
    }
    
    .page-item.disabled .page-link {
        color: #8898aa;
        pointer-events: none;
        cursor: auto;
        background-color: #fff;
        border-color: #dee2e6;
    }
    
    .pagination-lg .page-link {
        padding: 0.75rem 1.5rem;
        font-size: 1.09375rem;
        line-height: 1.5;
    }
    
    .pagination-lg .page-item:first-child .page-link {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    
    .pagination-lg .page-item:last-child .page-link {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    
    .pagination-sm .page-link {
        padding: 0.25rem 0.5rem;
        font-size: 0.76563rem;
        line-height: 1.5;
    }
    
    .pagination-sm .page-item:first-child .page-link {
        border-top-left-radius: 1px;
        border-bottom-left-radius: 1px;
    }
    
    .pagination-sm .page-item:last-child .page-link {
        border-top-right-radius: 1px;
        border-bottom-right-radius: 1px;
    }
    
    .badge {
        display: inline-block;
        padding: 0.35em 0.7em;
        font-size: 73%;
        font-weight: 300;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 2px;
    }
    
    .badge:empty {
        display: none;
    }
    
    .btn .badge {
        position: relative;
        top: -1px;
    }
    
    .badge-pill {
        padding-right: 0.7em;
        padding-left: 0.7em;
        border-radius: 10rem;
    }
    
    .badge-primary {
        color: #fff;
        background-color: #5e72e4;
    }
    
    .badge-primary[href]:hover,
    .badge-primary[href]:focus {
        color: #fff;
        text-decoration: none;
        background-color: #324cdd;
    }
    
    .badge-secondary {
        color: #fff;
        background-color: #8898aa;
    }
    
    .badge-secondary[href]:hover,
    .badge-secondary[href]:focus {
        color: #fff;
        text-decoration: none;
        background-color: #6a7e95;
    }
    
    .badge-success {
        color: #fff;
        background-color: #2dce89;
    }
    
    .badge-success[href]:hover,
    .badge-success[href]:focus {
        color: #fff;
        text-decoration: none;
        background-color: #24a46d;
    }
    
    .badge-info {
        color: #fff;
        background-color: #2c6daa;
    }
    
    .badge-info[href]:hover,
    .badge-info[href]:focus {
        color: #fff;
        text-decoration: none;
        background-color: #0041f5;
    }
    
    .badge-warning {
        color: #fff;
        background-color: #fb6340;
    }
    
    .badge-warning[href]:hover,
    .badge-warning[href]:focus {
        color: #fff;
        text-decoration: none;
        background-color: #fa3a0e;
    }
    
    .badge-danger {
        color: #fff;
        background-color: #f62d51;
    }
    
    .badge-danger[href]:hover,
    .badge-danger[href]:focus {
        color: #fff;
        text-decoration: none;
        background-color: #e60a31;
    }
    
    .badge-light {
        color: #212529;
        background-color: #f6f9fc;
    }
    
    .badge-light[href]:hover,
    .badge-light[href]:focus {
        color: #212529;
        text-decoration: none;
        background-color: #d0e0ef;
    }
    
    .badge-dark {
        color: #fff;
        background-color: #32325d;
    }
    
    .badge-dark[href]:hover,
    .badge-dark[href]:focus {
        color: #fff;
        text-decoration: none;
        background-color: #20203c;
    }
    
    .badge-cyan {
        color: #212529;
        background-color: #4fc3f7;
    }
    
    .badge-cyan[href]:hover,
    .badge-cyan[href]:focus {
        color: #212529;
        text-decoration: none;
        background-color: #1eb2f5;
    }
    
    .badge-orange {
        color: #fff;
        background-color: #fb6340;
    }
    
    .badge-orange[href]:hover,
    .badge-orange[href]:focus {
        color: #fff;
        text-decoration: none;
        background-color: #fa3a0e;
    }
    
    .badge-purple {
        color: #fff;
        background-color: #5e72e4;
    }
    
    .badge-purple[href]:hover,
    .badge-purple[href]:focus {
        color: #fff;
        text-decoration: none;
        background-color: #324cdd;
    }
    
    .badge-inverse {
        color: #fff;
        background-color: #32325d;
    }
    
    .badge-inverse[href]:hover,
    .badge-inverse[href]:focus {
        color: #fff;
        text-decoration: none;
        background-color: #20203c;
    }
    
    .jumbotron {
        padding: 2rem 1rem;
        margin-bottom: 2rem;
        background-color: #e9ecef;
        border-radius: 4px;
    }
    
    @media (min-width: 576px) {
        .jumbotron {
            padding: 4rem 2rem;
        }
    }
    
    .jumbotron-fluid {
        padding-right: 0;
        padding-left: 0;
        border-radius: 0;
    }
    
    .alert {
        position: relative;
        padding: 0.75rem 1.25rem;
        margin-bottom: 1rem;
        border: 1px solid transparent;
        border-radius: 2px;
    }
    
    .alert-heading {
        color: inherit;
    }
    
    .alert-link {
        font-weight: 800;
    }
    
    .alert-dismissible {
        padding-right: 3.8125rem;
    }
    
    .alert-dismissible .close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.75rem 1.25rem;
        color: inherit;
    }
    
    .alert-primary {
        color: #313b77;
        background-color: #dfe3fa;
        border-color: #d2d8f7;
    }
    
    .alert-primary hr {
        border-top-color: #bcc5f3;
    }
    
    .alert-primary .alert-link {
        color: #222953;
    }
    
    .alert-secondary {
        color: #474f58;
        background-color: #e7eaee;
        border-color: #dee2e7;
    }
    
    .alert-secondary hr {
        border-top-color: #cfd5dc;
    }
    
    .alert-secondary .alert-link {
        color: #30363c;
    }
    
    .alert-success {
        color: #176b47;
        background-color: #d5f5e7;
        border-color: #c4f1de;
    }
    
    .alert-success hr {
        border-top-color: #afecd2;
    }
    
    .alert-success .alert-link {
        color: #0e412b;
    }
    
    .alert-info {
        color: #153385;
        background-color: #d4e0ff;
        border-color: #c3d3ff;
    }
    
    .alert-info hr {
        border-top-color: #aac0ff;
    }
    
    .alert-info .alert-link {
        color: #0e2259;
    }
    
    .alert-warning {
        color: #833321;
        background-color: #fee0d9;
        border-color: #fed3ca;
    }
    
    .alert-warning hr {
        border-top-color: #febeb1;
    }
    
    .alert-warning .alert-link {
        color: #5a2317;
    }
    
    .alert-danger {
        color: #80172a;
        background-color: #fdd5dc;
        border-color: #fcc4ce;
    }
    
    .alert-danger hr {
        border-top-color: #fbacba;
    }
    
    .alert-danger .alert-link {
        color: #550f1c;
    }
    
    .alert-light {
        color: #808183;
        background-color: #fdfefe;
        border-color: #fcfdfe;
    }
    
    .alert-light hr {
        border-top-color: #e9f0f8;
    }
    
    .alert-light .alert-link {
        color: #676869;
    }
    
    .alert-dark {
        color: #1a1a30;
        background-color: #d6d6df;
        border-color: #c6c6d2;
    }
    
    .alert-dark hr {
        border-top-color: #b8b8c7;
    }
    
    .alert-dark .alert-link {
        color: #08080f;
    }
    
    .alert-cyan {
        color: #296580;
        background-color: #dcf3fd;
        border-color: #ceeefd;
    }
    
    .alert-cyan hr {
        border-top-color: #b6e6fc;
    }
    
    .alert-cyan .alert-link {
        color: #1d4759;
    }
    
    .alert-orange {
        color: #833321;
        background-color: #fee0d9;
        border-color: #fed3ca;
    }
    
    .alert-orange hr {
        border-top-color: #febeb1;
    }
    
    .alert-orange .alert-link {
        color: #5a2317;
    }
    
    .alert-purple {
        color: #313b77;
        background-color: #dfe3fa;
        border-color: #d2d8f7;
    }
    
    .alert-purple hr {
        border-top-color: #bcc5f3;
    }
    
    .alert-purple .alert-link {
        color: #222953;
    }
    
    .alert-inverse {
        color: #1a1a30;
        background-color: #d6d6df;
        border-color: #c6c6d2;
    }
    
    .alert-inverse hr {
        border-top-color: #b8b8c7;
    }
    
    .alert-inverse .alert-link {
        color: #08080f;
    }
    
    @keyframes progress-bar-stripes {
        from {
            background-position: 6px 0;
        }
        to {
            background-position: 0 0;
        }
    }
    
    .progress {
        display: flex;
        height: 6px;
        overflow: hidden;
        font-size: 0.65625rem;
        background-color: #f6f9fc;
        border-radius: 2px;
        box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
    }
    
    .progress-bar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        background-color: #5e72e4;
        transition: width 0.6s ease;
    }
    
    @media screen and (prefers-reduced-motion: reduce) {
        .progress-bar {
            transition: none;
        }
    }
    
    .progress-bar-striped {
        background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
        background-size: 6px 6px;
    }
    
    .progress-bar-animated {
        animation: progress-bar-stripes 1s linear infinite;
    }
    
    .media {
        display: flex;
        align-items: flex-start;
    }
    
    .media-body {
        flex: 1;
    }
    
    .list-group {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
    }
    
    .list-group-item-action {
        width: 100%;
        color: #525f7f;
        text-align: inherit;
    }
    
    .list-group-item-action:hover,
    .list-group-item-action:focus {
        color: #525f7f;
        text-decoration: none;
        background-color: #f6f9fc;
    }
    
    .list-group-item-action:active {
        color: #3e5569;
        background-color: #e9ecef;
    }
    
    .list-group-item {
        position: relative;
        display: block;
        padding: 0.75rem 1.25rem;
        margin-bottom: -1px;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.125);
    }
    
    .list-group-item:first-child {
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
    }
    
    .list-group-item:last-child {
        margin-bottom: 0;
        border-bottom-right-radius: 2px;
        border-bottom-left-radius: 2px;
    }
    
    .list-group-item:hover,
    .list-group-item:focus {
        z-index: 1;
        text-decoration: none;
    }
    
    .list-group-item.disabled,
    .list-group-item:disabled {
        color: #8898aa;
        background-color: #fff;
    }
    
    .list-group-item.active {
        z-index: 2;
        color: #fff;
        background-color: #2c6daa;
        border-color: #2c6daa;
    }
    
    .list-group-flush .list-group-item {
        border-right: 0;
        border-left: 0;
        border-radius: 0;
    }
    
    .list-group-flush:first-child .list-group-item:first-child {
        border-top: 0;
    }
    
    .list-group-flush:last-child .list-group-item:last-child {
        border-bottom: 0;
    }
    
    .list-group-item-primary {
        color: #313b77;
        background-color: #d2d8f7;
    }
    
    .list-group-item-primary.list-group-item-action:hover,
    .list-group-item-primary.list-group-item-action:focus {
        color: #313b77;
        background-color: #bcc5f3;
    }
    
    .list-group-item-primary.list-group-item-action.active {
        color: #fff;
        background-color: #313b77;
        border-color: #313b77;
    }
    
    .list-group-item-secondary {
        color: #474f58;
        background-color: #dee2e7;
    }
    
    .list-group-item-secondary.list-group-item-action:hover,
    .list-group-item-secondary.list-group-item-action:focus {
        color: #474f58;
        background-color: #cfd5dc;
    }
    
    .list-group-item-secondary.list-group-item-action.active {
        color: #fff;
        background-color: #474f58;
        border-color: #474f58;
    }
    
    .list-group-item-success {
        color: #176b47;
        background-color: #c4f1de;
    }
    
    .list-group-item-success.list-group-item-action:hover,
    .list-group-item-success.list-group-item-action:focus {
        color: #176b47;
        background-color: #afecd2;
    }
    
    .list-group-item-success.list-group-item-action.active {
        color: #fff;
        background-color: #176b47;
        border-color: #176b47;
    }
    
    .list-group-item-info {
        color: #153385;
        background-color: #c3d3ff;
    }
    
    .list-group-item-info.list-group-item-action:hover,
    .list-group-item-info.list-group-item-action:focus {
        color: #153385;
        background-color: #aac0ff;
    }
    
    .list-group-item-info.list-group-item-action.active {
        color: #fff;
        background-color: #153385;
        border-color: #153385;
    }
    
    .list-group-item-warning {
        color: #833321;
        background-color: #fed3ca;
    }
    
    .list-group-item-warning.list-group-item-action:hover,
    .list-group-item-warning.list-group-item-action:focus {
        color: #833321;
        background-color: #febeb1;
    }
    
    .list-group-item-warning.list-group-item-action.active {
        color: #fff;
        background-color: #833321;
        border-color: #833321;
    }
    
    .list-group-item-danger {
        color: #80172a;
        background-color: #fcc4ce;
    }
    
    .list-group-item-danger.list-group-item-action:hover,
    .list-group-item-danger.list-group-item-action:focus {
        color: #80172a;
        background-color: #fbacba;
    }
    
    .list-group-item-danger.list-group-item-action.active {
        color: #fff;
        background-color: #80172a;
        border-color: #80172a;
    }
    
    .list-group-item-light {
        color: #808183;
        background-color: #fcfdfe;
    }
    
    .list-group-item-light.list-group-item-action:hover,
    .list-group-item-light.list-group-item-action:focus {
        color: #808183;
        background-color: #e9f0f8;
    }
    
    .list-group-item-light.list-group-item-action.active {
        color: #fff;
        background-color: #808183;
        border-color: #808183;
    }
    
    .list-group-item-dark {
        color: #1a1a30;
        background-color: #c6c6d2;
    }
    
    .list-group-item-dark.list-group-item-action:hover,
    .list-group-item-dark.list-group-item-action:focus {
        color: #1a1a30;
        background-color: #b8b8c7;
    }
    
    .list-group-item-dark.list-group-item-action.active {
        color: #fff;
        background-color: #1a1a30;
        border-color: #1a1a30;
    }
    
    .list-group-item-cyan {
        color: #296580;
        background-color: #ceeefd;
    }
    
    .list-group-item-cyan.list-group-item-action:hover,
    .list-group-item-cyan.list-group-item-action:focus {
        color: #296580;
        background-color: #b6e6fc;
    }
    
    .list-group-item-cyan.list-group-item-action.active {
        color: #fff;
        background-color: #296580;
        border-color: #296580;
    }
    
    .list-group-item-orange {
        color: #833321;
        background-color: #fed3ca;
    }
    
    .list-group-item-orange.list-group-item-action:hover,
    .list-group-item-orange.list-group-item-action:focus {
        color: #833321;
        background-color: #febeb1;
    }
    
    .list-group-item-orange.list-group-item-action.active {
        color: #fff;
        background-color: #833321;
        border-color: #833321;
    }
    
    .list-group-item-purple {
        color: #313b77;
        background-color: #d2d8f7;
    }
    
    .list-group-item-purple.list-group-item-action:hover,
    .list-group-item-purple.list-group-item-action:focus {
        color: #313b77;
        background-color: #bcc5f3;
    }
    
    .list-group-item-purple.list-group-item-action.active {
        color: #fff;
        background-color: #313b77;
        border-color: #313b77;
    }
    
    .list-group-item-inverse {
        color: #1a1a30;
        background-color: #c6c6d2;
    }
    
    .list-group-item-inverse.list-group-item-action:hover,
    .list-group-item-inverse.list-group-item-action:focus {
        color: #1a1a30;
        background-color: #b8b8c7;
    }
    
    .list-group-item-inverse.list-group-item-action.active {
        color: #fff;
        background-color: #1a1a30;
        border-color: #1a1a30;
    }
    
    .close {
        float: right;
        font-size: 1.3125rem;
        font-weight: 800;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        opacity: .5;
    }
    
    .close:not(:disabled):not(.disabled) {
        cursor: pointer;
    }
    
    .close:not(:disabled):not(.disabled):hover,
    .close:not(:disabled):not(.disabled):focus {
        color: #000;
        text-decoration: none;
        opacity: .75;
    }
    
    button.close {
        padding: 0;
        background-color: transparent;
        border: 0;
        -webkit-appearance: none;
    }
    
    .modal-open {
        overflow: hidden;
    }
    
    .modal-open .modal {
        overflow-x: hidden;
        overflow-y: auto;
    }
    
    .modal {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1050;
        display: none;
        overflow: hidden;
        outline: 0;
    }
    
    .modal-dialog {
        position: relative;
        width: auto;
        margin: 0.5rem;
        pointer-events: none;
    }
    
    .modal.fade .modal-dialog {
        transition: transform 0.3s ease-out;
        transform: translate(0, -25%);
    }
    
    @media screen and (prefers-reduced-motion: reduce) {
        .modal.fade .modal-dialog {
            transition: none;
        }
    }
    
    .modal.show .modal-dialog {
        transform: translate(0, 0);
    }
    
    .modal-dialog-centered {
        display: flex;
        align-items: center;
        min-height: calc(100% - (0.5rem * 2));
    }
    
    .modal-dialog-centered::before {
        display: block;
        height: calc(100vh - (0.5rem * 2));
        content: "";
    }
    
    .modal-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
        outline: 0;
    }
    
    .modal-backdrop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1040;
        background-color: #000;
    }
    
    .modal-backdrop.fade {
        opacity: 0;
    }
    
    .modal-backdrop.show {
        opacity: 0.5;
    }
    
    .modal-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1rem;
        border-bottom: 1px solid #e9ecef;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    
    .modal-header .close {
        padding: 1rem;
        margin: -1rem -1rem -1rem auto;
    }
    
    .modal-title {
        margin-bottom: 0;
        line-height: 1.5;
    }
    
    .modal-body {
        position: relative;
        flex: 1 1 auto;
        padding: 1rem;
    }
    
    .modal-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 1rem;
        border-top: 1px solid #e9ecef;
    }
    
    .modal-footer> :not(:first-child) {
        margin-left: .25rem;
    }
    
    .modal-footer> :not(:last-child) {
        margin-right: .25rem;
    }
    
    .modal-scrollbar-measure {
        position: absolute;
        top: -9999px;
        width: 50px;
        height: 50px;
        overflow: scroll;
    }
    
    @media (min-width: 576px) {
        .modal-dialog {
            max-width: 500px;
            margin: 1.75rem auto;
        }
        .modal-dialog-centered {
            min-height: calc(100% - (1.75rem * 2));
        }
        .modal-dialog-centered::before {
            height: calc(100vh - (1.75rem * 2));
        }
        .modal-content {
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
        }
        .modal-sm {
            max-width: 300px;
        }
    }
    
    @media (min-width: 992px) {
        .modal-lg {
            max-width: 800px;
        }
    }
    
    .tooltip {
        position: absolute;
        z-index: 1070;
        display: block;
        margin: 0;
        font-family: "Nunito Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5;
        text-align: left;
        text-align: start;
        text-decoration: none;
        text-shadow: none;
        text-transform: none;
        letter-spacing: normal;
        word-break: normal;
        word-spacing: normal;
        white-space: normal;
        line-break: auto;
        font-size: 0.76563rem;
        word-wrap: break-word;
        opacity: 0;
    }
    
    .tooltip.show {
        opacity: 0.9;
    }
    
    .tooltip .arrow {
        position: absolute;
        display: block;
        width: 0.8rem;
        height: 0.4rem;
    }
    
    .tooltip .arrow::before {
        position: absolute;
        content: "";
        border-color: transparent;
        border-style: solid;
    }
    
    .bs-tooltip-top,
    .bs-tooltip-auto[x-placement^="top"] {
        padding: 0.4rem 0;
    }
    
    .bs-tooltip-top .arrow,
    .bs-tooltip-auto[x-placement^="top"] .arrow {
        bottom: 0;
    }
    
    .bs-tooltip-top .arrow::before,
    .bs-tooltip-auto[x-placement^="top"] .arrow::before {
        top: 0;
        border-width: 0.4rem 0.4rem 0;
        border-top-color: #000;
    }
    
    .bs-tooltip-right,
    .bs-tooltip-auto[x-placement^="right"] {
        padding: 0 0.4rem;
    }
    
    .bs-tooltip-right .arrow,
    .bs-tooltip-auto[x-placement^="right"] .arrow {
        left: 0;
        width: 0.4rem;
        height: 0.8rem;
    }
    
    .bs-tooltip-right .arrow::before,
    .bs-tooltip-auto[x-placement^="right"] .arrow::before {
        right: 0;
        border-width: 0.4rem 0.4rem 0.4rem 0;
        border-right-color: #000;
    }
    
    .bs-tooltip-bottom,
    .bs-tooltip-auto[x-placement^="bottom"] {
        padding: 0.4rem 0;
    }
    
    .bs-tooltip-bottom .arrow,
    .bs-tooltip-auto[x-placement^="bottom"] .arrow {
        top: 0;
    }
    
    .bs-tooltip-bottom .arrow::before,
    .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
        bottom: 0;
        border-width: 0 0.4rem 0.4rem;
        border-bottom-color: #000;
    }
    
    .bs-tooltip-left,
    .bs-tooltip-auto[x-placement^="left"] {
        padding: 0 0.4rem;
    }
    
    .bs-tooltip-left .arrow,
    .bs-tooltip-auto[x-placement^="left"] .arrow {
        right: 0;
        width: 0.4rem;
        height: 0.8rem;
    }
    
    .bs-tooltip-left .arrow::before,
    .bs-tooltip-auto[x-placement^="left"] .arrow::before {
        left: 0;
        border-width: 0.4rem 0 0.4rem 0.4rem;
        border-left-color: #000;
    }
    
    .tooltip-inner {
        max-width: 200px;
        padding: 0.25rem 0.5rem;
        color: #fff;
        text-align: center;
        background-color: #000;
        border-radius: 2px;
    }
    
    .popover {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1060;
        display: block;
        max-width: 276px;
        font-family: "Nunito Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5;
        text-align: left;
        text-align: start;
        text-decoration: none;
        text-shadow: none;
        text-transform: none;
        letter-spacing: normal;
        word-break: normal;
        word-spacing: normal;
        white-space: normal;
        line-break: auto;
        font-size: 0.76563rem;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
    }
    
    .popover .arrow {
        position: absolute;
        display: block;
        width: 1rem;
        height: 0.5rem;
        margin: 0 4px;
    }
    
    .popover .arrow::before,
    .popover .arrow::after {
        position: absolute;
        display: block;
        content: "";
        border-color: transparent;
        border-style: solid;
    }
    
    .bs-popover-top,
    .bs-popover-auto[x-placement^="top"] {
        margin-bottom: 0.5rem;
    }
    
    .bs-popover-top .arrow,
    .bs-popover-auto[x-placement^="top"] .arrow {
        bottom: calc((0.5rem + 1px) * -1);
    }
    
    .bs-popover-top .arrow::before,
    .bs-popover-auto[x-placement^="top"] .arrow::before,
    .bs-popover-top .arrow::after,
    .bs-popover-auto[x-placement^="top"] .arrow::after {
        border-width: 0.5rem 0.5rem 0;
    }
    
    .bs-popover-top .arrow::before,
    .bs-popover-auto[x-placement^="top"] .arrow::before {
        bottom: 0;
        border-top-color: rgba(0, 0, 0, 0.25);
    }
    
    .bs-popover-top .arrow::after,
    .bs-popover-auto[x-placement^="top"] .arrow::after {
        bottom: 1px;
        border-top-color: #fff;
    }
    
    .bs-popover-right,
    .bs-popover-auto[x-placement^="right"] {
        margin-left: 0.5rem;
    }
    
    .bs-popover-right .arrow,
    .bs-popover-auto[x-placement^="right"] .arrow {
        left: calc((0.5rem + 1px) * -1);
        width: 0.5rem;
        height: 1rem;
        margin: 4px 0;
    }
    
    .bs-popover-right .arrow::before,
    .bs-popover-auto[x-placement^="right"] .arrow::before,
    .bs-popover-right .arrow::after,
    .bs-popover-auto[x-placement^="right"] .arrow::after {
        border-width: 0.5rem 0.5rem 0.5rem 0;
    }
    
    .bs-popover-right .arrow::before,
    .bs-popover-auto[x-placement^="right"] .arrow::before {
        left: 0;
        border-right-color: rgba(0, 0, 0, 0.25);
    }
    
    .bs-popover-right .arrow::after,
    .bs-popover-auto[x-placement^="right"] .arrow::after {
        left: 1px;
        border-right-color: #fff;
    }
    
    .bs-popover-bottom,
    .bs-popover-auto[x-placement^="bottom"] {
        margin-top: 0.5rem;
    }
    
    .bs-popover-bottom .arrow,
    .bs-popover-auto[x-placement^="bottom"] .arrow {
        top: calc((0.5rem + 1px) * -1);
    }
    
    .bs-popover-bottom .arrow::before,
    .bs-popover-auto[x-placement^="bottom"] .arrow::before,
    .bs-popover-bottom .arrow::after,
    .bs-popover-auto[x-placement^="bottom"] .arrow::after {
        border-width: 0 0.5rem 0.5rem 0.5rem;
    }
    
    .bs-popover-bottom .arrow::before,
    .bs-popover-auto[x-placement^="bottom"] .arrow::before {
        top: 0;
        border-bottom-color: rgba(0, 0, 0, 0.25);
    }
    
    .bs-popover-bottom .arrow::after,
    .bs-popover-auto[x-placement^="bottom"] .arrow::after {
        top: 1px;
        border-bottom-color: #fff;
    }
    
    .bs-popover-bottom .popover-header::before,
    .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
        position: absolute;
        top: 0;
        left: 50%;
        display: block;
        width: 1rem;
        margin-left: -0.5rem;
        content: "";
        border-bottom: 1px solid #f7f7f7;
    }
    
    .bs-popover-left,
    .bs-popover-auto[x-placement^="left"] {
        margin-right: 0.5rem;
    }
    
    .bs-popover-left .arrow,
    .bs-popover-auto[x-placement^="left"] .arrow {
        right: calc((0.5rem + 1px) * -1);
        width: 0.5rem;
        height: 1rem;
        margin: 4px 0;
    }
    
    .bs-popover-left .arrow::before,
    .bs-popover-auto[x-placement^="left"] .arrow::before,
    .bs-popover-left .arrow::after,
    .bs-popover-auto[x-placement^="left"] .arrow::after {
        border-width: 0.5rem 0 0.5rem 0.5rem;
    }
    
    .bs-popover-left .arrow::before,
    .bs-popover-auto[x-placement^="left"] .arrow::before {
        right: 0;
        border-left-color: rgba(0, 0, 0, 0.25);
    }
    
    .bs-popover-left .arrow::after,
    .bs-popover-auto[x-placement^="left"] .arrow::after {
        right: 1px;
        border-left-color: #fff;
    }
    
    .popover-header {
        padding: 0.5rem 0.75rem;
        margin-bottom: 0;
        font-size: 0.875rem;
        color: inherit;
        background-color: #f7f7f7;
        border-bottom: 1px solid #ebebeb;
        border-top-left-radius: calc(4px - 1px);
        border-top-right-radius: calc(4px - 1px);
    }
    
    .popover-header:empty {
        display: none;
    }
    
    .popover-body {
        padding: 0.5rem 0.75rem;
        color: #3e5569;
    }
    
    .carousel {
        position: relative;
    }
    
    .carousel-inner {
        position: relative;
        width: 100%;
        overflow: hidden;
    }
    
    .carousel-item {
        position: relative;
        display: none;
        align-items: center;
        width: 100%;
        backface-visibility: hidden;
        perspective: 1000px;
    }
    
    .carousel-item.active,
    .carousel-item-next,
    .carousel-item-prev {
        display: block;
        transition: transform 0.6s ease;
    }
    
    @media screen and (prefers-reduced-motion: reduce) {
        .carousel-item.active,
        .carousel-item-next,
        .carousel-item-prev {
            transition: none;
        }
    }
    
    .carousel-item-next,
    .carousel-item-prev {
        position: absolute;
        top: 0;
    }
    
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
        transform: translateX(0);
    }
    
    @supports (transform-style: preserve-3d) {
        .carousel-item-next.carousel-item-left,
        .carousel-item-prev.carousel-item-right {
            transform: translate3d(0, 0, 0);
        }
    }
    
    .carousel-item-next,
    .active.carousel-item-right {
        transform: translateX(100%);
    }
    
    @supports (transform-style: preserve-3d) {
        .carousel-item-next,
        .active.carousel-item-right {
            transform: translate3d(100%, 0, 0);
        }
    }
    
    .carousel-item-prev,
    .active.carousel-item-left {
        transform: translateX(-100%);
    }
    
    @supports (transform-style: preserve-3d) {
        .carousel-item-prev,
        .active.carousel-item-left {
            transform: translate3d(-100%, 0, 0);
        }
    }
    
    .carousel-fade .carousel-item {
        opacity: 0;
        transition-duration: .6s;
        transition-property: opacity;
    }
    
    .carousel-fade .carousel-item.active,
    .carousel-fade .carousel-item-next.carousel-item-left,
    .carousel-fade .carousel-item-prev.carousel-item-right {
        opacity: 1;
    }
    
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
        opacity: 0;
    }
    
    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active,
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev {
        transform: translateX(0);
    }
    
    @supports (transform-style: preserve-3d) {
        .carousel-fade .carousel-item-next,
        .carousel-fade .carousel-item-prev,
        .carousel-fade .carousel-item.active,
        .carousel-fade .active.carousel-item-left,
        .carousel-fade .active.carousel-item-prev {
            transform: translate3d(0, 0, 0);
        }
    }
    
    .carousel-control-prev,
    .carousel-control-next {
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15%;
        color: #fff;
        text-align: center;
        opacity: 0.5;
    }
    
    .carousel-control-prev:hover,
    .carousel-control-prev:focus,
    .carousel-control-next:hover,
    .carousel-control-next:focus {
        color: #fff;
        text-decoration: none;
        outline: 0;
        opacity: .9;
    }
    
    .carousel-control-prev {
        left: 0;
    }
    
    .carousel-control-next {
        right: 0;
    }
    
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: transparent no-repeat center center;
        background-size: 100% 100%;
    }
    
    .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
    }
    
    .carousel-control-next-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
    }
    
    .carousel-indicators {
        position: absolute;
        right: 0;
        bottom: 10px;
        left: 0;
        z-index: 15;
        display: flex;
        justify-content: center;
        padding-left: 0;
        margin-right: 15%;
        margin-left: 15%;
        list-style: none;
    }
    
    .carousel-indicators li {
        position: relative;
        flex: 0 1 auto;
        width: 30px;
        height: 3px;
        margin-right: 3px;
        margin-left: 3px;
        text-indent: -999px;
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.5);
    }
    
    .carousel-indicators li::before {
        position: absolute;
        top: -10px;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 10px;
        content: "";
    }
    
    .carousel-indicators li::after {
        position: absolute;
        bottom: -10px;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 10px;
        content: "";
    }
    
    .carousel-indicators .active {
        background-color: #fff;
    }
    
    .carousel-caption {
        position: absolute;
        right: 15%;
        bottom: 20px;
        left: 15%;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #fff;
        text-align: center;
    }
    
    .align-baseline {
        vertical-align: baseline !important;
    }
    
    .align-top {
        vertical-align: top !important;
    }
    
    .align-middle {
        vertical-align: middle !important;
    }
    
    .align-bottom {
        vertical-align: bottom !important;
    }
    
    .align-text-bottom {
        vertical-align: text-bottom !important;
    }
    
    .align-text-top {
        vertical-align: text-top !important;
    }
    
    .bg-primary {
        background-color: #5e72e4 !important;
    }
    
    a.bg-primary:hover,
    a.bg-primary:focus,
    button.bg-primary:hover,
    button.bg-primary:focus {
        background-color: #324cdd !important;
    }
    
    .bg-secondary {
        background-color: #8898aa !important;
    }
    
    a.bg-secondary:hover,
    a.bg-secondary:focus,
    button.bg-secondary:hover,
    button.bg-secondary:focus {
        background-color: #6a7e95 !important;
    }
    
    .bg-success {
        background-color: #2dce89 !important;
    }
    
    a.bg-success:hover,
    a.bg-success:focus,
    button.bg-success:hover,
    button.bg-success:focus {
        background-color: #24a46d !important;
    }
    
    .bg-info {
        background-color: #2c6daa !important;
    }
    
    a.bg-info:hover,
    a.bg-info:focus,
    button.bg-info:hover,
    button.bg-info:focus {
        background-color: #0041f5 !important;
    }
    
    .bg-warning {
        background-color: #fb6340 !important;
    }
    
    a.bg-warning:hover,
    a.bg-warning:focus,
    button.bg-warning:hover,
    button.bg-warning:focus {
        background-color: #fa3a0e !important;
    }
    
    .bg-danger {
        background-color: #f62d51 !important;
    }
    
    a.bg-danger:hover,
    a.bg-danger:focus,
    button.bg-danger:hover,
    button.bg-danger:focus {
        background-color: #e60a31 !important;
    }
    
    .bg-light {
        background-color: #f6f9fc !important;
    }
    
    a.bg-light:hover,
    a.bg-light:focus,
    button.bg-light:hover,
    button.bg-light:focus {
        background-color: #d0e0ef !important;
    }
    
    .bg-dark {
        background-color: #32325d !important;
    }
    
    a.bg-dark:hover,
    a.bg-dark:focus,
    button.bg-dark:hover,
    button.bg-dark:focus {
        background-color: #20203c !important;
    }
    
    .bg-cyan {
        background-color: #4fc3f7 !important;
    }
    
    a.bg-cyan:hover,
    a.bg-cyan:focus,
    button.bg-cyan:hover,
    button.bg-cyan:focus {
        background-color: #1eb2f5 !important;
    }
    
    .bg-orange {
        background-color: #fb6340 !important;
    }
    
    a.bg-orange:hover,
    a.bg-orange:focus,
    button.bg-orange:hover,
    button.bg-orange:focus {
        background-color: #fa3a0e !important;
    }
    
    .bg-purple {
        background-color: #5e72e4 !important;
    }
    
    a.bg-purple:hover,
    a.bg-purple:focus,
    button.bg-purple:hover,
    button.bg-purple:focus {
        background-color: #324cdd !important;
    }
    
    .bg-inverse {
        background-color: #32325d !important;
    }
    
    a.bg-inverse:hover,
    a.bg-inverse:focus,
    button.bg-inverse:hover,
    button.bg-inverse:focus {
        background-color: #20203c !important;
    }
    
    .bg-white {
        background-color: #fff !important;
    }
    
    .bg-transparent {
        background-color: transparent !important;
    }
    
    .border {
        border: 1px solid #e9ecef !important;
    }
    
    .border-top {
        border-top: 1px solid #e9ecef !important;
    }
    
    .border-right {
        border-right: 1px solid #e9ecef !important;
    }
    
    .border-bottom {
        border-bottom: 1px solid #e9ecef !important;
    }
    
    .border-left {
        border-left: 1px solid #e9ecef !important;
    }
    
    .border-0 {
        border: 0 !important;
    }
    
    .border-top-0 {
        border-top: 0 !important;
    }
    
    .border-right-0 {
        border-right: 0 !important;
    }
    
    .border-bottom-0 {
        border-bottom: 0 !important;
    }
    
    .border-left-0 {
        border-left: 0 !important;
    }
    
    .border-primary {
        border-color: #5e72e4 !important;
    }
    
    .border-secondary {
        border-color: #8898aa !important;
    }
    
    .border-success {
        border-color: #2dce89 !important;
    }
    
    .border-info {
        border-color: #2c6daa !important;
    }
    
    .border-warning {
        border-color: #fb6340 !important;
    }
    
    .border-danger {
        border-color: #f62d51 !important;
    }
    
    .border-light {
        border-color: #f6f9fc !important;
    }
    
    .border-dark {
        border-color: #32325d !important;
    }
    
    .border-cyan {
        border-color: #4fc3f7 !important;
    }
    
    .border-orange {
        border-color: #fb6340 !important;
    }
    
    .border-purple {
        border-color: #5e72e4 !important;
    }
    
    .border-inverse {
        border-color: #32325d !important;
    }
    
    .border-white {
        border-color: #fff !important;
    }
    
    .rounded {
        border-radius: 2px !important;
    }
    
    .rounded-top {
        border-top-left-radius: 2px !important;
        border-top-right-radius: 2px !important;
    }
    
    .rounded-right {
        border-top-right-radius: 2px !important;
        border-bottom-right-radius: 2px !important;
    }
    
    .rounded-bottom {
        border-bottom-right-radius: 2px !important;
        border-bottom-left-radius: 2px !important;
    }
    
    .rounded-left {
        border-top-left-radius: 2px !important;
        border-bottom-left-radius: 2px !important;
    }
    
    .rounded-circle {
        border-radius: 50% !important;
    }
    
    .rounded-0 {
        border-radius: 0 !important;
    }
    
    .clearfix::after {
        display: block;
        clear: both;
        content: "";
    }
    
    .d-none {
        display: none !important;
    }
    
    .d-inline {
        display: inline !important;
    }
    
    .d-inline-block {
        display: inline-block !important;
    }
    
    .d-block {
        display: block !important;
    }
    
    .d-table {
        display: table !important;
    }
    
    .d-table-row {
        display: table-row !important;
    }
    
    .d-table-cell {
        display: table-cell !important;
    }
    
    .d-flex {
        display: flex !important;
    }
    
    .d-inline-flex {
        display: inline-flex !important;
    }
    
    @media (min-width: 576px) {
        .d-sm-none {
            display: none !important;
        }
        .d-sm-inline {
            display: inline !important;
        }
        .d-sm-inline-block {
            display: inline-block !important;
        }
        .d-sm-block {
            display: block !important;
        }
        .d-sm-table {
            display: table !important;
        }
        .d-sm-table-row {
            display: table-row !important;
        }
        .d-sm-table-cell {
            display: table-cell !important;
        }
        .d-sm-flex {
            display: flex !important;
        }
        .d-sm-inline-flex {
            display: inline-flex !important;
        }
    }
    
    @media (min-width: 800px) {
        .d-md-none {
            /*display: none !important;*/
        }
        .d-md-inline {
            display: inline !important;
        }
        .d-md-inline-block {
            display: inline-block !important;
        }
        .d-md-block {
            display: block !important;
        }
        .d-md-table {
            display: table !important;
        }
        .d-md-table-row {
            display: table-row !important;
        }
        .d-md-table-cell {
            display: table-cell !important;
        }
        .d-md-flex {
            display: flex !important;
        }
        .d-md-inline-flex {
            display: inline-flex !important;
        }
    }
    
    @media (min-width: 992px) {
        .d-lg-none {
            display: none !important;
        }
        .d-lg-inline {
            display: inline !important;
        }
        .d-lg-inline-block {
            display: inline-block !important;
        }
        .d-lg-block {
            display: block !important;
        }
        .d-lg-table {
            display: table !important;
        }
        .d-lg-table-row {
            display: table-row !important;
        }
        .d-lg-table-cell {
            display: table-cell !important;
        }
        .d-lg-flex {
            display: flex !important;
        }
        .d-lg-inline-flex {
            display: inline-flex !important;
        }
    }
    
    @media (min-width: 1200px) {
        .d-xl-none {
            display: none !important;
        }
        .d-xl-inline {
            display: inline !important;
        }
        .d-xl-inline-block {
            display: inline-block !important;
        }
        .d-xl-block {
            display: block !important;
        }
        .d-xl-table {
            display: table !important;
        }
        .d-xl-table-row {
            display: table-row !important;
        }
        .d-xl-table-cell {
            display: table-cell !important;
        }
        .d-xl-flex {
            display: flex !important;
        }
        .d-xl-inline-flex {
            display: inline-flex !important;
        }
    }
    
    @media (min-width: 1600px) {
        .d-xlg-none {
            display: none !important;
        }
        .d-xlg-inline {
            display: inline !important;
        }
        .d-xlg-inline-block {
            display: inline-block !important;
        }
        .d-xlg-block {
            display: block !important;
        }
        .d-xlg-table {
            display: table !important;
        }
        .d-xlg-table-row {
            display: table-row !important;
        }
        .d-xlg-table-cell {
            display: table-cell !important;
        }
        .d-xlg-flex {
            display: flex !important;
        }
        .d-xlg-inline-flex {
            display: inline-flex !important;
        }
    }
    
    @media print {
        .d-print-none {
            display: none !important;
        }
        .d-print-inline {
            display: inline !important;
        }
        .d-print-inline-block {
            display: inline-block !important;
        }
        .d-print-block {
            display: block !important;
        }
        .d-print-table {
            display: table !important;
        }
        .d-print-table-row {
            display: table-row !important;
        }
        .d-print-table-cell {
            display: table-cell !important;
        }
        .d-print-flex {
            display: flex !important;
        }
        .d-print-inline-flex {
            display: inline-flex !important;
        }
    }
    
    .embed-responsive {
        position: relative;
        display: block;
        width: 100%;
        padding: 0;
        overflow: hidden;
    }
    
    .embed-responsive::before {
        display: block;
        content: "";
    }
    
    .embed-responsive .embed-responsive-item,
    .embed-responsive iframe,
    .embed-responsive embed,
    .embed-responsive object,
    .embed-responsive video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
    
    .embed-responsive-21by9::before {
        padding-top: 42.85714%;
    }
    
    .embed-responsive-16by9::before {
        padding-top: 56.25%;
    }
    
    .embed-responsive-4by3::before {
        padding-top: 75%;
    }
    
    .embed-responsive-1by1::before {
        padding-top: 100%;
    }
    
    .flex-row {
        flex-direction: row !important;
    }
    
    .flex-column {
        flex-direction: column !important;
    }
    
    .flex-row-reverse {
        flex-direction: row-reverse !important;
    }
    
    .flex-column-reverse {
        flex-direction: column-reverse !important;
    }
    
    .flex-wrap {
        flex-wrap: wrap !important;
    }
    
    .flex-nowrap {
        flex-wrap: nowrap !important;
    }
    
    .flex-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    
    .flex-fill {
        flex: 1 1 auto !important;
    }
    
    .flex-grow-0 {
        flex-grow: 0 !important;
    }
    
    .flex-grow-1 {
        flex-grow: 1 !important;
    }
    
    .flex-shrink-0 {
        flex-shrink: 0 !important;
    }
    
    .flex-shrink-1 {
        flex-shrink: 1 !important;
    }
    
    .justify-content-start {
        justify-content: flex-start !important;
    }
    
    .justify-content-end {
        justify-content: flex-end !important;
    }
    
    .justify-content-center {
        justify-content: center !important;
    }
    
    .justify-content-between {
        justify-content: space-between !important;
    }
    
    .justify-content-around {
        justify-content: space-around !important;
    }
    
    .align-items-start {
        align-items: flex-start !important;
    }
    
    .align-items-end {
        align-items: flex-end !important;
    }
    
    .align-items-center {
        align-items: center !important;
    }
    
    .align-items-baseline {
        align-items: baseline !important;
    }
    
    .align-items-stretch {
        align-items: stretch !important;
    }
    
    .align-content-start {
        align-content: flex-start !important;
    }
    
    .align-content-end {
        align-content: flex-end !important;
    }
    
    .align-content-center {
        align-content: center !important;
    }
    
    .align-content-between {
        align-content: space-between !important;
    }
    
    .align-content-around {
        align-content: space-around !important;
    }
    
    .align-content-stretch {
        align-content: stretch !important;
    }
    
    .align-self-auto {
        align-self: auto !important;
    }
    
    .align-self-start {
        align-self: flex-start !important;
    }
    
    .align-self-end {
        align-self: flex-end !important;
    }
    
    .align-self-center {
        align-self: center !important;
    }
    
    .align-self-baseline {
        align-self: baseline !important;
    }
    
    .align-self-stretch {
        align-self: stretch !important;
    }
    
    @media (min-width: 576px) {
        .flex-sm-row {
            flex-direction: row !important;
        }
        .flex-sm-column {
            flex-direction: column !important;
        }
        .flex-sm-row-reverse {
            flex-direction: row-reverse !important;
        }
        .flex-sm-column-reverse {
            flex-direction: column-reverse !important;
        }
        .flex-sm-wrap {
            flex-wrap: wrap !important;
        }
        .flex-sm-nowrap {
            flex-wrap: nowrap !important;
        }
        .flex-sm-wrap-reverse {
            flex-wrap: wrap-reverse !important;
        }
        .flex-sm-fill {
            flex: 1 1 auto !important;
        }
        .flex-sm-grow-0 {
            flex-grow: 0 !important;
        }
        .flex-sm-grow-1 {
            flex-grow: 1 !important;
        }
        .flex-sm-shrink-0 {
            flex-shrink: 0 !important;
        }
        .flex-sm-shrink-1 {
            flex-shrink: 1 !important;
        }
        .justify-content-sm-start {
            justify-content: flex-start !important;
        }
        .justify-content-sm-end {
            justify-content: flex-end !important;
        }
        .justify-content-sm-center {
            justify-content: center !important;
        }
        .justify-content-sm-between {
            justify-content: space-between !important;
        }
        .justify-content-sm-around {
            justify-content: space-around !important;
        }
        .align-items-sm-start {
            align-items: flex-start !important;
        }
        .align-items-sm-end {
            align-items: flex-end !important;
        }
        .align-items-sm-center {
            align-items: center !important;
        }
        .align-items-sm-baseline {
            align-items: baseline !important;
        }
        .align-items-sm-stretch {
            align-items: stretch !important;
        }
        .align-content-sm-start {
            align-content: flex-start !important;
        }
        .align-content-sm-end {
            align-content: flex-end !important;
        }
        .align-content-sm-center {
            align-content: center !important;
        }
        .align-content-sm-between {
            align-content: space-between !important;
        }
        .align-content-sm-around {
            align-content: space-around !important;
        }
        .align-content-sm-stretch {
            align-content: stretch !important;
        }
        .align-self-sm-auto {
            align-self: auto !important;
        }
        .align-self-sm-start {
            align-self: flex-start !important;
        }
        .align-self-sm-end {
            align-self: flex-end !important;
        }
        .align-self-sm-center {
            align-self: center !important;
        }
        .align-self-sm-baseline {
            align-self: baseline !important;
        }
        .align-self-sm-stretch {
            align-self: stretch !important;
        }
    }
    
    @media (min-width: 800px) {
        .flex-md-row {
            flex-direction: row !important;
        }
        .flex-md-column {
            flex-direction: column !important;
        }
        .flex-md-row-reverse {
            flex-direction: row-reverse !important;
        }
        .flex-md-column-reverse {
            flex-direction: column-reverse !important;
        }
        .flex-md-wrap {
            flex-wrap: wrap !important;
        }
        .flex-md-nowrap {
            flex-wrap: nowrap !important;
        }
        .flex-md-wrap-reverse {
            flex-wrap: wrap-reverse !important;
        }
        .flex-md-fill {
            flex: 1 1 auto !important;
        }
        .flex-md-grow-0 {
            flex-grow: 0 !important;
        }
        .flex-md-grow-1 {
            flex-grow: 1 !important;
        }
        .flex-md-shrink-0 {
            flex-shrink: 0 !important;
        }
        .flex-md-shrink-1 {
            flex-shrink: 1 !important;
        }
        .justify-content-md-start {
            justify-content: flex-start !important;
        }
        .justify-content-md-end {
            justify-content: flex-end !important;
        }
        .justify-content-md-center {
            justify-content: center !important;
        }
        .justify-content-md-between {
            justify-content: space-between !important;
        }
        .justify-content-md-around {
            justify-content: space-around !important;
        }
        .align-items-md-start {
            align-items: flex-start !important;
        }
        .align-items-md-end {
            align-items: flex-end !important;
        }
        .align-items-md-center {
            align-items: center !important;
        }
        .align-items-md-baseline {
            align-items: baseline !important;
        }
        .align-items-md-stretch {
            align-items: stretch !important;
        }
        .align-content-md-start {
            align-content: flex-start !important;
        }
        .align-content-md-end {
            align-content: flex-end !important;
        }
        .align-content-md-center {
            align-content: center !important;
        }
        .align-content-md-between {
            align-content: space-between !important;
        }
        .align-content-md-around {
            align-content: space-around !important;
        }
        .align-content-md-stretch {
            align-content: stretch !important;
        }
        .align-self-md-auto {
            align-self: auto !important;
        }
        .align-self-md-start {
            align-self: flex-start !important;
        }
        .align-self-md-end {
            align-self: flex-end !important;
        }
        .align-self-md-center {
            align-self: center !important;
        }
        .align-self-md-baseline {
            align-self: baseline !important;
        }
        .align-self-md-stretch {
            align-self: stretch !important;
        }
    }
    
    @media (min-width: 992px) {
        .flex-lg-row {
            flex-direction: row !important;
        }
        .flex-lg-column {
            flex-direction: column !important;
        }
        .flex-lg-row-reverse {
            flex-direction: row-reverse !important;
        }
        .flex-lg-column-reverse {
            flex-direction: column-reverse !important;
        }
        .flex-lg-wrap {
            flex-wrap: wrap !important;
        }
        .flex-lg-nowrap {
            flex-wrap: nowrap !important;
        }
        .flex-lg-wrap-reverse {
            flex-wrap: wrap-reverse !important;
        }
        .flex-lg-fill {
            flex: 1 1 auto !important;
        }
        .flex-lg-grow-0 {
            flex-grow: 0 !important;
        }
        .flex-lg-grow-1 {
            flex-grow: 1 !important;
        }
        .flex-lg-shrink-0 {
            flex-shrink: 0 !important;
        }
        .flex-lg-shrink-1 {
            flex-shrink: 1 !important;
        }
        .justify-content-lg-start {
            justify-content: flex-start !important;
        }
        .justify-content-lg-end {
            justify-content: flex-end !important;
        }
        .justify-content-lg-center {
            justify-content: center !important;
        }
        .justify-content-lg-between {
            justify-content: space-between !important;
        }
        .justify-content-lg-around {
            justify-content: space-around !important;
        }
        .align-items-lg-start {
            align-items: flex-start !important;
        }
        .align-items-lg-end {
            align-items: flex-end !important;
        }
        .align-items-lg-center {
            align-items: center !important;
        }
        .align-items-lg-baseline {
            align-items: baseline !important;
        }
        .align-items-lg-stretch {
            align-items: stretch !important;
        }
        .align-content-lg-start {
            align-content: flex-start !important;
        }
        .align-content-lg-end {
            align-content: flex-end !important;
        }
        .align-content-lg-center {
            align-content: center !important;
        }
        .align-content-lg-between {
            align-content: space-between !important;
        }
        .align-content-lg-around {
            align-content: space-around !important;
        }
        .align-content-lg-stretch {
            align-content: stretch !important;
        }
        .align-self-lg-auto {
            align-self: auto !important;
        }
        .align-self-lg-start {
            align-self: flex-start !important;
        }
        .align-self-lg-end {
            align-self: flex-end !important;
        }
        .align-self-lg-center {
            align-self: center !important;
        }
        .align-self-lg-baseline {
            align-self: baseline !important;
        }
        .align-self-lg-stretch {
            align-self: stretch !important;
        }
    }
    
    @media (min-width: 1200px) {
        .flex-xl-row {
            flex-direction: row !important;
        }
        .flex-xl-column {
            flex-direction: column !important;
        }
        .flex-xl-row-reverse {
            flex-direction: row-reverse !important;
        }
        .flex-xl-column-reverse {
            flex-direction: column-reverse !important;
        }
        .flex-xl-wrap {
            flex-wrap: wrap !important;
        }
        .flex-xl-nowrap {
            flex-wrap: nowrap !important;
        }
        .flex-xl-wrap-reverse {
            flex-wrap: wrap-reverse !important;
        }
        .flex-xl-fill {
            flex: 1 1 auto !important;
        }
        .flex-xl-grow-0 {
            flex-grow: 0 !important;
        }
        .flex-xl-grow-1 {
            flex-grow: 1 !important;
        }
        .flex-xl-shrink-0 {
            flex-shrink: 0 !important;
        }
        .flex-xl-shrink-1 {
            flex-shrink: 1 !important;
        }
        .justify-content-xl-start {
            justify-content: flex-start !important;
        }
        .justify-content-xl-end {
            justify-content: flex-end !important;
        }
        .justify-content-xl-center {
            justify-content: center !important;
        }
        .justify-content-xl-between {
            justify-content: space-between !important;
        }
        .justify-content-xl-around {
            justify-content: space-around !important;
        }
        .align-items-xl-start {
            align-items: flex-start !important;
        }
        .align-items-xl-end {
            align-items: flex-end !important;
        }
        .align-items-xl-center {
            align-items: center !important;
        }
        .align-items-xl-baseline {
            align-items: baseline !important;
        }
        .align-items-xl-stretch {
            align-items: stretch !important;
        }
        .align-content-xl-start {
            align-content: flex-start !important;
        }
        .align-content-xl-end {
            align-content: flex-end !important;
        }
        .align-content-xl-center {
            align-content: center !important;
        }
        .align-content-xl-between {
            align-content: space-between !important;
        }
        .align-content-xl-around {
            align-content: space-around !important;
        }
        .align-content-xl-stretch {
            align-content: stretch !important;
        }
        .align-self-xl-auto {
            align-self: auto !important;
        }
        .align-self-xl-start {
            align-self: flex-start !important;
        }
        .align-self-xl-end {
            align-self: flex-end !important;
        }
        .align-self-xl-center {
            align-self: center !important;
        }
        .align-self-xl-baseline {
            align-self: baseline !important;
        }
        .align-self-xl-stretch {
            align-self: stretch !important;
        }
    }
    
    @media (min-width: 1600px) {
        .flex-xlg-row {
            flex-direction: row !important;
        }
        .flex-xlg-column {
            flex-direction: column !important;
        }
        .flex-xlg-row-reverse {
            flex-direction: row-reverse !important;
        }
        .flex-xlg-column-reverse {
            flex-direction: column-reverse !important;
        }
        .flex-xlg-wrap {
            flex-wrap: wrap !important;
        }
        .flex-xlg-nowrap {
            flex-wrap: nowrap !important;
        }
        .flex-xlg-wrap-reverse {
            flex-wrap: wrap-reverse !important;
        }
        .flex-xlg-fill {
            flex: 1 1 auto !important;
        }
        .flex-xlg-grow-0 {
            flex-grow: 0 !important;
        }
        .flex-xlg-grow-1 {
            flex-grow: 1 !important;
        }
        .flex-xlg-shrink-0 {
            flex-shrink: 0 !important;
        }
        .flex-xlg-shrink-1 {
            flex-shrink: 1 !important;
        }
        .justify-content-xlg-start {
            justify-content: flex-start !important;
        }
        .justify-content-xlg-end {
            justify-content: flex-end !important;
        }
        .justify-content-xlg-center {
            justify-content: center !important;
        }
        .justify-content-xlg-between {
            justify-content: space-between !important;
        }
        .justify-content-xlg-around {
            justify-content: space-around !important;
        }
        .align-items-xlg-start {
            align-items: flex-start !important;
        }
        .align-items-xlg-end {
            align-items: flex-end !important;
        }
        .align-items-xlg-center {
            align-items: center !important;
        }
        .align-items-xlg-baseline {
            align-items: baseline !important;
        }
        .align-items-xlg-stretch {
            align-items: stretch !important;
        }
        .align-content-xlg-start {
            align-content: flex-start !important;
        }
        .align-content-xlg-end {
            align-content: flex-end !important;
        }
        .align-content-xlg-center {
            align-content: center !important;
        }
        .align-content-xlg-between {
            align-content: space-between !important;
        }
        .align-content-xlg-around {
            align-content: space-around !important;
        }
        .align-content-xlg-stretch {
            align-content: stretch !important;
        }
        .align-self-xlg-auto {
            align-self: auto !important;
        }
        .align-self-xlg-start {
            align-self: flex-start !important;
        }
        .align-self-xlg-end {
            align-self: flex-end !important;
        }
        .align-self-xlg-center {
            align-self: center !important;
        }
        .align-self-xlg-baseline {
            align-self: baseline !important;
        }
        .align-self-xlg-stretch {
            align-self: stretch !important;
        }
    }
    
    .float-left {
        float: left !important;
    }
    
    .float-right {
        float: right !important;
    }
    
    .float-none {
        float: none !important;
    }
    
    @media (min-width: 576px) {
        .float-sm-left {
            float: left !important;
        }
        .float-sm-right {
            float: right !important;
        }
        .float-sm-none {
            float: none !important;
        }
    }
    
    @media (min-width: 800px) {
        .float-md-left {
            float: left !important;
        }
        .float-md-right {
            float: right !important;
        }
        .float-md-none {
            float: none !important;
        }
    }
    
    @media (min-width: 992px) {
        .float-lg-left {
            float: left !important;
        }
        .float-lg-right {
            float: right !important;
        }
        .float-lg-none {
            float: none !important;
        }
    }
    
    @media (min-width: 1200px) {
        .float-xl-left {
            float: left !important;
        }
        .float-xl-right {
            float: right !important;
        }
        .float-xl-none {
            float: none !important;
        }
    }
    
    @media (min-width: 1600px) {
        .float-xlg-left {
            float: left !important;
        }
        .float-xlg-right {
            float: right !important;
        }
        .float-xlg-none {
            float: none !important;
        }
    }
    
    .position-static {
        position: static !important;
    }
    
    .position-relative {
        position: relative !important;
    }
    
    .position-absolute {
        position: absolute !important;
    }
    
    .position-fixed {
        position: fixed !important;
    }
    
    .position-sticky {
        position: sticky !important;
    }
    
    .fixed-top {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1030;
    }
    
    .fixed-bottom {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1030;
    }
    
    @supports (position: sticky) {
        .sticky-top {
            position: sticky;
            top: 0;
            z-index: 1020;
        }
    }
    
    .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
    }
    
    .sr-only-focusable:active,
    .sr-only-focusable:focus {
        position: static;
        width: auto;
        height: auto;
        overflow: visible;
        clip: auto;
        white-space: normal;
    }
    
    .shadow-sm {
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    }
    
    .shadow {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.08) !important;
    }
    
    .shadow-lg {
        box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
    }
    
    .shadow-none {
        box-shadow: none !important;
    }
    
    .w-25 {
        width: 25% !important;
    }
    
    .w-50 {
        width: 50% !important;
    }
    
    .w-75 {
        width: 75% !important;
    }
    
    .w-100 {
        width: 100% !important;
    }
    
    .w-auto {
        width: auto !important;
    }
    
    .h-25 {
        height: 25% !important;
    }
    
    .h-50 {
        height: 50% !important;
    }
    
    .h-75 {
        height: 75% !important;
    }
    
    .h-100 {
        height: 100% !important;
    }
    
    .h-auto {
        height: auto !important;
    }
    
    .mw-100 {
        max-width: 100% !important;
    }
    
    .mh-100 {
        max-height: 100% !important;
    }
    
    .m-0 {
        margin: 0 !important;
    }
    
    .mt-0,
    .my-0 {
        margin-top: 0 !important;
    }
    
    .mr-0,
    .mx-0 {
        margin-right: 0 !important;
    }
    
    .mb-0,
    .my-0 {
        margin-bottom: 0 !important;
    }
    
    .ml-0,
    .mx-0 {
        margin-left: 0 !important;
    }
    
    .m-1 {
        margin: 0.25rem !important;
    }
    
    .mt-1,
    .my-1 {
        margin-top: 0.25rem !important;
    }
    
    .mr-1,
    .mx-1 {
        margin-right: 0.25rem !important;
    }
    
    .mb-1,
    .my-1 {
        margin-bottom: 0.25rem !important;
    }
    
    .ml-1,
    .mx-1 {
        margin-left: 0.25rem !important;
    }
    
    .m-2 {
        margin: 0.5rem !important;
    }
    
    .mt-2,
    .my-2 {
        margin-top: 0.5rem !important;
    }
    
    .mr-2,
    .mx-2 {
        margin-right: 0.5rem !important;
    }
    
    .mb-2,
    .my-2 {
        margin-bottom: 0.5rem !important;
    }
    
    .ml-2,
    .mx-2 {
        margin-left: 0.5rem !important;
    }
    
    .m-3 {
        margin: 1rem !important;
    }
    
    .mt-3,
    .my-3 {
        margin-top: 1rem !important;
    }
    
    .mr-3,
    .mx-3 {
        margin-right: 1rem !important;
    }
    
    .mb-3,
    .my-3 {
        margin-bottom: 1rem !important;
    }
    
    .ml-3,
    .mx-3 {
        margin-left: 1rem !important;
    }
    
    .m-4 {
        margin: 1.5rem !important;
    }
    
    .mt-4,
    .my-4 {
        margin-top: 1.5rem !important;
    }
    
    .mr-4,
    .mx-4 {
        margin-right: 1.5rem !important;
    }
    
    .mb-4,
    .my-4 {
        margin-bottom: 1.5rem !important;
    }
    
    .ml-4,
    .mx-4 {
        margin-left: 1.5rem !important;
    }
    
    .m-5 {
        margin: 3rem !important;
    }
    
    .mt-5,
    .my-5 {
        margin-top: 3rem !important;
    }
    
    .mr-5,
    .mx-5 {
        margin-right: 3rem !important;
    }
    
    .mb-5,
    .my-5 {
        margin-bottom: 3rem !important;
    }
    
    .ml-5,
    .mx-5 {
        margin-left: 3rem !important;
    }
    
    .p-0 {
        padding: 0 !important;
    }
    
    .pt-0,
    .py-0 {
        padding-top: 0 !important;
    }
    
    .pr-0,
    .px-0 {
        padding-right: 0 !important;
    }
    
    .pb-0,
    .py-0 {
        padding-bottom: 0 !important;
    }
    
    .pl-0,
    .px-0 {
        padding-left: 0 !important;
    }
    
    .p-1 {
        padding: 0.25rem !important;
    }
    
    .pt-1,
    .py-1 {
        padding-top: 0.25rem !important;
    }
    
    .pr-1,
    .px-1 {
        padding-right: 0.25rem !important;
    }
    
    .pb-1,
    .py-1 {
        padding-bottom: 0.25rem !important;
    }
    
    .pl-1,
    .px-1 {
        padding-left: 0.25rem !important;
    }
    
    .p-2 {
        padding: 0.5rem !important;
    }
    
    .pt-2,
    .py-2 {
        padding-top: 0.5rem !important;
    }
    
    .pr-2,
    .px-2 {
        padding-right: 0.5rem !important;
    }
    
    .pb-2,
    .py-2 {
        padding-bottom: 0.5rem !important;
    }
    
    .pl-2,
    .px-2 {
        padding-left: 0.5rem !important;
    }
    
    .p-3 {
        padding: 1rem !important;
    }
    
    .pt-3,
    .py-3 {
        padding-top: 1rem !important;
    }
    
    .pr-3,
    .px-3 {
        padding-right: 1rem !important;
    }
    
    .pb-3,
    .py-3 {
        padding-bottom: 1rem !important;
    }
    
    .pl-3,
    .px-3 {
        padding-left: 1rem !important;
    }
    
    .p-4 {
        padding: 1.5rem !important;
    }
    
    .pt-4,
    .py-4 {
        padding-top: 1.5rem !important;
    }
    
    .pr-4,
    .px-4 {
        padding-right: 1.5rem !important;
    }
    
    .pb-4,
    .py-4 {
        padding-bottom: 1.5rem !important;
    }
    
    .pl-4,
    .px-4 {
        padding-left: 1.5rem !important;
    }
    
    .p-5 {
        padding: 3rem !important;
    }
    
    .pt-5,
    .py-5 {
        padding-top: 3rem !important;
    }
    
    .pr-5,
    .px-5 {
        padding-right: 3rem !important;
    }
    
    .pb-5,
    .py-5 {
        padding-bottom: 3rem !important;
    }
    
    .pl-5,
    .px-5 {
        padding-left: 3rem !important;
    }
    
    .m-auto {
        margin: auto !important;
    }
    
    .mt-auto,
    .my-auto {
        margin-top: auto !important;
    }
    
    .mr-auto,
    .mx-auto {
        margin-right: auto !important;
    }
    
    .mb-auto,
    .my-auto {
        margin-bottom: auto !important;
    }
    
    .ml-auto,
    .mx-auto {
        margin-left: auto !important;
    }
    
    @media (min-width: 576px) {
        .m-sm-0 {
            margin: 0 !important;
        }
        .mt-sm-0,
        .my-sm-0 {
            margin-top: 0 !important;
        }
        .mr-sm-0,
        .mx-sm-0 {
            margin-right: 0 !important;
        }
        .mb-sm-0,
        .my-sm-0 {
            margin-bottom: 0 !important;
        }
        .ml-sm-0,
        .mx-sm-0 {
            margin-left: 0 !important;
        }
        .m-sm-1 {
            margin: 0.25rem !important;
        }
        .mt-sm-1,
        .my-sm-1 {
            margin-top: 0.25rem !important;
        }
        .mr-sm-1,
        .mx-sm-1 {
            margin-right: 0.25rem !important;
        }
        .mb-sm-1,
        .my-sm-1 {
            margin-bottom: 0.25rem !important;
        }
        .ml-sm-1,
        .mx-sm-1 {
            margin-left: 0.25rem !important;
        }
        .m-sm-2 {
            margin: 0.5rem !important;
        }
        .mt-sm-2,
        .my-sm-2 {
            margin-top: 0.5rem !important;
        }
        .mr-sm-2,
        .mx-sm-2 {
            margin-right: 0.5rem !important;
        }
        .mb-sm-2,
        .my-sm-2 {
            margin-bottom: 0.5rem !important;
        }
        .ml-sm-2,
        .mx-sm-2 {
            margin-left: 0.5rem !important;
        }
        .m-sm-3 {
            margin: 1rem !important;
        }
        .mt-sm-3,
        .my-sm-3 {
            margin-top: 1rem !important;
        }
        .mr-sm-3,
        .mx-sm-3 {
            margin-right: 1rem !important;
        }
        .mb-sm-3,
        .my-sm-3 {
            margin-bottom: 1rem !important;
        }
        .ml-sm-3,
        .mx-sm-3 {
            margin-left: 1rem !important;
        }
        .m-sm-4 {
            margin: 1.5rem !important;
        }
        .mt-sm-4,
        .my-sm-4 {
            margin-top: 1.5rem !important;
        }
        .mr-sm-4,
        .mx-sm-4 {
            margin-right: 1.5rem !important;
        }
        .mb-sm-4,
        .my-sm-4 {
            margin-bottom: 1.5rem !important;
        }
        .ml-sm-4,
        .mx-sm-4 {
            margin-left: 1.5rem !important;
        }
        .m-sm-5 {
            margin: 3rem !important;
        }
        .mt-sm-5,
        .my-sm-5 {
            margin-top: 3rem !important;
        }
        .mr-sm-5,
        .mx-sm-5 {
            margin-right: 3rem !important;
        }
        .mb-sm-5,
        .my-sm-5 {
            margin-bottom: 3rem !important;
        }
        .ml-sm-5,
        .mx-sm-5 {
            margin-left: 3rem !important;
        }
        .p-sm-0 {
            padding: 0 !important;
        }
        .pt-sm-0,
        .py-sm-0 {
            padding-top: 0 !important;
        }
        .pr-sm-0,
        .px-sm-0 {
            padding-right: 0 !important;
        }
        .pb-sm-0,
        .py-sm-0 {
            padding-bottom: 0 !important;
        }
        .pl-sm-0,
        .px-sm-0 {
            padding-left: 0 !important;
        }
        .p-sm-1 {
            padding: 0.25rem !important;
        }
        .pt-sm-1,
        .py-sm-1 {
            padding-top: 0.25rem !important;
        }
        .pr-sm-1,
        .px-sm-1 {
            padding-right: 0.25rem !important;
        }
        .pb-sm-1,
        .py-sm-1 {
            padding-bottom: 0.25rem !important;
        }
        .pl-sm-1,
        .px-sm-1 {
            padding-left: 0.25rem !important;
        }
        .p-sm-2 {
            padding: 0.5rem !important;
        }
        .pt-sm-2,
        .py-sm-2 {
            padding-top: 0.5rem !important;
        }
        .pr-sm-2,
        .px-sm-2 {
            padding-right: 0.5rem !important;
        }
        .pb-sm-2,
        .py-sm-2 {
            padding-bottom: 0.5rem !important;
        }
        .pl-sm-2,
        .px-sm-2 {
            padding-left: 0.5rem !important;
        }
        .p-sm-3 {
            padding: 1rem !important;
        }
        .pt-sm-3,
        .py-sm-3 {
            padding-top: 1rem !important;
        }
        .pr-sm-3,
        .px-sm-3 {
            padding-right: 1rem !important;
        }
        .pb-sm-3,
        .py-sm-3 {
            padding-bottom: 1rem !important;
        }
        .pl-sm-3,
        .px-sm-3 {
            padding-left: 1rem !important;
        }
        .p-sm-4 {
            padding: 1.5rem !important;
        }
        .pt-sm-4,
        .py-sm-4 {
            padding-top: 1.5rem !important;
        }
        .pr-sm-4,
        .px-sm-4 {
            padding-right: 1.5rem !important;
        }
        .pb-sm-4,
        .py-sm-4 {
            padding-bottom: 1.5rem !important;
        }
        .pl-sm-4,
        .px-sm-4 {
            padding-left: 1.5rem !important;
        }
        .p-sm-5 {
            padding: 3rem !important;
        }
        .pt-sm-5,
        .py-sm-5 {
            padding-top: 3rem !important;
        }
        .pr-sm-5,
        .px-sm-5 {
            padding-right: 3rem !important;
        }
        .pb-sm-5,
        .py-sm-5 {
            padding-bottom: 3rem !important;
        }
        .pl-sm-5,
        .px-sm-5 {
            padding-left: 3rem !important;
        }
        .m-sm-auto {
            margin: auto !important;
        }
        .mt-sm-auto,
        .my-sm-auto {
            margin-top: auto !important;
        }
        .mr-sm-auto,
        .mx-sm-auto {
            margin-right: auto !important;
        }
        .mb-sm-auto,
        .my-sm-auto {
            margin-bottom: auto !important;
        }
        .ml-sm-auto,
        .mx-sm-auto {
            margin-left: auto !important;
        }
    }
    
    @media (min-width: 800px) {
        .m-md-0 {
            margin: 0 !important;
        }
        .mt-md-0,
        .my-md-0 {
            margin-top: 0 !important;
        }
        .mr-md-0,
        .mx-md-0 {
            margin-right: 0 !important;
        }
        .mb-md-0,
        .my-md-0 {
            margin-bottom: 0 !important;
        }
        .ml-md-0,
        .mx-md-0 {
            margin-left: 0 !important;
        }
        .m-md-1 {
            margin: 0.25rem !important;
        }
        .mt-md-1,
        .my-md-1 {
            margin-top: 0.25rem !important;
        }
        .mr-md-1,
        .mx-md-1 {
            margin-right: 0.25rem !important;
        }
        .mb-md-1,
        .my-md-1 {
            margin-bottom: 0.25rem !important;
        }
        .ml-md-1,
        .mx-md-1 {
            margin-left: 0.25rem !important;
        }
        .m-md-2 {
            margin: 0.5rem !important;
        }
        .mt-md-2,
        .my-md-2 {
            margin-top: 0.5rem !important;
        }
        .mr-md-2,
        .mx-md-2 {
            margin-right: 0.5rem !important;
        }
        .mb-md-2,
        .my-md-2 {
            margin-bottom: 0.5rem !important;
        }
        .ml-md-2,
        .mx-md-2 {
            margin-left: 0.5rem !important;
        }
        .m-md-3 {
            margin: 1rem !important;
        }
        .mt-md-3,
        .my-md-3 {
            margin-top: 1rem !important;
        }
        .mr-md-3,
        .mx-md-3 {
            margin-right: 1rem !important;
        }
        .mb-md-3,
        .my-md-3 {
            margin-bottom: 1rem !important;
        }
        .ml-md-3,
        .mx-md-3 {
            margin-left: 1rem !important;
        }
        .m-md-4 {
            margin: 1.5rem !important;
        }
        .mt-md-4,
        .my-md-4 {
            margin-top: 1.5rem !important;
        }
        .mr-md-4,
        .mx-md-4 {
            margin-right: 1.5rem !important;
        }
        .mb-md-4,
        .my-md-4 {
            margin-bottom: 1.5rem !important;
        }
        .ml-md-4,
        .mx-md-4 {
            margin-left: 1.5rem !important;
        }
        .m-md-5 {
            margin: 3rem !important;
        }
        .mt-md-5,
        .my-md-5 {
            margin-top: 3rem !important;
        }
        .mr-md-5,
        .mx-md-5 {
            margin-right: 3rem !important;
        }
        .mb-md-5,
        .my-md-5 {
            margin-bottom: 3rem !important;
        }
        .ml-md-5,
        .mx-md-5 {
            margin-left: 3rem !important;
        }
        .p-md-0 {
            padding: 0 !important;
        }
        .pt-md-0,
        .py-md-0 {
            padding-top: 0 !important;
        }
        .pr-md-0,
        .px-md-0 {
            padding-right: 0 !important;
        }
        .pb-md-0,
        .py-md-0 {
            padding-bottom: 0 !important;
        }
        .pl-md-0,
        .px-md-0 {
            padding-left: 0 !important;
        }
        .p-md-1 {
            padding: 0.25rem !important;
        }
        .pt-md-1,
        .py-md-1 {
            padding-top: 0.25rem !important;
        }
        .pr-md-1,
        .px-md-1 {
            padding-right: 0.25rem !important;
        }
        .pb-md-1,
        .py-md-1 {
            padding-bottom: 0.25rem !important;
        }
        .pl-md-1,
        .px-md-1 {
            padding-left: 0.25rem !important;
        }
        .p-md-2 {
            padding: 0.5rem !important;
        }
        .pt-md-2,
        .py-md-2 {
            padding-top: 0.5rem !important;
        }
        .pr-md-2,
        .px-md-2 {
            padding-right: 0.5rem !important;
        }
        .pb-md-2,
        .py-md-2 {
            padding-bottom: 0.5rem !important;
        }
        .pl-md-2,
        .px-md-2 {
            padding-left: 0.5rem !important;
        }
        .p-md-3 {
            padding: 1rem !important;
        }
        .pt-md-3,
        .py-md-3 {
            padding-top: 1rem !important;
        }
        .pr-md-3,
        .px-md-3 {
            padding-right: 1rem !important;
        }
        .pb-md-3,
        .py-md-3 {
            padding-bottom: 1rem !important;
        }
        .pl-md-3,
        .px-md-3 {
            padding-left: 1rem !important;
        }
        .p-md-4 {
            padding: 1.5rem !important;
        }
        .pt-md-4,
        .py-md-4 {
            padding-top: 1.5rem !important;
        }
        .pr-md-4,
        .px-md-4 {
            padding-right: 1.5rem !important;
        }
        .pb-md-4,
        .py-md-4 {
            padding-bottom: 1.5rem !important;
        }
        .pl-md-4,
        .px-md-4 {
            padding-left: 1.5rem !important;
        }
        .p-md-5 {
            padding: 3rem !important;
        }
        .pt-md-5,
        .py-md-5 {
            padding-top: 3rem !important;
        }
        .pr-md-5,
        .px-md-5 {
            padding-right: 3rem !important;
        }
        .pb-md-5,
        .py-md-5 {
            padding-bottom: 3rem !important;
        }
        .pl-md-5,
        .px-md-5 {
            padding-left: 3rem !important;
        }
        .m-md-auto {
            margin: auto !important;
        }
        .mt-md-auto,
        .my-md-auto {
            margin-top: auto !important;
        }
        .mr-md-auto,
        .mx-md-auto {
            margin-right: auto !important;
        }
        .mb-md-auto,
        .my-md-auto {
            margin-bottom: auto !important;
        }
        .ml-md-auto,
        .mx-md-auto {
            margin-left: auto !important;
        }
    }
    
    @media (min-width: 992px) {
        .m-lg-0 {
            margin: 0 !important;
        }
        .mt-lg-0,
        .my-lg-0 {
            margin-top: 0 !important;
        }
        .mr-lg-0,
        .mx-lg-0 {
            margin-right: 0 !important;
        }
        .mb-lg-0,
        .my-lg-0 {
            margin-bottom: 0 !important;
        }
        .ml-lg-0,
        .mx-lg-0 {
            margin-left: 0 !important;
        }
        .m-lg-1 {
            margin: 0.25rem !important;
        }
        .mt-lg-1,
        .my-lg-1 {
            margin-top: 0.25rem !important;
        }
        .mr-lg-1,
        .mx-lg-1 {
            margin-right: 0.25rem !important;
        }
        .mb-lg-1,
        .my-lg-1 {
            margin-bottom: 0.25rem !important;
        }
        .ml-lg-1,
        .mx-lg-1 {
            margin-left: 0.25rem !important;
        }
        .m-lg-2 {
            margin: 0.5rem !important;
        }
        .mt-lg-2,
        .my-lg-2 {
            margin-top: 0.5rem !important;
        }
        .mr-lg-2,
        .mx-lg-2 {
            margin-right: 0.5rem !important;
        }
        .mb-lg-2,
        .my-lg-2 {
            margin-bottom: 0.5rem !important;
        }
        .ml-lg-2,
        .mx-lg-2 {
            margin-left: 0.5rem !important;
        }
        .m-lg-3 {
            margin: 1rem !important;
        }
        .mt-lg-3,
        .my-lg-3 {
            margin-top: 1rem !important;
        }
        .mr-lg-3,
        .mx-lg-3 {
            margin-right: 1rem !important;
        }
        .mb-lg-3,
        .my-lg-3 {
            margin-bottom: 1rem !important;
        }
        .ml-lg-3,
        .mx-lg-3 {
            margin-left: 1rem !important;
        }
        .m-lg-4 {
            margin: 1.5rem !important;
        }
        .mt-lg-4,
        .my-lg-4 {
            margin-top: 1.5rem !important;
        }
        .mr-lg-4,
        .mx-lg-4 {
            margin-right: 1.5rem !important;
        }
        .mb-lg-4,
        .my-lg-4 {
            margin-bottom: 1.5rem !important;
        }
        .ml-lg-4,
        .mx-lg-4 {
            margin-left: 1.5rem !important;
        }
        .m-lg-5 {
            margin: 3rem !important;
        }
        .mt-lg-5,
        .my-lg-5 {
            margin-top: 3rem !important;
        }
        .mr-lg-5,
        .mx-lg-5 {
            margin-right: 3rem !important;
        }
        .mb-lg-5,
        .my-lg-5 {
            margin-bottom: 3rem !important;
        }
        .ml-lg-5,
        .mx-lg-5 {
            margin-left: 3rem !important;
        }
        .p-lg-0 {
            padding: 0 !important;
        }
        .pt-lg-0,
        .py-lg-0 {
            padding-top: 0 !important;
        }
        .pr-lg-0,
        .px-lg-0 {
            padding-right: 0 !important;
        }
        .pb-lg-0,
        .py-lg-0 {
            padding-bottom: 0 !important;
        }
        .pl-lg-0,
        .px-lg-0 {
            padding-left: 0 !important;
        }
        .p-lg-1 {
            padding: 0.25rem !important;
        }
        .pt-lg-1,
        .py-lg-1 {
            padding-top: 0.25rem !important;
        }
        .pr-lg-1,
        .px-lg-1 {
            padding-right: 0.25rem !important;
        }
        .pb-lg-1,
        .py-lg-1 {
            padding-bottom: 0.25rem !important;
        }
        .pl-lg-1,
        .px-lg-1 {
            padding-left: 0.25rem !important;
        }
        .p-lg-2 {
            padding: 0.5rem !important;
        }
        .pt-lg-2,
        .py-lg-2 {
            padding-top: 0.5rem !important;
        }
        .pr-lg-2,
        .px-lg-2 {
            padding-right: 0.5rem !important;
        }
        .pb-lg-2,
        .py-lg-2 {
            padding-bottom: 0.5rem !important;
        }
        .pl-lg-2,
        .px-lg-2 {
            padding-left: 0.5rem !important;
        }
        .p-lg-3 {
            padding: 1rem !important;
        }
        .pt-lg-3,
        .py-lg-3 {
            padding-top: 1rem !important;
        }
        .pr-lg-3,
        .px-lg-3 {
            padding-right: 1rem !important;
        }
        .pb-lg-3,
        .py-lg-3 {
            padding-bottom: 1rem !important;
        }
        .pl-lg-3,
        .px-lg-3 {
            padding-left: 1rem !important;
        }
        .p-lg-4 {
            padding: 1.5rem !important;
        }
        .pt-lg-4,
        .py-lg-4 {
            padding-top: 1.5rem !important;
        }
        .pr-lg-4,
        .px-lg-4 {
            padding-right: 1.5rem !important;
        }
        .pb-lg-4,
        .py-lg-4 {
            padding-bottom: 1.5rem !important;
        }
        .pl-lg-4,
        .px-lg-4 {
            padding-left: 1.5rem !important;
        }
        .p-lg-5 {
            padding: 3rem !important;
        }
        .pt-lg-5,
        .py-lg-5 {
            padding-top: 3rem !important;
        }
        .pr-lg-5,
        .px-lg-5 {
            padding-right: 3rem !important;
        }
        .pb-lg-5,
        .py-lg-5 {
            padding-bottom: 3rem !important;
        }
        .pl-lg-5,
        .px-lg-5 {
            padding-left: 3rem !important;
        }
        .m-lg-auto {
            margin: auto !important;
        }
        .mt-lg-auto,
        .my-lg-auto {
            margin-top: auto !important;
        }
        .mr-lg-auto,
        .mx-lg-auto {
            margin-right: auto !important;
        }
        .mb-lg-auto,
        .my-lg-auto {
            margin-bottom: auto !important;
        }
        .ml-lg-auto,
        .mx-lg-auto {
            margin-left: auto !important;
        }
    }
    
    @media (min-width: 1200px) {
        .m-xl-0 {
            margin: 0 !important;
        }
        .mt-xl-0,
        .my-xl-0 {
            margin-top: 0 !important;
        }
        .mr-xl-0,
        .mx-xl-0 {
            margin-right: 0 !important;
        }
        .mb-xl-0,
        .my-xl-0 {
            margin-bottom: 0 !important;
        }
        .ml-xl-0,
        .mx-xl-0 {
            margin-left: 0 !important;
        }
        .m-xl-1 {
            margin: 0.25rem !important;
        }
        .mt-xl-1,
        .my-xl-1 {
            margin-top: 0.25rem !important;
        }
        .mr-xl-1,
        .mx-xl-1 {
            margin-right: 0.25rem !important;
        }
        .mb-xl-1,
        .my-xl-1 {
            margin-bottom: 0.25rem !important;
        }
        .ml-xl-1,
        .mx-xl-1 {
            margin-left: 0.25rem !important;
        }
        .m-xl-2 {
            margin: 0.5rem !important;
        }
        .mt-xl-2,
        .my-xl-2 {
            margin-top: 0.5rem !important;
        }
        .mr-xl-2,
        .mx-xl-2 {
            margin-right: 0.5rem !important;
        }
        .mb-xl-2,
        .my-xl-2 {
            margin-bottom: 0.5rem !important;
        }
        .ml-xl-2,
        .mx-xl-2 {
            margin-left: 0.5rem !important;
        }
        .m-xl-3 {
            margin: 1rem !important;
        }
        .mt-xl-3,
        .my-xl-3 {
            margin-top: 1rem !important;
        }
        .mr-xl-3,
        .mx-xl-3 {
            margin-right: 1rem !important;
        }
        .mb-xl-3,
        .my-xl-3 {
            margin-bottom: 1rem !important;
        }
        .ml-xl-3,
        .mx-xl-3 {
            margin-left: 1rem !important;
        }
        .m-xl-4 {
            margin: 1.5rem !important;
        }
        .mt-xl-4,
        .my-xl-4 {
            margin-top: 1.5rem !important;
        }
        .mr-xl-4,
        .mx-xl-4 {
            margin-right: 1.5rem !important;
        }
        .mb-xl-4,
        .my-xl-4 {
            margin-bottom: 1.5rem !important;
        }
        .ml-xl-4,
        .mx-xl-4 {
            margin-left: 1.5rem !important;
        }
        .m-xl-5 {
            margin: 3rem !important;
        }
        .mt-xl-5,
        .my-xl-5 {
            margin-top: 3rem !important;
        }
        .mr-xl-5,
        .mx-xl-5 {
            margin-right: 3rem !important;
        }
        .mb-xl-5,
        .my-xl-5 {
            margin-bottom: 3rem !important;
        }
        .ml-xl-5,
        .mx-xl-5 {
            margin-left: 3rem !important;
        }
        .p-xl-0 {
            padding: 0 !important;
        }
        .pt-xl-0,
        .py-xl-0 {
            padding-top: 0 !important;
        }
        .pr-xl-0,
        .px-xl-0 {
            padding-right: 0 !important;
        }
        .pb-xl-0,
        .py-xl-0 {
            padding-bottom: 0 !important;
        }
        .pl-xl-0,
        .px-xl-0 {
            padding-left: 0 !important;
        }
        .p-xl-1 {
            padding: 0.25rem !important;
        }
        .pt-xl-1,
        .py-xl-1 {
            padding-top: 0.25rem !important;
        }
        .pr-xl-1,
        .px-xl-1 {
            padding-right: 0.25rem !important;
        }
        .pb-xl-1,
        .py-xl-1 {
            padding-bottom: 0.25rem !important;
        }
        .pl-xl-1,
        .px-xl-1 {
            padding-left: 0.25rem !important;
        }
        .p-xl-2 {
            padding: 0.5rem !important;
        }
        .pt-xl-2,
        .py-xl-2 {
            padding-top: 0.5rem !important;
        }
        .pr-xl-2,
        .px-xl-2 {
            padding-right: 0.5rem !important;
        }
        .pb-xl-2,
        .py-xl-2 {
            padding-bottom: 0.5rem !important;
        }
        .pl-xl-2,
        .px-xl-2 {
            padding-left: 0.5rem !important;
        }
        .p-xl-3 {
            padding: 1rem !important;
        }
        .pt-xl-3,
        .py-xl-3 {
            padding-top: 1rem !important;
        }
        .pr-xl-3,
        .px-xl-3 {
            padding-right: 1rem !important;
        }
        .pb-xl-3,
        .py-xl-3 {
            padding-bottom: 1rem !important;
        }
        .pl-xl-3,
        .px-xl-3 {
            padding-left: 1rem !important;
        }
        .p-xl-4 {
            padding: 1.5rem !important;
        }
        .pt-xl-4,
        .py-xl-4 {
            padding-top: 1.5rem !important;
        }
        .pr-xl-4,
        .px-xl-4 {
            padding-right: 1.5rem !important;
        }
        .pb-xl-4,
        .py-xl-4 {
            padding-bottom: 1.5rem !important;
        }
        .pl-xl-4,
        .px-xl-4 {
            padding-left: 1.5rem !important;
        }
        .p-xl-5 {
            padding: 3rem !important;
        }
        .pt-xl-5,
        .py-xl-5 {
            padding-top: 3rem !important;
        }
        .pr-xl-5,
        .px-xl-5 {
            padding-right: 3rem !important;
        }
        .pb-xl-5,
        .py-xl-5 {
            padding-bottom: 3rem !important;
        }
        .pl-xl-5,
        .px-xl-5 {
            padding-left: 3rem !important;
        }
        .m-xl-auto {
            margin: auto !important;
        }
        .mt-xl-auto,
        .my-xl-auto {
            margin-top: auto !important;
        }
        .mr-xl-auto,
        .mx-xl-auto {
            margin-right: auto !important;
        }
        .mb-xl-auto,
        .my-xl-auto {
            margin-bottom: auto !important;
        }
        .ml-xl-auto,
        .mx-xl-auto {
            margin-left: auto !important;
        }
    }
    
    @media (min-width: 1600px) {
        .m-xlg-0 {
            margin: 0 !important;
        }
        .mt-xlg-0,
        .my-xlg-0 {
            margin-top: 0 !important;
        }
        .mr-xlg-0,
        .mx-xlg-0 {
            margin-right: 0 !important;
        }
        .mb-xlg-0,
        .my-xlg-0 {
            margin-bottom: 0 !important;
        }
        .ml-xlg-0,
        .mx-xlg-0 {
            margin-left: 0 !important;
        }
        .m-xlg-1 {
            margin: 0.25rem !important;
        }
        .mt-xlg-1,
        .my-xlg-1 {
            margin-top: 0.25rem !important;
        }
        .mr-xlg-1,
        .mx-xlg-1 {
            margin-right: 0.25rem !important;
        }
        .mb-xlg-1,
        .my-xlg-1 {
            margin-bottom: 0.25rem !important;
        }
        .ml-xlg-1,
        .mx-xlg-1 {
            margin-left: 0.25rem !important;
        }
        .m-xlg-2 {
            margin: 0.5rem !important;
        }
        .mt-xlg-2,
        .my-xlg-2 {
            margin-top: 0.5rem !important;
        }
        .mr-xlg-2,
        .mx-xlg-2 {
            margin-right: 0.5rem !important;
        }
        .mb-xlg-2,
        .my-xlg-2 {
            margin-bottom: 0.5rem !important;
        }
        .ml-xlg-2,
        .mx-xlg-2 {
            margin-left: 0.5rem !important;
        }
        .m-xlg-3 {
            margin: 1rem !important;
        }
        .mt-xlg-3,
        .my-xlg-3 {
            margin-top: 1rem !important;
        }
        .mr-xlg-3,
        .mx-xlg-3 {
            margin-right: 1rem !important;
        }
        .mb-xlg-3,
        .my-xlg-3 {
            margin-bottom: 1rem !important;
        }
        .ml-xlg-3,
        .mx-xlg-3 {
            margin-left: 1rem !important;
        }
        .m-xlg-4 {
            margin: 1.5rem !important;
        }
        .mt-xlg-4,
        .my-xlg-4 {
            margin-top: 1.5rem !important;
        }
        .mr-xlg-4,
        .mx-xlg-4 {
            margin-right: 1.5rem !important;
        }
        .mb-xlg-4,
        .my-xlg-4 {
            margin-bottom: 1.5rem !important;
        }
        .ml-xlg-4,
        .mx-xlg-4 {
            margin-left: 1.5rem !important;
        }
        .m-xlg-5 {
            margin: 3rem !important;
        }
        .mt-xlg-5,
        .my-xlg-5 {
            margin-top: 3rem !important;
        }
        .mr-xlg-5,
        .mx-xlg-5 {
            margin-right: 3rem !important;
        }
        .mb-xlg-5,
        .my-xlg-5 {
            margin-bottom: 3rem !important;
        }
        .ml-xlg-5,
        .mx-xlg-5 {
            margin-left: 3rem !important;
        }
        .p-xlg-0 {
            padding: 0 !important;
        }
        .pt-xlg-0,
        .py-xlg-0 {
            padding-top: 0 !important;
        }
        .pr-xlg-0,
        .px-xlg-0 {
            padding-right: 0 !important;
        }
        .pb-xlg-0,
        .py-xlg-0 {
            padding-bottom: 0 !important;
        }
        .pl-xlg-0,
        .px-xlg-0 {
            padding-left: 0 !important;
        }
        .p-xlg-1 {
            padding: 0.25rem !important;
        }
        .pt-xlg-1,
        .py-xlg-1 {
            padding-top: 0.25rem !important;
        }
        .pr-xlg-1,
        .px-xlg-1 {
            padding-right: 0.25rem !important;
        }
        .pb-xlg-1,
        .py-xlg-1 {
            padding-bottom: 0.25rem !important;
        }
        .pl-xlg-1,
        .px-xlg-1 {
            padding-left: 0.25rem !important;
        }
        .p-xlg-2 {
            padding: 0.5rem !important;
        }
        .pt-xlg-2,
        .py-xlg-2 {
            padding-top: 0.5rem !important;
        }
        .pr-xlg-2,
        .px-xlg-2 {
            padding-right: 0.5rem !important;
        }
        .pb-xlg-2,
        .py-xlg-2 {
            padding-bottom: 0.5rem !important;
        }
        .pl-xlg-2,
        .px-xlg-2 {
            padding-left: 0.5rem !important;
        }
        .p-xlg-3 {
            padding: 1rem !important;
        }
        .pt-xlg-3,
        .py-xlg-3 {
            padding-top: 1rem !important;
        }
        .pr-xlg-3,
        .px-xlg-3 {
            padding-right: 1rem !important;
        }
        .pb-xlg-3,
        .py-xlg-3 {
            padding-bottom: 1rem !important;
        }
        .pl-xlg-3,
        .px-xlg-3 {
            padding-left: 1rem !important;
        }
        .p-xlg-4 {
            padding: 1.5rem !important;
        }
        .pt-xlg-4,
        .py-xlg-4 {
            padding-top: 1.5rem !important;
        }
        .pr-xlg-4,
        .px-xlg-4 {
            padding-right: 1.5rem !important;
        }
        .pb-xlg-4,
        .py-xlg-4 {
            padding-bottom: 1.5rem !important;
        }
        .pl-xlg-4,
        .px-xlg-4 {
            padding-left: 1.5rem !important;
        }
        .p-xlg-5 {
            padding: 3rem !important;
        }
        .pt-xlg-5,
        .py-xlg-5 {
            padding-top: 3rem !important;
        }
        .pr-xlg-5,
        .px-xlg-5 {
            padding-right: 3rem !important;
        }
        .pb-xlg-5,
        .py-xlg-5 {
            padding-bottom: 3rem !important;
        }
        .pl-xlg-5,
        .px-xlg-5 {
            padding-left: 3rem !important;
        }
        .m-xlg-auto {
            margin: auto !important;
        }
        .mt-xlg-auto,
        .my-xlg-auto {
            margin-top: auto !important;
        }
        .mr-xlg-auto,
        .mx-xlg-auto {
            margin-right: auto !important;
        }
        .mb-xlg-auto,
        .my-xlg-auto {
            margin-bottom: auto !important;
        }
        .ml-xlg-auto,
        .mx-xlg-auto {
            margin-left: auto !important;
        }
    }
    
    .text-monospace {
        font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    }
    
    .text-justify {
        text-align: justify !important;
    }
    
    .text-nowrap {
        white-space: nowrap !important;
    }
    
    .text-truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    
    .text-left {
        text-align: left !important;
    }
    
    .text-right {
        text-align: right !important;
    }
    
    .text-center {
        text-align: center !important;
    }
    
    @media (min-width: 576px) {
        .text-sm-left {
            text-align: left !important;
        }
        .text-sm-right {
            text-align: right !important;
        }
        .text-sm-center {
            text-align: center !important;
        }
    }
    
    @media (min-width: 800px) {
        .text-md-left {
            text-align: left !important;
        }
        .text-md-right {
            text-align: right !important;
        }
        .text-md-center {
            text-align: center !important;
        }
    }
    
    @media (min-width: 992px) {
        .text-lg-left {
            text-align: left !important;
        }
        .text-lg-right {
            text-align: right !important;
        }
        .text-lg-center {
            text-align: center !important;
        }
    }
    
    @media (min-width: 1200px) {
        .text-xl-left {
            text-align: left !important;
        }
        .text-xl-right {
            text-align: right !important;
        }
        .text-xl-center {
            text-align: center !important;
        }
    }
    
    @media (min-width: 1600px) {
        .text-xlg-left {
            text-align: left !important;
        }
        .text-xlg-right {
            text-align: right !important;
        }
        .text-xlg-center {
            text-align: center !important;
        }
    }
    
    .text-lowercase {
        text-transform: lowercase !important;
    }
    
    .text-uppercase {
        text-transform: uppercase !important;
    }
    
    .text-capitalize {
        text-transform: capitalize !important;
    }
    
    .font-weight-light {
        font-weight: 300 !important;
    }
    
    .font-weight-normal {
        font-weight: 400 !important;
    }
    
    .font-weight-bold {
        font-weight: 800 !important;
    }
    
    .font-italic {
        font-style: italic !important;
    }
    
    .text-white {
        color: #fff !important;
    }
    
    .text-primary {
        color: #5e72e4 !important;
    }
    
    a.text-primary:hover,
    a.text-primary:focus {
        color: #324cdd !important;
    }
    
    .text-secondary {
        color: #8898aa !important;
    }
    
    a.text-secondary:hover,
    a.text-secondary:focus {
        color: #6a7e95 !important;
    }
    
    .text-success {
        color: #2dce89 !important;
    }
    
    a.text-success:hover,
    a.text-success:focus {
        color: #24a46d !important;
    }
    
    .text-info {
        color: #2c6daa !important;
    }
    
    a.text-info:hover,
    a.text-info:focus {
        color: #0041f5 !important;
    }
    
    .text-warning {
        color: #fb6340 !important;
    }
    
    a.text-warning:hover,
    a.text-warning:focus {
        color: #fa3a0e !important;
    }
    
    .text-danger {
        color: #f62d51 !important;
    }
    
    a.text-danger:hover,
    a.text-danger:focus {
        color: #e60a31 !important;
    }
    
    .text-light {
        color: #f6f9fc !important;
    }
    
    a.text-light:hover,
    a.text-light:focus {
        color: #d0e0ef !important;
    }
    
    .text-dark {
        color: #32325d !important;
    }
    
    a.text-dark:hover,
    a.text-dark:focus {
        color: #20203c !important;
    }
    
    .text-cyan {
        color: #4fc3f7 !important;
    }
    
    a.text-cyan:hover,
    a.text-cyan:focus {
        color: #1eb2f5 !important;
    }
    
    .text-orange {
        color: #fb6340 !important;
    }
    
    a.text-orange:hover,
    a.text-orange:focus {
        color: #fa3a0e !important;
    }
    
    .text-purple {
        color: #5e72e4 !important;
    }
    
    a.text-purple:hover,
    a.text-purple:focus {
        color: #324cdd !important;
    }
    
    .text-inverse {
        color: #32325d !important;
    }
    
    a.text-inverse:hover,
    a.text-inverse:focus {
        color: #20203c !important;
    }
    
    .text-body {
        color: #3e5569 !important;
    }
    
    .text-muted {
        color: #8898aa !important;
    }
    
    .text-black-50 {
        color: rgba(0, 0, 0, 0.5) !important;
    }
    
    .text-white-50 {
        color: rgba(255, 255, 255, 0.5) !important;
    }
    
    .text-hide {
        font: 0/0 a;
        color: transparent;
        text-shadow: none;
        background-color: transparent;
        border: 0;
    }
    
    .visible {
        visibility: visible !important;
    }
    
    .invisible {
        visibility: hidden !important;
    }
    
    @media print {
        *,
        *::before,
        *::after {
            text-shadow: none !important;
            box-shadow: none !important;
        }
        a:not(.btn) {
            text-decoration: underline;
        }
        abbr[title]::after {
            content: " ("attr(title) ")";
        }
        pre {
            white-space: pre-wrap !important;
        }
        pre,
        blockquote {
            border: 1px solid #adb5bd;
            page-break-inside: avoid;
        }
        thead {
            display: table-header-group;
        }
        tr,
        img {
            page-break-inside: avoid;
        }
        p,
        h2,
        h3 {
            orphans: 3;
            widows: 3;
        }
        h2,
        h3 {
            page-break-after: avoid;
        }
        @page {
            size: a3;
        }
        body {
            min-width: 992px !important;
        }
        .container {
            min-width: 992px !important;
        }
        .navbar {
            display: none;
        }
        .badge {
            border: 1px solid #000;
        }
        .table {
            border-collapse: collapse !important;
        }
        .table td,
        .table th {
            background-color: #fff !important;
        }
        .table-bordered th,
        .table-bordered td {
            border: 1px solid #dee2e6 !important;
        }
        .table-dark {
            color: inherit;
        }
        .table-dark th,
        .table-dark td,
        .table-dark thead th,
        .table-dark tbody+tbody {
            border-color: #dee2e6;
        }
        .table .thead-dark th {
            color: inherit;
            border-color: #dee2e6;
        }
    }
    /*******************************/
    /*******************************/
    /*Theme Colors*/
    /*Topbar Colors*/
    
    .topbar {
        box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.05);
        -webkit-box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.05);
        -o-box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.05);
    }
    /*Sidebar Colors*/
    /*Boxed layout width*/
    /*Shadow*/
    /* transitions */
    /* Dark transparent bg */
    
    .search-box .app-search .form-control,
    .topbar .mega-dropdown .dropdown-menu {
        border-radius: 0px;
    }
    
    .topbar .dropdown-menu .with-arrow>span {
        transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
    }
    /* ***************************** */
    /******************************
/* Color system*/
    
    .card {
        box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
        -webkit-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
        -moz-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
        -o-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
    }
    
    .fill-primary {
        fill: #5e72e4;
    }
    
    .stroke-primary {
        stroke: #5e72e4;
    }
    
    .fill-secondary {
        fill: #8898aa;
    }
    
    .stroke-secondary {
        stroke: #8898aa;
    }
    
    .fill-success {
        fill: #2dce89;
    }
    
    .stroke-success {
        stroke: #2dce89;
    }
    
    .fill-info {
        fill: #2c6daa;
    }
    
    .stroke-info {
        stroke: #2c6daa;
    }
    
    .fill-warning {
        fill: #fb6340;
    }
    
    .stroke-warning {
        stroke: #fb6340;
    }
    
    .fill-danger {
        fill: #f62d51;
    }
    
    .stroke-danger {
        stroke: #f62d51;
    }
    
    .fill-light {
        fill: #f6f9fc;
    }
    
    .stroke-light {
        stroke: #f6f9fc;
    }
    
    .fill-dark {
        fill: #32325d;
    }
    
    .stroke-dark {
        stroke: #32325d;
    }
    
    .fill-cyan {
        fill: #4fc3f7;
    }
    
    .stroke-cyan {
        stroke: #4fc3f7;
    }
    
    .fill-orange {
        fill: #fb6340;
    }
    
    .stroke-orange {
        stroke: #fb6340;
    }
    
    .fill-purple {
        fill: #5e72e4;
    }
    
    .stroke-purple {
        stroke: #5e72e4;
    }
    
    .fill-inverse {
        fill: #32325d;
    }
    
    .stroke-inverse {
        stroke: #32325d;
    }
    
    .bg-gradient-primary {
        background: linear-gradient(87deg, #5e72e4 0, #825ee4 100%) !important;
    }
    
    .bg-gradient-secondary {
        background: linear-gradient(87deg, #8898aa 0, #888aaa 100%) !important;
    }
    
    .bg-gradient-success {
        background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%) !important;
    }
    
    .bg-gradient-info {
        background: linear-gradient(87deg, #2c6daa 0, #4929ff 100%) !important;
    }
    
    .bg-gradient-warning {
        background: linear-gradient(87deg, #fb6340 0, #fbb140 100%) !important;
    }
    
    .bg-gradient-danger {
        background: linear-gradient(87deg, #f62d51 0, #f65d2d 100%) !important;
    }
    
    .bg-gradient-light {
        background: linear-gradient(87deg, #f6f9fc 0, #f6f7fc 100%) !important;
    }
    
    .bg-gradient-dark {
        background: linear-gradient(87deg, #32325d 0, #44325d 100%) !important;
    }
    
    .bg-gradient-cyan {
        background: linear-gradient(87deg, #4fc3f7 0, #4f7df7 100%) !important;
    }
    
    .bg-gradient-orange {
        background: linear-gradient(87deg, #fb6340 0, #fbb140 100%) !important;
    }
    
    .bg-gradient-purple {
        background: linear-gradient(87deg, #5e72e4 0, #825ee4 100%) !important;
    }
    
    .bg-gradient-inverse {
        background: linear-gradient(87deg, #32325d 0, #44325d 100%) !important;
    }
    
    .fill-primary {
        fill: #5e72e4;
    }
    
    .stroke-primary {
        stroke: #5e72e4;
    }
    
    .fill-secondary {
        fill: #8898aa;
    }
    
    .stroke-secondary {
        stroke: #8898aa;
    }
    
    .fill-success {
        fill: #2dce89;
    }
    
    .stroke-success {
        stroke: #2dce89;
    }
    
    .fill-info {
        fill: #2c6daa;
    }
    
    .stroke-info {
        stroke: #2c6daa;
    }
    
    .fill-warning {
        fill: #fb6340;
    }
    
    .stroke-warning {
        stroke: #fb6340;
    }
    
    .fill-danger {
        fill: #f62d51;
    }
    
    .stroke-danger {
        stroke: #f62d51;
    }
    
    .fill-light {
        fill: #f6f9fc;
    }
    
    .stroke-light {
        stroke: #f6f9fc;
    }
    
    .fill-dark {
        fill: #32325d;
    }
    
    .stroke-dark {
        stroke: #32325d;
    }
    
    .fill-cyan {
        fill: #4fc3f7;
    }
    
    .stroke-cyan {
        stroke: #4fc3f7;
    }
    
    .fill-orange {
        fill: #fb6340;
    }
    
    .stroke-orange {
        stroke: #fb6340;
    }
    
    .fill-purple {
        fill: #5e72e4;
    }
    
    .stroke-purple {
        stroke: #5e72e4;
    }
    
    .fill-inverse {
        fill: #32325d;
    }
    
    .stroke-inverse {
        stroke: #32325d;
    }
    
    .floating {
        animation: floating 3s ease infinite;
        will-change: transform;
    }
    
    .floating:hover {
        animation-play-state: paused;
    }
    
    .floating-lg {
        animation: floating-lg 3s ease infinite;
    }
    
    .floating-sm {
        animation: floating-sm 3s ease infinite;
    }
    
    @keyframes floating-lg {
        0% {
            transform: translateY(0px);
        }
        50% {
            transform: translateY(15px);
        }
        100% {
            transform: translateY(0px);
        }
    }
    
    @keyframes floating {
        0% {
            transform: translateY(0px);
        }
        50% {
            transform: translateY(10px);
        }
        100% {
            transform: translateY(0px);
        }
    }
    
    @keyframes floating-sm {
        0% {
            transform: translateY(0px);
        }
        50% {
            transform: translateY(5px);
        }
        100% {
            transform: translateY(0px);
        }
    }
    
    .font-bold {
        font-weight: 800;
    }
    
    .font-normal {
        font-weight: 400;
    }
    
    .font-light {
        font-weight: 300;
    }
    
    .font-medium {
        font-weight: 600;
    }
    
    .card {
        margin-bottom: 30px;
    }
    
    .card .card-subtitle {
        font-weight: 300;
        margin-bottom: 10px;
        color: #8898aa;
    }
    
    .card .card-title {
        position: relative;
        font-weight: 600;
    }
    
    .card .card-actions {
        float: right;
    }
    
    .card .card-actions a {
        padding: 0 5px;
        cursor: pointer;
    }
    
    .card .card-header .card-title {
        margin-bottom: 0px;
    }
    
    .card-alt {
        margin: 0 -20px;
        background: #e4e9ef;
    }
    
    .card-group {
        margin-bottom: 30px;
    }
    
    .card-group .card {
        border-right: 1px solid #e9ecef;
    }
    
    .card-fullscreen {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 9999;
        overflow: auto;
    }
    
    .card-hover {
        -webkit-transition: all 0.25s ease;
        -o-transition: all 0.25s ease;
        -moz-transition: all 0.25s ease;
        transition: all 0.25s ease;
    }
    
    .card-hover:hover {
        -webkit-transform: translateY(-4px) scale(1.01);
        -moz-transform: translateY(-4px) scale(1.01);
        -ms-transform: translateY(-4px) scale(1.01);
        -o-transform: translateY(-4px) scale(1.01);
        transform: translateY(-4px) scale(1.01);
        -webkit-box-shadow: 0 14px 24px rgba(62, 57, 107, 0.1);
        box-shadow: 0 14px 24px rgba(62, 57, 107, 0.1);
    }
    
    .draggable-cards .card-header {
        cursor: move;
    }
    
    .card-moved .card {
        background: #4fc3f7;
        color: #fff;
    }
    
    .accordion .card,
    .accordion .btn,
    .accordion .btn:hover {
        box-shadow: none;
        margin-bottom: 1px;
    }
    
    .font-16 {
        font-size: 16px;
    }
    
    .font-12 {
        font-size: 12px;
    }
    
    .font-14 {
        font-size: 14px;
    }
    
    .font-10 {
        font-size: 10px;
    }
    
    .font-18 {
        font-size: 18px;
    }
    
    .font-20 {
        font-size: 20px;
    }
    
    .font-22 {
        font-size: 22px;
    }
    
    .font-24 {
        font-size: 24px;
    }
    
    .display-5 {
        font-size: 3rem;
        font-weight: 300;
        line-height: 1.2;
    }
    
    .display-6 {
        font-size: 2.5rem;
        font-weight: 300;
        line-height: 1.2;
    }
    
    .display-7 {
        font-size: 2rem;
        font-weight: 300;
        line-height: 1.2;
    }
    
    .blockquote {
        border-left: 5px solid #007bff;
        border: 1px solid #e9ecef;
        padding: 15px;
    }
    
    .badge-circle {
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        font-size: 0.875rem;
    }
    
    .badge-rounded {
        border-radius: 40px;
    }
    /*******************
Rounds
*******************/
    
    .round {
        line-height: 48px;
        color: #fff;
        width: 50px;
        height: 50px;
        display: inline-block;
        font-weight: 400;
        text-align: center;
        border-radius: 100%;
        background: #007bff;
        line-height: 52px;
    }
    
    .round img {
        border-radius: 100%;
    }
    
    .round.round-info {
        background: #007bff;
    }
    
    .round.round-warning {
        background: #ffc107;
    }
    
    .round.round-danger {
        background: #dc3545;
    }
    
    .round.round-success {
        background: #28a745;
    }
    
    .round.round-primary {
        background: #007bff;
    }
    
    .round-lg {
        line-height: 65px;
        width: 60px;
        height: 60px;
        font-size: 30px;
    }
    
    * {
        outline: none;
    }
    
    body {
        margin: 0;
        overflow-x: hidden;
        color: #3e5569;
        background: #fff;
    }
    
    html {
        position: relative;
    }
    
    a.link {
        color: #3e5569;
    }
    
    a.link:hover,
    a.link:focus {
        color: #007bff;
    }
    
    .no-wrap td,
    .no-wrap th {
        white-space: nowrap;
    }
    
    .clear {
        clear: both;
    }
    
    .hide {
        display: none;
    }
    /*******************
Opacity
*******************/
    
    .op-5 {
        opacity: 0.5;
    }
    
    .op-3 {
        opacity: 0.3;
    }
    
    .oh {
        overflow: hidden;
    }
    /*******************
List-style-none
******************/
    
    ul.list-style-none {
        margin: 0px;
        padding: 0px;
    }
    
    ul.list-style-none li {
        list-style: none;
    }
    
    ul.list-style-none li a {
        color: #3e5569;
        padding: 8px 0px;
        display: block;
        text-decoration: none;
    }
    
    ul.list-style-none li a:hover {
        color: #007bff;
    }
    
    .pull-right {
        float: right;
    }
    
    .pull-left {
        float: left;
    }
    
    .error-box {
        height: 100%;
        position: fixed;
        background: url(../../assets/images/background/error-bg.jpg) no-repeat center center #fff;
        width: 100%;
    }
    
    .error-box .error-body {
        padding-top: 5%;
    }
    
    .error-box .error-title {
        font-size: 210px;
        font-weight: 900;
        text-shadow: 4px 4px 0 #fff, 6px 6px 0 #32325d;
        line-height: 210px;
    }
    
    .f-icon,
    .t-icon,
    .w-icon,
    .sl-icon,
    .if-icon,
    .m-icon {
        cursor: pointer;
        padding: 13px 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .f-icon:hover,
    .t-icon:hover,
    .w-icon:hover,
    .sl-icon:hover,
    .if-icon:hover,
    .m-icon:hover {
        background-color: #f6f9fc;
    }
    /*.ps-container {
-ms-touch-action: auto;
touch-action: auto;
overflow: hidden !important;
-ms-overflow-style: none;
}
@supports (-ms-overflow-style: none) {
.ps-container {
 overflow: auto !important;
}
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
.ps-container {
 overflow: auto !important;
}
}
.ps-container.ps-active-x > .ps-scrollbar-x-rail,
.ps-container.ps-active-y > .ps-scrollbar-y-rail {
display: block;
background-color: transparent;
}
.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
background-color: transparent;
opacity: 0.9;
}
.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
background-color: rgba(0, 0, 0, 0.2);
height: 11px;
}
.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
background-color: transparent;
opacity: 0.9;
}
.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
background-color: rgba(0, 0, 0, 0.2);
width: 11px;
}
.ps-container > .ps-scrollbar-x-rail {
display: none;
position: absolute;
opacity: 0;
-webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
-o-transition: background-color 0.2s linear, opacity 0.2s linear;
-moz-transition: background-color 0.2s linear, opacity 0.2s linear;
transition: background-color 0.2s linear, opacity 0.2s linear;
bottom: 0;
height: 15px;
}
.ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
position: absolute;
background-color: rgba(0, 0, 0, 0.2);
-webkit-border-radius: 6px;
-moz-border-radius: 6px;
border-radius: 6px;
-webkit-transition: background-color 0.2s linear, height 0.2s linear,
 width 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out;
transition: background-color 0.2s linear, height 0.2s linear,
 width 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out;
-o-transition: background-color 0.2s linear, height 0.2s linear,
 width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
-moz-transition: background-color 0.2s linear, height 0.2s linear,
 width 0.2s ease-in-out, border-radius 0.2s ease-in-out,
 -moz-border-radius 0.2s ease-in-out;
transition: background-color 0.2s linear, height 0.2s linear,
 width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
transition: background-color 0.2s linear, height 0.2s linear,
 width 0.2s ease-in-out, border-radius 0.2s ease-in-out,
 -webkit-border-radius 0.2s ease-in-out, -moz-border-radius 0.2s ease-in-out;
bottom: 2px;
height: 6px;
}
.ps-container > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x,
.ps-container > .ps-scrollbar-x-rail:active > .ps-scrollbar-x {
height: 6px;
}
.ps-container > .ps-scrollbar-y-rail {
display: none;
position: absolute;
opacity: 0;
-webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
-o-transition: background-color 0.2s linear, opacity 0.2s linear;
-moz-transition: background-color 0.2s linear, opacity 0.2s linear;
transition: background-color 0.2s linear, opacity 0.2s linear;
right: 0;
width: 15px;
}
.ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
position: absolute;
background-color: rgba(0, 0, 0, 0.2);
-webkit-border-radius: 6px;
-moz-border-radius: 6px;
border-radius: 6px;
-webkit-transition: background-color 0.2s linear, height 0.2s linear,
 width 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out;
transition: background-color 0.2s linear, height 0.2s linear,
 width 0.2s ease-in-out, -webkit-border-radius 0.2s ease-in-out;
-o-transition: background-color 0.2s linear, height 0.2s linear,
 width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
-moz-transition: background-color 0.2s linear, height 0.2s linear,
 width 0.2s ease-in-out, border-radius 0.2s ease-in-out,
 -moz-border-radius 0.2s ease-in-out;
transition: background-color 0.2s linear, height 0.2s linear,
 width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
transition: background-color 0.2s linear, height 0.2s linear,
 width 0.2s ease-in-out, border-radius 0.2s ease-in-out,
 -webkit-border-radius 0.2s ease-in-out, -moz-border-radius 0.2s ease-in-out;
right: 2px;
width: 6px;
}
.ps-container > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y,
.ps-container > .ps-scrollbar-y-rail:active > .ps-scrollbar-y {
width: 6px;
}
.ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
background-color: transparent;
opacity: 0.9;
}
.ps-container:hover.ps-in-scrolling.ps-x
> .ps-scrollbar-x-rail
> .ps-scrollbar-x {
background-color: rgba(0, 0, 0, 0.2);
height: 6px;
}
.ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
background-color: transparent;
opacity: 0.9;
}
.ps-container:hover.ps-in-scrolling.ps-y
> .ps-scrollbar-y-rail
> .ps-scrollbar-y {
background-color: rgba(0, 0, 0, 0.2);
width: 6px;
}
.ps-container:hover > .ps-scrollbar-x-rail,
.ps-container:hover > .ps-scrollbar-y-rail {
opacity: 0.6;
}
.ps-container:hover > .ps-scrollbar-x-rail:hover {
background-color: transparent;
opacity: 0.9;
}
.ps-container:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
background-color: rgba(0, 0, 0, 0.2);
}
.ps-container:hover > .ps-scrollbar-y-rail:hover {
background-color: transparent;
opacity: 0.9;
}
.ps-container:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
background-color: rgba(0, 0, 0, 0.2);
}

.ps-container .ps-scrollbar-y-rail {
position: absolute;
right: 3px;
width: 8px;
-webkit-border-radius: 4px;
-moz-border-radius: 4px;
border-radius: 4px;
opacity: 0;
filter: alpha(opacity = 0);
-o-transition: background-color 0.2s linear, opacity 0.2s linear;
-webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
-moz-transition: background-color 0.2s linear, opacity 0.2s linear;
transition: background-color 0.2s linear, opacity 0.2s linear;
}
*/
    /*This is the original*/
    /*
* Container style
*/
    
    .ps {
        overflow: hidden !important;
        overflow-anchor: none;
        -ms-overflow-style: none;
        touch-action: auto;
        -ms-touch-action: auto;
    }
    /*
* Scrollbar rail styles
*/
    
    .ps__rail-x {
        display: none;
        opacity: 0;
        transition: background-color .2s linear, opacity .2s linear;
        -webkit-transition: background-color .2s linear, opacity .2s linear;
        height: 15px;
        /* there must be 'bottom' or 'top' for ps__rail-x */
        bottom: 0px;
        /* please don't change 'position' */
        position: absolute;
    }
    
    .ps__rail-y {
        display: none;
        opacity: 0;
        transition: background-color .2s linear, opacity .2s linear;
        -webkit-transition: background-color .2s linear, opacity .2s linear;
        width: 15px;
        /* there must be 'right' or 'left' for ps__rail-y */
        right: 0;
        /* please don't change 'position' */
        position: absolute;
    }
    
    .ps--active-x>.ps__rail-x,
    .ps--active-y>.ps__rail-y {
        display: block;
        background-color: transparent;
    }
    
    .ps:hover>.ps__rail-x,
    .ps:hover>.ps__rail-y,
    .ps--focus>.ps__rail-x,
    .ps--focus>.ps__rail-y,
    .ps--scrolling-x>.ps__rail-x,
    .ps--scrolling-y>.ps__rail-y {
        opacity: 0.6;
    }
    
    .ps .ps__rail-x:hover,
    .ps .ps__rail-y:hover,
    .ps .ps__rail-x:focus,
    .ps .ps__rail-y:focus,
    .ps .ps__rail-x.ps--clicking,
    .ps .ps__rail-y.ps--clicking {
        background-color: #eee;
        opacity: 0.9;
    }
    /*
* Scrollbar thumb styles
*/
    
    .ps__thumb-x {
        background-color: #aaa;
        border-radius: 6px;
        transition: background-color .2s linear, height .2s ease-in-out;
        -webkit-transition: background-color .2s linear, height .2s ease-in-out;
        height: 6px;
        /* there must be 'bottom' for ps__thumb-x */
        bottom: 2px;
        /* please don't change 'position' */
        position: absolute;
    }
    
    .ps__thumb-y {
        background-color: #aaa;
        border-radius: 6px;
        transition: background-color .2s linear, width .2s ease-in-out;
        -webkit-transition: background-color .2s linear, width .2s ease-in-out;
        width: 6px;
        /* there must be 'right' for ps__thumb-y */
        right: 2px;
        /* please don't change 'position' */
        position: absolute;
    }
    
    .ps__rail-x:hover>.ps__thumb-x,
    .ps__rail-x:focus>.ps__thumb-x,
    .ps__rail-x.ps--clicking .ps__thumb-x {
        background-color: #999;
        height: 11px;
    }
    
    .ps__rail-y:hover>.ps__thumb-y,
    .ps__rail-y:focus>.ps__thumb-y,
    .ps__rail-y.ps--clicking .ps__thumb-y {
        background-color: #999;
        width: 11px;
    }
    /* MS supports */
    
    @supports (-ms-overflow-style: none) {
        .ps {
            overflow: auto !important;
        }
    }
    
    @media screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
        .ps {
            overflow: auto !important;
        }
    }
    
    .scrollbar-container {
        position: relative;
        height: 100%;
    }
    /*# sourceMappingURL=styles.min.css.map*/
    /*******************
Preloader
********************/
    
    .preloader {
        width: 100%;
        height: 100%;
        top: 0px;
        position: fixed;
        z-index: 99999;
        background: #fff;
    }
    
    .lds-ripple {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;
        position: absolute;
        top: calc(50% - 3.5px);
        left: calc(50% - 3.5px);
    }
    
    .lds-ripple .lds-pos {
        position: absolute;
        border: 2px solid #007bff;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.1, 0.5, 1) infinite;
    }
    
    .lds-ripple .lds-pos:nth-child(2) {
        animation-delay: -0.5s;
    }
    
    @keyframes lds-ripple {
        0% {
            top: 28px;
            left: 28px;
            width: 0;
            height: 0;
            opacity: 0;
        }
        5% {
            top: 28px;
            left: 28px;
            width: 0;
            height: 0;
            opacity: 1;
        }
        100% {
            top: -1px;
            left: -1px;
            width: 58px;
            height: 58px;
            opacity: 0;
        }
    }
    
    .btn {
        position: relative;
        transition: all 0.2s ease-in-out;
    }
    
    .btn:hover {
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        transform: translateY(-1px);
        -webkit-transform: translateY(-1px);
        -moz-transform: translateY(-1px);
        -o-transform: translateY(-1px);
    }
    
    .btn:focus,
    .btn.focus {
        outline: 0;
        box-shadow: none;
    }
    
    .btn-circle {
        border-radius: 100%;
        width: 40px;
        height: 40px;
        padding: 10px;
    }
    
    .btn-circle:not(:disabled):not(.disabled):active,
    .btn-circle:not(:disabled):not(.disabled).active {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
    
    .btn-circle:not(:disabled):not(.disabled):active:focus,
    .btn-circle:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08), inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
    
    .btn-circle.btn-sm,
    .btn-group-sm>.btn-circle.btn {
        width: 35px;
        height: 35px;
        padding: 8px 10px;
        font-size: 14px;
    }
    
    .btn-circle.btn-lg,
    .btn-group-lg>.btn-circle.btn {
        width: 50px;
        height: 50px;
        padding: 14px 15px;
        font-size: 18px;
        line-height: 23px;
    }
    
    .btn-circle.btn-xl {
        width: 70px;
        height: 70px;
        padding: 14px 15px;
        font-size: 24px;
    }
    
    .btn-rounded {
        border-radius: 60px;
    }
    
    .btn-rounded:not(:disabled):not(.disabled):active,
    .btn-rounded:not(:disabled):not(.disabled).active {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
    
    .btn-rounded:not(:disabled):not(.disabled):active:focus,
    .btn-rounded:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08), inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
    
    .btn-rounded.btn-lg,
    .btn-group-lg>.btn-rounded.btn {
        padding: 0.75rem 1.5rem;
    }
    
    .btn-rounded.btn-sm,
    .btn-group-sm>.btn-rounded.btn {
        padding: 0.25rem 0.75rem;
        font-size: 12px;
    }
    
    .btn-rounded.btn-md {
        padding: 12px 35px;
        font-size: 16px;
    }
    
    .btn-facebook {
        color: #fff;
        background-color: #3b5999;
        border-color: #3b5999;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    
    .btn-facebook:hover {
        color: #fff;
        background-color: #30497d;
        border-color: #2d4474;
    }
    
    .btn-facebook:focus,
    .btn-facebook.focus {
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0.2rem rgba(59, 89, 153, 0.5);
    }
    
    .btn-facebook.disabled,
    .btn-facebook:disabled {
        color: #fff;
        background-color: #3b5999;
        border-color: #3b5999;
    }
    
    .btn-facebook:not(:disabled):not(.disabled):active,
    .btn-facebook:not(:disabled):not(.disabled).active,
    .show>.btn-facebook.dropdown-toggle {
        color: #fff;
        background-color: #2d4474;
        border-color: #293e6b;
    }
    
    .btn-facebook:not(:disabled):not(.disabled):active:focus,
    .btn-facebook:not(:disabled):not(.disabled).active:focus,
    .show>.btn-facebook.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(59, 89, 153, 0.5);
    }
    
    .btn-twitter {
        color: #fff;
        background-color: #1da1f2;
        border-color: #1da1f2;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    
    .btn-twitter:hover {
        color: #fff;
        background-color: #0d8ddc;
        border-color: #0c85d0;
    }
    
    .btn-twitter:focus,
    .btn-twitter.focus {
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0.2rem rgba(29, 161, 242, 0.5);
    }
    
    .btn-twitter.disabled,
    .btn-twitter:disabled {
        color: #fff;
        background-color: #1da1f2;
        border-color: #1da1f2;
    }
    
    .btn-twitter:not(:disabled):not(.disabled):active,
    .btn-twitter:not(:disabled):not(.disabled).active,
    .show>.btn-twitter.dropdown-toggle {
        color: #fff;
        background-color: #0c85d0;
        border-color: #0b7ec4;
    }
    
    .btn-twitter:not(:disabled):not(.disabled):active:focus,
    .btn-twitter:not(:disabled):not(.disabled).active:focus,
    .show>.btn-twitter.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(29, 161, 242, 0.5);
    }
    
    .btn-googleplus {
        color: #fff;
        background-color: #dd4b39;
        border-color: #dd4b39;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    
    .btn-googleplus:hover {
        color: #fff;
        background-color: #cd3623;
        border-color: #c23321;
    }
    
    .btn-googleplus:focus,
    .btn-googleplus.focus {
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0.2rem rgba(221, 75, 57, 0.5);
    }
    
    .btn-googleplus.disabled,
    .btn-googleplus:disabled {
        color: #fff;
        background-color: #dd4b39;
        border-color: #dd4b39;
    }
    
    .btn-googleplus:not(:disabled):not(.disabled):active,
    .btn-googleplus:not(:disabled):not(.disabled).active,
    .show>.btn-googleplus.dropdown-toggle {
        color: #fff;
        background-color: #c23321;
        border-color: #b7301f;
    }
    
    .btn-googleplus:not(:disabled):not(.disabled):active:focus,
    .btn-googleplus:not(:disabled):not(.disabled).active:focus,
    .show>.btn-googleplus.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(221, 75, 57, 0.5);
    }
    
    .btn-instagram {
        color: #fff;
        background-color: #e4405f;
        border-color: #e4405f;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    
    .btn-instagram:hover {
        color: #fff;
        background-color: #de1f44;
        border-color: #d31e40;
    }
    
    .btn-instagram:focus,
    .btn-instagram.focus {
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0.2rem rgba(228, 64, 95, 0.5);
    }
    
    .btn-instagram.disabled,
    .btn-instagram:disabled {
        color: #fff;
        background-color: #e4405f;
        border-color: #e4405f;
    }
    
    .btn-instagram:not(:disabled):not(.disabled):active,
    .btn-instagram:not(:disabled):not(.disabled).active,
    .show>.btn-instagram.dropdown-toggle {
        color: #fff;
        background-color: #d31e40;
        border-color: #c81c3d;
    }
    
    .btn-instagram:not(:disabled):not(.disabled):active:focus,
    .btn-instagram:not(:disabled):not(.disabled).active:focus,
    .show>.btn-instagram.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(228, 64, 95, 0.5);
    }
    
    .btn-pinterest {
        color: #fff;
        background-color: #bd081c;
        border-color: #bd081c;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    
    .btn-pinterest:hover {
        color: #fff;
        background-color: #980617;
        border-color: #8c0615;
    }
    
    .btn-pinterest:focus,
    .btn-pinterest.focus {
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0.2rem rgba(189, 8, 28, 0.5);
    }
    
    .btn-pinterest.disabled,
    .btn-pinterest:disabled {
        color: #fff;
        background-color: #bd081c;
        border-color: #bd081c;
    }
    
    .btn-pinterest:not(:disabled):not(.disabled):active,
    .btn-pinterest:not(:disabled):not(.disabled).active,
    .show>.btn-pinterest.dropdown-toggle {
        color: #fff;
        background-color: #8c0615;
        border-color: #800513;
    }
    
    .btn-pinterest:not(:disabled):not(.disabled):active:focus,
    .btn-pinterest:not(:disabled):not(.disabled).active:focus,
    .show>.btn-pinterest.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(189, 8, 28, 0.5);
    }
    
    .btn-youtube {
        color: #fff;
        background-color: #cd201f;
        border-color: #cd201f;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    
    .btn-youtube:hover {
        color: #fff;
        background-color: #ac1b1a;
        border-color: #a11918;
    }
    
    .btn-youtube:focus,
    .btn-youtube.focus {
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0.2rem rgba(205, 32, 31, 0.5);
    }
    
    .btn-youtube.disabled,
    .btn-youtube:disabled {
        color: #fff;
        background-color: #cd201f;
        border-color: #cd201f;
    }
    
    .btn-youtube:not(:disabled):not(.disabled):active,
    .btn-youtube:not(:disabled):not(.disabled).active,
    .show>.btn-youtube.dropdown-toggle {
        color: #fff;
        background-color: #a11918;
        border-color: #961717;
    }
    
    .btn-youtube:not(:disabled):not(.disabled):active:focus,
    .btn-youtube:not(:disabled):not(.disabled).active:focus,
    .show>.btn-youtube.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(205, 32, 31, 0.5);
    }
    
    .btn-linkedin {
        color: #fff;
        background-color: #007bb6;
        border-color: #007bb6;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    
    .btn-linkedin:hover {
        color: #fff;
        background-color: #006190;
        border-color: #005983;
    }
    
    .btn-linkedin:focus,
    .btn-linkedin.focus {
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0.2rem rgba(0, 123, 182, 0.5);
    }
    
    .btn-linkedin.disabled,
    .btn-linkedin:disabled {
        color: #fff;
        background-color: #007bb6;
        border-color: #007bb6;
    }
    
    .btn-linkedin:not(:disabled):not(.disabled):active,
    .btn-linkedin:not(:disabled):not(.disabled).active,
    .show>.btn-linkedin.dropdown-toggle {
        color: #fff;
        background-color: #005983;
        border-color: #005076;
    }
    
    .btn-linkedin:not(:disabled):not(.disabled):active:focus,
    .btn-linkedin:not(:disabled):not(.disabled).active:focus,
    .show>.btn-linkedin.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 123, 182, 0.5);
    }
    
    .btn-slack {
        color: #fff;
        background-color: #3aaf85;
        border-color: #3aaf85;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    
    .btn-slack:hover {
        color: #fff;
        background-color: #30926f;
        border-color: #2d8968;
    }
    
    .btn-slack:focus,
    .btn-slack.focus {
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0.2rem rgba(58, 175, 133, 0.5);
    }
    
    .btn-slack.disabled,
    .btn-slack:disabled {
        color: #fff;
        background-color: #3aaf85;
        border-color: #3aaf85;
    }
    
    .btn-slack:not(:disabled):not(.disabled):active,
    .btn-slack:not(:disabled):not(.disabled).active,
    .show>.btn-slack.dropdown-toggle {
        color: #fff;
        background-color: #2d8968;
        border-color: #2a7f61;
    }
    
    .btn-slack:not(:disabled):not(.disabled):active:focus,
    .btn-slack:not(:disabled):not(.disabled).active:focus,
    .show>.btn-slack.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(58, 175, 133, 0.5);
    }
    
    .btn-dribbble {
        color: #fff;
        background-color: #ea4c89;
        border-color: #ea4c89;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    
    .btn-dribbble:hover {
        color: #fff;
        background-color: #e62a72;
        border-color: #e51e6b;
    }
    
    .btn-dribbble:focus,
    .btn-dribbble.focus {
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0.2rem rgba(234, 76, 137, 0.5);
    }
    
    .btn-dribbble.disabled,
    .btn-dribbble:disabled {
        color: #fff;
        background-color: #ea4c89;
        border-color: #ea4c89;
    }
    
    .btn-dribbble:not(:disabled):not(.disabled):active,
    .btn-dribbble:not(:disabled):not(.disabled).active,
    .show>.btn-dribbble.dropdown-toggle {
        color: #fff;
        background-color: #e51e6b;
        border-color: #dc1a65;
    }
    
    .btn-dribbble:not(:disabled):not(.disabled):active:focus,
    .btn-dribbble:not(:disabled):not(.disabled).active:focus,
    .show>.btn-dribbble.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(234, 76, 137, 0.5);
    }
    
    .btn-github {
        color: #fff;
        background-color: #222222;
        border-color: #222222;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    
    .btn-github:hover {
        color: #fff;
        background-color: #0f0f0f;
        border-color: #090909;
    }
    
    .btn-github:focus,
    .btn-github.focus {
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
    }
    
    .btn-github.disabled,
    .btn-github:disabled {
        color: #fff;
        background-color: #222222;
        border-color: #222222;
    }
    
    .btn-github:not(:disabled):not(.disabled):active,
    .btn-github:not(:disabled):not(.disabled).active,
    .show>.btn-github.dropdown-toggle {
        color: #fff;
        background-color: #090909;
        border-color: #020202;
    }
    
    .btn-github:not(:disabled):not(.disabled):active:focus,
    .btn-github:not(:disabled):not(.disabled).active:focus,
    .show>.btn-github.dropdown-toggle:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
    }
    
    .btn-group .btn,
    .input-group .btn {
        margin-right: 0;
        transform: translateY(0);
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -o-transform: translateY(0);
    }
    
    .button-group .btn {
        margin-bottom: 5px;
    }
    
    .no-button-group .btn {
        margin-bottom: 5px;
        margin-right: 0px;
    }
    
    .btn .text-active {
        display: none;
    }
    
    .btn.active .text-active {
        display: inline-block;
    }
    
    .btn.active .text {
        display: none;
    }
    /*******************/
    /*Comment widgets*/
    /*******************/
    
    .comment-widgets {
        position: relative;
        margin-bottom: 10px;
    }
    
    .comment-widgets .comment-row {
        border-bottom: 1px solid transparent;
        padding: 14px;
        display: flex;
        margin: 10px 0;
    }
    
    .comment-widgets .comment-row:last-child {
        border-bottom: 0px;
    }
    
    .comment-widgets .comment-row:hover,
    .comment-widgets .comment-row.active {
        background: rgba(0, 0, 0, 0.03);
    }
    
    .comment-text {
        padding-left: 15px;
        width: 100%;
    }
    
    .comment-text:hover .comment-footer .action-icons,
    .comment-text.active .comment-footer .action-icons {
        visibility: visible;
    }
    
    .comment-text p {
        max-height: 65px;
        width: 100%;
        overflow: hidden;
    }
    
    .comment-footer .action-icons {
        visibility: hidden;
    }
    
    .comment-footer .action-icons a {
        padding-left: 7px;
        vertical-align: middle;
        color: #adb5bd;
    }
    
    .comment-footer .action-icons a:hover,
    .comment-footer .action-icons a.active {
        color: #4fc3f7;
    }
    
    .feed-widget .feed-body .feed-item {
        padding: 12px 0;
        display: flex;
        align-items: center;
    }
    
    .feed-widget .feed-body .feed-item:hover {
        background: #f6f9fc;
    }
    
    .feed-widget .feed-body .feed-item>.feed-icon {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        border-radius: 100%;
        color: #fff;
    }
    
    .feed-widget .feed-body .feed-item>.feed-icon i {
        line-height: 40px;
    }
    
    #main-wrapper[data-layout='vertical'],
    #main-wrapper[data-layout='horizontal'] {
        /*This is for the logo bg*/
        /*This is for the sidebar bg*/
    }
    
    #main-wrapper[data-layout='vertical'] .topbar .top-navbar .navbar-header[data-logobg='skin1'],
    #main-wrapper[data-layout='horizontal'] .topbar .top-navbar .navbar-header[data-logobg='skin1'] {
        background: #2c6daa;
    }
    
    #main-wrapper[data-layout='vertical'] .topbar .top-navbar .navbar-header[data-logobg='skin2'],
    #main-wrapper[data-layout='horizontal'] .topbar .top-navbar .navbar-header[data-logobg='skin2'] {
        background: #fe5419;
    }
    
    #main-wrapper[data-layout='vertical'] .topbar .top-navbar .navbar-header[data-logobg='skin3'],
    #main-wrapper[data-layout='horizontal'] .topbar .top-navbar .navbar-header[data-logobg='skin3'] {
        background: #00b0ff;
    }
    
    #main-wrapper[data-layout='vertical'] .topbar .top-navbar .navbar-header[data-logobg='skin4'],
    #main-wrapper[data-layout='horizontal'] .topbar .top-navbar .navbar-header[data-logobg='skin4'] {
        background: #6659f7;
    }
    
    #main-wrapper[data-layout='vertical'] .topbar .top-navbar .navbar-header[data-logobg='skin5'],
    #main-wrapper[data-layout='horizontal'] .topbar .top-navbar .navbar-header[data-logobg='skin5'] {
        background: #414755;
    }
    
    #main-wrapper[data-layout='vertical'] .topbar .top-navbar .navbar-header[data-logobg='skin6'],
    #main-wrapper[data-layout='horizontal'] .topbar .top-navbar .navbar-header[data-logobg='skin6'] {
        background: #fff;
    }
    
    #main-wrapper[data-layout='vertical'] .topbar .top-navbar .navbar-header[data-logobg='skin6'] .navbar-brand .dark-logo,
    #main-wrapper[data-layout='horizontal'] .topbar .top-navbar .navbar-header[data-logobg='skin6'] .navbar-brand .dark-logo {
        display: inline;
    }
    
    #main-wrapper[data-layout='vertical'] .topbar .top-navbar .navbar-header[data-logobg='skin6'] .navbar-brand .light-logo,
    #main-wrapper[data-layout='horizontal'] .topbar .top-navbar .navbar-header[data-logobg='skin6'] .navbar-brand .light-logo {
        display: none;
    }
    
    #main-wrapper[data-layout='vertical'] .topbar .top-navbar .navbar-header[data-logobg='skin6'] .nav-toggler,
    #main-wrapper[data-layout='vertical'] .topbar .top-navbar .navbar-header[data-logobg='skin6'] .topbartoggler,
    #main-wrapper[data-layout='horizontal'] .topbar .top-navbar .navbar-header[data-logobg='skin6'] .nav-toggler,
    #main-wrapper[data-layout='horizontal'] .topbar .top-navbar .navbar-header[data-logobg='skin6'] .topbartoggler {
        color: #3e5569;
    }
    
    #main-wrapper[data-layout='vertical'] .topbar .navbar-collapse[data-navbarbg='skin1'],
    #main-wrapper[data-layout='vertical'] .topbar[data-navbarbg='skin1'],
    #main-wrapper[data-layout='horizontal'] .topbar .navbar-collapse[data-navbarbg='skin1'],
    #main-wrapper[data-layout='horizontal'] .topbar[data-navbarbg='skin1'] {
        background: #2c6daa;
    }
    
    #main-wrapper[data-layout='vertical'] .topbar .navbar-collapse[data-navbarbg='skin2'],
    #main-wrapper[data-layout='vertical'] .topbar[data-navbarbg='skin2'],
    #main-wrapper[data-layout='horizontal'] .topbar .navbar-collapse[data-navbarbg='skin2'],
    #main-wrapper[data-layout='horizontal'] .topbar[data-navbarbg='skin2'] {
        background: #fe5419;
    }
    
    #main-wrapper[data-layout='vertical'] .topbar .navbar-collapse[data-navbarbg='skin3'],
    #main-wrapper[data-layout='vertical'] .topbar[data-navbarbg='skin3'],
    #main-wrapper[data-layout='horizontal'] .topbar .navbar-collapse[data-navbarbg='skin3'],
    #main-wrapper[data-layout='horizontal'] .topbar[data-navbarbg='skin3'] {
        background: #00b0ff;
    }
    
    #main-wrapper[data-layout='vertical'] .topbar .navbar-collapse[data-navbarbg='skin4'],
    #main-wrapper[data-layout='vertical'] .topbar[data-navbarbg='skin4'],
    #main-wrapper[data-layout='horizontal'] .topbar .navbar-collapse[data-navbarbg='skin4'],
    #main-wrapper[data-layout='horizontal'] .topbar[data-navbarbg='skin4'] {
        background: #6659f7;
    }
    
    #main-wrapper[data-layout='vertical'] .topbar .navbar-collapse[data-navbarbg='skin5'],
    #main-wrapper[data-layout='vertical'] .topbar[data-navbarbg='skin5'],
    #main-wrapper[data-layout='horizontal'] .topbar .navbar-collapse[data-navbarbg='skin5'],
    #main-wrapper[data-layout='horizontal'] .topbar[data-navbarbg='skin5'] {
        background: #414755;
    }
    
    #main-wrapper[data-layout='vertical'] .topbar .navbar-collapse[data-navbarbg='skin6'],
    #main-wrapper[data-layout='vertical'] .topbar[data-navbarbg='skin6'],
    #main-wrapper[data-layout='horizontal'] .topbar .navbar-collapse[data-navbarbg='skin6'],
    #main-wrapper[data-layout='horizontal'] .topbar[data-navbarbg='skin6'] {
        background: #fff;
    }
    
    #main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin1'],
    #main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin1'] ul,
    #main-wrapper[data-layout='horizontal'] .left-sidebar[data-sidebarbg='skin1'],
    #main-wrapper[data-layout='horizontal'] .left-sidebar[data-sidebarbg='skin1'] ul {
        background: #2c6daa;
    }
    
    #main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin1'] .create-btn,
    #main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin1'] ul .create-btn,
    #main-wrapper[data-layout='horizontal'] .left-sidebar[data-sidebarbg='skin1'] .create-btn,
    #main-wrapper[data-layout='horizontal'] .left-sidebar[data-sidebarbg='skin1'] ul .create-btn {
        background: #4fc3f7;
    }
    
    #main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin2'],
    #main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin2'] ul,
    #main-wrapper[data-layout='horizontal'] .left-sidebar[data-sidebarbg='skin2'],
    #main-wrapper[data-layout='horizontal'] .left-sidebar[data-sidebarbg='skin2'] ul {
        background: #fe5419;
    }
    
    #main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin2']::after,
    #main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin2'] ul::after,
    #main-wrapper[data-layout='horizontal'] .left-sidebar[data-sidebarbg='skin2']::after,
    #main-wrapper[data-layout='horizontal'] .left-sidebar[data-sidebarbg='skin2'] ul::after {
        background: url(../../assets/images/background/sidebarbg.png) no-repeat;
        position: absolute;
        top: 0px;
        content: '';
        width: 100%;
        height: 100%;
        opacity: 0.1;
        z-index: -1;
    }
    
    #main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin3'],
    #main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin3'] ul,
    #main-wrapper[data-layout='horizontal'] .left-sidebar[data-sidebarbg='skin3'],
    #main-wrapper[data-layout='horizontal'] .left-sidebar[data-sidebarbg='skin3'] ul {
        background: #00b0ff;
    }
    
    #main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin4'],
    #main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin4'] ul,
    #main-wrapper[data-layout='horizontal'] .left-sidebar[data-sidebarbg='skin4'],
    #main-wrapper[data-layout='horizontal'] .left-sidebar[data-sidebarbg='skin4'] ul {
        background: #6659f7;
    }
    
    #main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin5'],
    #main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin5'] ul,
    #main-wrapper[data-layout='horizontal'] .left-sidebar[data-sidebarbg='skin5'],
    #main-wrapper[data-layout='horizontal'] .left-sidebar[data-sidebarbg='skin5'] ul {
        background: #414755;
    }
    
    #main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin5'] .create-btn,
    #main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin5'] ul .create-btn,
    #main-wrapper[data-layout='horizontal'] .left-sidebar[data-sidebarbg='skin5'] .create-btn,
    #main-wrapper[data-layout='horizontal'] .left-sidebar[data-sidebarbg='skin5'] ul .create-btn {
        background: #4fc3f7;
    }
    
    #main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin6'],
    #main-wrapper[data-layout='horizontal'] .left-sidebar[data-sidebarbg='skin6'] {
        background: #fff;
    }
    
    #main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin6'] .sidebar-nav ul,
    #main-wrapper[data-layout='horizontal'] .left-sidebar[data-sidebarbg='skin6'] .sidebar-nav ul {
        background: #fff;
    }
    
    #main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin6'] .sidebar-nav ul .sidebar-item .sidebar-link,
    #main-wrapper[data-layout='horizontal'] .left-sidebar[data-sidebarbg='skin6'] .sidebar-nav ul .sidebar-item .sidebar-link {
        color: #212529;
    }
    
    #main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin6'] .sidebar-nav ul .sidebar-item .sidebar-link i,
    #main-wrapper[data-layout='horizontal'] .left-sidebar[data-sidebarbg='skin6'] .sidebar-nav ul .sidebar-item .sidebar-link i {
        color: #4f5467;
    }
    
    #main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin6'] .sidebar-nav ul .nav-small-cap,
    #main-wrapper[data-layout='horizontal'] .left-sidebar[data-sidebarbg='skin6'] .sidebar-nav ul .nav-small-cap {
        color: #212529;
        opacity: 0.7;
    }
    
    #main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin6'] .sidebar-nav .has-arrow::after,
    #main-wrapper[data-layout='horizontal'] .left-sidebar[data-sidebarbg='skin6'] .sidebar-nav .has-arrow::after {
        border-color: #212529;
    }
    
    #main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin6'] .sidebar-nav .user-content,
    #main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin6'] .sidebar-nav .user-name,
    #main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin6'] .sidebar-nav .user-email,
    #main-wrapper[data-layout='horizontal'] .left-sidebar[data-sidebarbg='skin6'] .sidebar-nav .user-content,
    #main-wrapper[data-layout='horizontal'] .left-sidebar[data-sidebarbg='skin6'] .sidebar-nav .user-name,
    #main-wrapper[data-layout='horizontal'] .left-sidebar[data-sidebarbg='skin6'] .sidebar-nav .user-email {
        color: #212529;
    }
    
    #main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin6'] .create-btn,
    #main-wrapper[data-layout='horizontal'] .left-sidebar[data-sidebarbg='skin6'] .create-btn {
        background: #4fc3f7;
    }
    /*============================================================== 
For all pages 
============================================================== */
    
    #main-wrapper {
        width: 100% !important;
        /*BE: margin-left: 1px !important;*/
    }
    
    .page-wrapper {
        background: #eef5f9;
        position: relative;
        transition: 0.2s ease-in;
        display: none;
    }
    
    .page-wrapper>.page-content {
        padding: 30px;
        /* min-height: 100vh; V2 */
        min-height: 70vh;
        /*calc(100vh - 116px);*/
        overflow: hidden;
        padding-bottom: 0px;
    }
    
    .page-breadcrumb {
        padding: 30px 30px 0px;
    }
    /*******************
Footer
*******************/
    /*******************
Stickey inner-left-part
*******************/
    
    .left-part {
        height: calc(100vh - 220px);
        width: 260px;
        position: absolute;
        border-right: 1px solid #e9ecef;
    }
    
    .left-part.fixed-left-part {
        position: fixed;
        top: 0px;
        padding-top: 10px;
    }
    
    .left-part .show-left-part {
        position: absolute;
        top: 45%;
        right: -41px;
    }
    
    .right-part {
        width: calc(100% - 260px);
        height: calc(100vh - 220px);
        overflow: auto;
        margin-left: 260px;
    }
    
    .reverse-mode .left-part {
        right: 0;
        border-left: 1px solid #e9ecef;
    }
    
    .reverse-mode .show-left-part {
        right: auto;
        left: -41px;
    }
    
    .reverse-mode .right-part {
        margin-left: 0px;
        margin-right: 260px;
    }
    /*******************
Vertical layout
*******************/
    
    #main-wrapper[data-layout='vertical'] {
        /*Vertical*/
        /*sidebar type*/
        /*Sidebar position*/
        /*Header position*/
        /*Boxed Layout*/
    }
    
    #main-wrapper[data-layout='vertical'] .topbar .top-navbar .navbar-header {
        transition: 0.2s ease-in;
    }
    
    #main-wrapper[data-layout='vertical'][data-sidebartype='full'] .page-wrapper {
        /*BE: margin-left: 250px;*/
    }
    
    #main-wrapper[data-layout='vertical'][data-sidebartype='iconbar'] .page-wrapper {
        margin-left: 180px;
    }
    
    #main-wrapper[data-layout='vertical'][data-sidebartype='iconbar'] .left-sidebar,
    #main-wrapper[data-layout='vertical'][data-sidebartype='iconbar'] .topbar .top-navbar .navbar-header,
    #main-wrapper[data-layout='vertical'][data-sidebartype='iconbar'] .sidebar-nav ul .sidebar-item {
        width: 180px;
    }
    
    #main-wrapper[data-layout='vertical'][data-sidebartype='iconbar'] .sidebar-nav ul .sidebar-item .sidebar-link {
        display: block;
        text-align: center;
    }
    
    #main-wrapper[data-layout='vertical'][data-sidebartype='iconbar'] .sidebar-nav ul .sidebar-item .sidebar-link i {
        display: block;
        width: auto;
    }
    
    #main-wrapper[data-layout='vertical'][data-sidebartype='overlay'] .topbar .top-navbar .navbar-header {
        width: 250px;
    }
    
    #main-wrapper[data-layout='vertical'][data-sidebartype='overlay'] .left-sidebar {
        left: -250px;
    }
    
    #main-wrapper[data-layout='vertical'][data-sidebartype='overlay'].show-sidebar .left-sidebar {
        left: 0;
    }
    
    #main-wrapper[data-layout='vertical'][data-sidebar-position='fixed'] .topbar .top-navbar .navbar-header {
        position: fixed;
        z-index: 10;
    }
    
    #main-wrapper[data-layout='vertical'][data-sidebar-position='fixed'] .left-sidebar {
        position: fixed;
        z-index: 999999;
    }
    
    #main-wrapper[data-layout='vertical'][data-header-position='fixed'] .topbar {
        position: fixed;
        width: 100%;
    }
    
    #main-wrapper[data-layout='vertical'][data-header-position='fixed'] .page-wrapper {
        padding-top: 64px;
    }
    
    #main-wrapper[data-layout='vertical'][data-boxed-layout='boxed'] {
        max-width: 1200px;
        margin: 0 auto;
        position: relative;
        box-shadow: 1px 0 80px rgba(0, 0, 0, 0.2);
    }
    
    #main-wrapper[data-layout='vertical'][data-boxed-layout='boxed'][data-header-position='fixed'] .topbar {
        max-width: 1200px;
    }
    /*******************
Vertical responsive layout
*******************/
    
    @media (min-width: 800px) {
        #main-wrapper {
            /*Header position*/
            width: 100% !important;
        }
        #main-wrapper[data-layout='vertical'] {
            /*Vertical*/
            /*Sidebar position*/
            /*Vertical with mini-sidebar*/
            /*Fixed sidebar with minisidebar*/
            width: 100% !important;
            /*BE:*/
        }
        #main-wrapper[data-layout='vertical'][data-sidebartype='full'] .topbar .top-navbar .navbar-header {
            width: 250px;
        }
        #main-wrapper[data-layout='vertical'][data-sidebar-position='fixed'][data-sidebartype='full'] .topbar .top-navbar .navbar-collapse,
        #main-wrapper[data-layout='vertical'][data-sidebar-position='fixed'][data-sidebartype='overlay'] .topbar .top-navbar .navbar-collapse {
            margin-left: 250px;
        }
        #main-wrapper[data-layout='vertical'][data-sidebar-position='fixed'][data-sidebartype='mini-sidebar'] .topbar .top-navbar .navbar-collapse {
            /* margin-left: 65px; */
            margin-left: -9px;
        }
        #main-wrapper[data-layout='vertical'][data-sidebar-position='fixed'][data-sidebartype='iconbar'] .topbar .top-navbar .navbar-collapse {
            margin-left: 180px;
        }
        #main-wrapper[data-layout='vertical'][data-sidebartype='mini-sidebar'] .topbar .top-navbar .navbar-header {
            /* width: 65px; */
            width: 100%;
        }
        #main-wrapper[data-layout='vertical'][data-sidebartype='mini-sidebar'] .topbar .top-navbar .navbar-header .logo-text {
            display: none;
        }
        #main-wrapper[data-layout='vertical'][data-sidebartype='mini-sidebar'] .topbar .top-navbar .navbar-header.expand-logo {
            /* width: 250px; */
            width: 100%;
        }
        #main-wrapper[data-layout='vertical'][data-sidebartype='mini-sidebar'] .topbar .top-navbar .navbar-header.expand-logo .logo-text {
            display: block;
        }
        #main-wrapper[data-layout='vertical'][data-sidebar-position='fixed'][data-sidebartype='mini-sidebar'] .topbar .top-navbar .navbar-collapse {
            /* margin-left: 65px; */
            margin-left: -9px;
        }
        #main-wrapper[data-layout='vertical'][data-sidebartype='mini-sidebar'] .page-wrapper {
            /*margin-left: 65px;*/
        }
        #main-wrapper[data-layout='vertical'][data-sidebartype='mini-sidebar'] .sidebar-nav .hide-menu,
        #main-wrapper[data-layout='vertical'][data-sidebartype='mini-sidebar'] .sidebar-nav .has-arrow:after {
            display: none;
        }
        #main-wrapper[data-layout='vertical'][data-sidebartype='mini-sidebar'] .sidebar-nav .nav-small-cap {
            justify-content: center;
        }
        #main-wrapper[data-layout='vertical'][data-sidebartype='mini-sidebar'] .left-sidebar {
            width: 65px;
            overflow: hidden;
        }
        #main-wrapper[data-layout='vertical'][data-sidebartype='mini-sidebar'] .left-sidebar:hover {
            width: 250px;
        }
        #main-wrapper[data-layout='vertical'][data-sidebartype='mini-sidebar'] .left-sidebar:hover .sidebar-nav .hide-menu,
        #main-wrapper[data-layout='vertical'][data-sidebartype='mini-sidebar'] .left-sidebar:hover .sidebar-nav .has-arrow:after {
            display: block;
        }
        #main-wrapper[data-layout='vertical'][data-sidebartype='mini-sidebar'] .left-sidebar:hover .sidebar-nav .nav-small-cap {
            justify-content: flex-start;
        }
        #main-wrapper[data-layout='vertical'][data-sidebartype='mini-sidebar'] .left-sidebar:hover .first-level .sidebar-item .sidebar-link i {
            visibility: hidden;
        }
        #main-wrapper[data-layout='vertical'][data-sidebartype='mini-sidebar'] .left-sidebar:hover .second-level .sidebar-item {
            padding: 0 0 0 20px;
        }
        #main-wrapper[data-layout='vertical'][data-sidebartype='mini-sidebar'] .left-sidebar .first-level .sidebar-item .sidebar-link i {
            visibility: visible;
        }
        #main-wrapper[data-layout='vertical'][data-sidebartype='mini-sidebar'] .left-sidebar .second-level .sidebar-item {
            padding: 0 0 0 0px;
        }
    }
    
    @media (max-width: 799.98px) {
        #main-wrapper {
            /*Header position*/
            width: 100% !important;
            overflow-x: scroll !important;
            position: absolute !important;
            left: 0px;
        }
        #main-wrapper>div {
            width: 100% !important;
        }
        #main-wrapper>.page-wrapper {
            width: 100% !important;
        }
        #main-wrapper[data-sidebartype='mini-sidebar'] .left-sidebar {
            right: -100%;
        }
        /*BE:*/
        #main-wrapper.show-sidebar .left-sidebar {
            right: 0;
        }
        #main-wrapper[data-layout='vertical'][data-sidebar-position='fixed'] .topbar .top-navbar .navbar-collapse {
            position: relative;
            top: 64px;
        }
        #main-wrapper[data-layout='vertical'][data-header-position='fixed'][data-sidebar-position='fixed'] .topbar .top-navbar .navbar-collapse {
            position: relative;
            top: 64px;
        }
        .left-part {
            right: -260px;
            background: #eef5f9;
            z-index: 1;
            top: 64px;
            height: calc(100vh - 66px);
            box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
            position: fixed;
            transition: 0.1s ease-in;
        }
        .left-part.show-panel {
            right: 0px;
        }
        .reverse-mode .left-part {
            left: -260px;
            right: auto;
        }
        .reverse-mode .left-part.show-panel {
            left: 0px;
        }
        .right-part {
            width: 10%;
            margin-right: 0px;
            height: 500px;
        }
    }
    
    .topbar {
        position: relative;
        z-index: 50;
        background: #fff;
        height: 64px;
    }
    
    .topbar .navbar-collapse {
        padding: 0px 10px 0 0;
    }
    
    .topbar .top-navbar {
        min-height: 64px;
        padding: 0px;
    }
    
    .topbar .top-navbar .dropdown-toggle::after {
        display: none;
    }
    
    .topbar .top-navbar .navbar-header {
        line-height: 64px;
    }
    
    .topbar .top-navbar .navbar-header .navbar-brand {
        display: flex;
        align-items: center;
        margin: 0px;
        padding: 0 10px 0 10px;
    }
    
    .topbar .top-navbar .navbar-header .navbar-brand .dark-logo {
        display: none;
    }
    
    .topbar .top-navbar .navbar-header .navbar-brand .logo-icon {
        margin-right: 5px;
    }
    
    .topbar .top-navbar .navbar-nav>.nav-item>.nav-link {
        padding: 0px 15px;
        font-size: 0.875rem;
        line-height: 64px;
        height: 64px;
    }
    
    .topbar .top-navbar .navbar-nav>.nav-item {
        transition: 0.2s ease-in;
    }
    
    .topbar .top-navbar .navbar-nav>.nav-item:hover {
        background: rgba(0, 0, 0, 0.03);
    }
    
    .topbar .user-dd,
    .topbar .mailbox {
        min-width: 280px;
    }
    
    .topbar .nav-toggler,
    .topbar .topbartoggler {
        color: #fff;
        padding: 0 15px;
        border: 0;
    }
    
    .search-box .app-search {
        z-index: 110;
        width: 100%;
        top: -1px;
        box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
        display: none;
        left: 0px;
    }
    
    .search-box .app-search .form-control {
        padding: 32px 40px 32px 23px;
        transition: 0.2s ease-in;
    }
    
    .search-box .app-search .form-control:focus {
        border-color: transparent;
    }
    
    .search-box .app-search .srh-btn {
        position: absolute;
        top: 23px;
        cursor: pointer;
        right: 20px;
        border: 0;
    }
    /*******************
Megamenu
******************/
    
    .topbar .mega-dropdown {
        position: static;
    }
    
    .topbar .mega-dropdown .dropdown-menu {
        padding: 30px;
        width: 100%;
        max-height: 480px;
        overflow: auto;
    }
    /*******************
Designed dropdown
******************/
    
    .topbar .dropdown-menu {
        padding-top: 0px;
        border: 0px;
        box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
    }
    
    .topbar .dropdown-menu .with-arrow {
        position: absolute;
        height: 10px;
        overflow: hidden;
        width: 40px;
        top: -10px;
    }
    
    .topbar .dropdown-menu .with-arrow>span {
        background-color: #fff;
        width: 15px;
        height: 15px;
        top: 3px;
        left: 15px;
        position: absolute;
        content: '';
        -moz-border-radius: 6px 0 0 0;
        border-radius: 6px 0 0 0;
        /*box-shadow:-3px -3px 8px rgba(0, 0, 0, 0.1);    */
    }
    
    .topbar .dropdown-menu.dropdown-menu-right .with-arrow {
        right: 0px;
    }
    
    .topbar .dropdown-menu.dropdown-menu-right .with-arrow>span {
        right: 20px;
        left: auto;
    }
    /*******************
Topbar Responsive
******************/
    
    @media (max-width: 1025px) {
        .topbar .top-navbar .navbar-collapse.show,
        .topbar .top-navbar .navbar-collapse.collapsing {
            display: block;
            width: 100%;
            border-top: 1px solid #e9ecef;
        }
        .topbar .top-navbar .navbar-nav {
            flex-direction: row;
        }
        .topbar .top-navbar .navbar-nav .dropdown {
            position: static;
        }
        .topbar .top-navbar .navbar-nav>.nav-item>.nav-link {
            padding: 0 10px;
        }
        .topbar .top-navbar .navbar-header {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
        }
        .topbar .top-navbar .dropdown-menu {
            position: absolute;
            width: 100%;
        }
        .topbar .top-navbar .dropdown-menu .with-arrow {
            display: none;
        }
    }
    /*******************
Main sidebar
******************/
    
    .left-sidebar {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        z-index: 100;
        background: #fff;
        box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
        transition: 0.2s ease-in;
    }
    /*******************
sidebar navigation
******************/
    
    .scroll-sidebar {
        height: 100%;
        /*calc(100% - 20px);*/
        overflow: auto;
    }
    
    .sidebar-nav ul {
        /*******************
      Small cap
      ******************/
    }
    
    .sidebar-nav ul .sidebar-item {
        width: 250px;
        /*******************
        First level
        ******************/
    }
    
    .sidebar-nav ul .sidebar-item .sidebar-link {
        color: #fff;
        padding: 12px 15px;
        display: flex;
        white-space: nowrap;
        align-items: center;
        line-height: 25px;
        opacity: 1;
        cursor: pointer;
    }
    
    .sidebar-nav ul .sidebar-item .sidebar-link i {
        font-style: normal;
        width: 35px;
        line-height: 25px;
        font-size: 20px;
        color: #fff;
        display: inline-block;
        text-align: center;
    }
    
    .sidebar-nav ul .sidebar-item .sidebar-link.active,
    .sidebar-nav ul .sidebar-item .sidebar-link:hover {
        opacity: 1;
    }
    
    .sidebar-nav ul .sidebar-item.selected>.sidebar-link,
    .sidebar-nav ul .sidebar-item:hover>.sidebar-link {
        /*background: rgba(0, 0, 0, 0.04);*/
        opacity: 1;
    }
    
    .sidebar-nav ul .sidebar-item.selected>a>span {
        color: #5775fe;
    }
    
    .sidebar-nav ul .sidebar-item .first-level {
        padding: 0 0 10px 0;
    }
    
    .sidebar-nav ul .sidebar-item .first-level .sidebar-item.active .sidebar-link {
        opacity: 1;
    }
    
    .sidebar-nav ul .sidebar-item .first-level .sidebar-item .sidebar-link {
        padding: 10px 15px;
    }
    
    .sidebar-nav ul .sidebar-item .first-level .sidebar-item .sidebar-link i {
        font-size: 14px;
        visibility: hidden;
    }
    
    .sidebar-nav ul .sidebar-item .second-level .sidebar-item {
        padding: 0 0 0px 20px;
    }
    
    .sidebar-nav ul .nav-small-cap {
        font-size: 12px;
        padding: 12px 15px;
        white-space: nowrap;
        display: flex;
        align-items: center;
        line-height: 30px;
        margin-top: 10px;
        color: #fff;
        opacity: 1;
        text-transform: uppercase;
    }
    
    .sidebar-nav ul .nav-small-cap i {
        line-height: 30px;
        margin: 0 5px;
    }
    /*******************
common m-p removed
******************/
    
    .sidebar-nav ul {
        margin: 0px;
        padding: 0px;
    }
    
    .sidebar-nav ul li {
        list-style: none;
    }
    
    .collapse.in {
        display: block;
    }
    /*******************
Down arrow
******************/
    
    .sidebar-nav .has-arrow {
        position: relative;
    }
    
    .sidebar-nav .has-arrow::after {
        position: absolute;
        content: '';
        width: 7px;
        height: 7px;
        border-width: 1px 0 0 1px;
        border-style: solid;
        border-color: #fff;
        margin-left: 10px;
        -webkit-transform: rotate(135deg) translate(0, -50%);
        -ms-transform: rotate(135deg) translate(0, -50%);
        -o-transform: rotate(135deg) translate(0, -50%);
        transform: rotate(135deg) translate(0, -50%);
        -webkit-transform-origin: top;
        -ms-transform-origin: top;
        -o-transform-origin: top;
        transform-origin: top;
        top: 26px;
        right: 15px;
        -webkit-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
    }
    /*.sidebar-nav .active > .has-arrow::after,*/
    /*.sidebar-nav li.selected > .has-arrow::after,*/
    
    .sidebar-nav li.active>.has-arrow::after,
    .sidebar-nav li>.has-arrow.active::after,
    .sidebar-nav .has-arrow[aria-expanded='true']::after {
        -webkit-transform: rotate(-135deg) translate(0, -50%);
        -ms-transform: rotate(-135deg) translate(0, -50%);
        -o-transform: rotate(-135deg) translate(0, -50%);
        transform: rotate(-135deg) translate(0, -50%);
    }
    /*******************
User Profile
******************/
    
    .user-profile {
        padding: 15px;
    }
    
    .user-profile .user-content,
    .user-profile .user-name,
    .user-profile .user-email {
        color: #fff;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    /********************* GENERAL *******************/
    
    .page-wrapper {
        background: #f0f3ff !important;
    }
    /********************* GENERAL *******************/
    /********************* HEADER *******************/
    
    .title-menu>span {
        color: #fff;
        margin-right: 10px;
    }
    
    .title-menu>i {
        color: #fff;
        font-size: 20px;
        margin-right: 10px;
    }
    
    .title-menu>.notification {
        width: 10px;
        height: 10px;
        background: #FE9D57;
        border-radius: 10px;
        position: absolute;
        top: 55px;
        right: 22px;
    }
    
    .navbar-header {
        background: #02224F !important;
        /* main-color */
        position: relative !important;
        height: 140px;
    }
    
    .logo {
        position: absolute;
        text-align: center;
        width: 100%;
        z-index: -1;
    }
    
    .logo-left {
        margin-top: -4px;
        padding-left: 20px;
        position: absolute;
        text-align: left;
        width: 40%;
        z-index: -1;
    }

    .logo-left>img{
        width: 58% !important;
    }
    
    .header-detail {
        left: 0px;
        position: absolute;
        width: 100%;
    }
    
    .header-detail2 {
        bottom: -165px;
        left: 0px;
        position: absolute;
        width: 100%;
    }
    /********************* HEADER *******************/
    /********************* REEL *******************/
    
    .reel-hidden-scroll {
        /* margin-top: 55px; V2 */
        margin-top: 0px;
        margin-left: -30px;
        margin-right: -30px;
        overflow-x: hidden;
    }
    
    .reel::-webkit-scrollbar {
        display: none;
    }
    
    .reel {
        width: 100%;
        height: 175px;
        white-space: nowrap;
        position: relative;
        overflow-x: scroll;
        overflow-y: hidden;
        /* padding-left: 20px; */
        -webkit-overflow-scrolling: touch;
        z-index: 99;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
        padding-left:7%;
    }
    
    .reel div.option {
        display: inline-block;
        text-align: center;
        width: 28%;
    }
    
    .option-name {
        display: block !important;
        font-size: 14px;
        line-height: 100%;
        margin-top: -29px;
    }
    
    .option-name>span {
        color: #000;
    }
    /********************* REEL *******************/
    /********************* SIDEBAR *******************/
    
    .menu-title {
        color: #5775FE;
        padding: 26px;
        font-size: 20px;
        font-weight: bold;
    }
    
    .menu-secundary-text {
        padding: 26px;
        font-size: 16px;
        font-weight: bold;
    }
    
    .close-sidebar {
        float: right;
        font-size: 22px;
        font-weight: bold;
        margin-right: 23px;
    }
    
    .login-sidebar {
        bottom: 0px;
        /*border: 2px solid #9C9C9C;*/
        box-sizing: border-box;
        border-radius: 6px;
        margin-bottom: 40px;
        left: 5%;
        width: 90%;
        text-align: center;
        position: absolute;
    }
    
    .login-sidebar>div>button {
        font-size: 16px !important;
    }
    
    .login-sidebar>div>button>span {
        font-weight: bold !important;
    }
    
    #sidebarnav {
        padding-top: 10px;
        width: 100%;
    }
    
    #sidebarnav>li {
        padding: 0px 0px 0px 20px;
        width: 100%;
    }
    
    #sidebarnav>li>a>span {
        /* color: #2E6FB5; */
        /* font-size: 16px;  V2 */
        font-size: 12px;
        font-weight: bold;
        text-decoration: underline #2E6FB5;
    }
    
    #sidebarnav>li>a>img {
        padding-right: 10px;
    }
    /********************* SIDEBAR *******************/
    /********************* HOME *******************/
    
    .header-home {
        /* background: #02224F !important; */
        /* main-color */
        font-family: sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 121.3%;
        color: #3B4D6A;
        height: 105px;
        margin: -10px 0px 100px -30px;
        padding: 0px;
        width: 120%;
        padding: 30px;
        position: relative;
    }
    
    .header-section-home {
        font-size: 14px;
        font-weight: bold;
        margin-left: -14px;
        padding-bottom: 10px;
        text-align: center;
    }
    
    .header-section-home>hr {
        margin-bottom: -11px;
    }
    
    .header-section-home>span {
        background: #f0f3ff;
        padding: 0px 10px 0px 10px;
    }
    
    .article-box {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 4px 18px rgba(2, 34, 79, 0.15);
        margin-left: -14px;
        margin-bottom: 20px;
        width: 107% !important;
    }
    
    .article-box-img {
        vertical-align: middle;
        width: 100%;
    }
    
    .article-box-img>img {
        border-radius: 10px 10px 0px 0px;
        width: 100%;
    }
    
    .article-box-content {
        padding: 10px 10px 10px 10px;
        width: 100%;
    }
    
    .article-box-claps {}
    
    .article-box-claps>img {
        width: 45px;
        padding: 10px;
    }
    
    .article-box-type {
        background-color: #7CC97F;
        border-radius: 10px;
        color: #fff;
        width: 40%;
        text-align: center;
    }
    
    .article-box-text {
        color: #5775FE;
        font-weight: bold;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 16px;
        padding: 0px 10px 10px 10px;
    }
    
    .article-box-detail {
        color: #5775FE;
        text-decoration: underline;
        padding: 0px 10px 10px 10px;
    }
    
    .footer {
        /* background-color: #5775FE; */
        height: 250px;
        /* margin: 150px 0px -30px -30px; V2 */
        margin: 50px 0px -30px -30px;
        /*250px*/
        width: 100%;
    }
    
    .footer-title {
        color: #fff;
        padding: 10px 0px 20px 0px;
        margin-left: 10px;
        text-align: center;
        /*width: 100%;*/
    }
    
    .footer-other-information {
        padding-left: 30px;
        padding-top: 0px !important;
    }
    
    .footer-other-information>li>a>span {
        color: #C2D1FF !important;
        line-height: 40px;
        text-decoration: underline #02224F !important;
    }
    
    .footer-img {
        /* margin-top: -155px; V2*/
        margin-top: 44px;
        position: absolute;
        width: 108%;
        z-index: 99;
    }
    
    .footer-img>img {
        width: 100%;
    }
    
    .footer-content {
        bottom: -35px;
        padding-top: 20px;
        position: absolute;
        z-index: 99;
        width: 110%;
    }
    /********************* HOME *******************/
    /********************* PAGE *******************/
    
    .page-header {
        margin: 45px 0px 20px -30px;
        width: 119%;
    }
    
    .page-header>img {
        width: 100%;
    }
    
    .page-title {
        color: #000;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 30px;
        font-weight: bold;
        line-height: 35px;
        /*68px;*/
        padding-bottom: 15px;
    }
    
    .page-title-notification {
        color: #000;
        line-height: 35px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 25px;
        font-weight: bold;
    }
    
    .page-title-article {
        color: #3B4D6A;
        line-height: 35px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 30px;
        font-weight: bold;
    }
    
    .page-text-article {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 20px;
    }
    
    .page-text-article>span {
        color: #000;
    }
    
    .page-text {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 18px;
        text-align: justify;
    }
    
    .page-text.title {
        font-size: 20;
    }
    
    .page-text-bold {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-weight: bold;
        font-size: 18px;
    }
    
    .page-text>span {
        color: #5775FE;
    }
    
    .page-title-no-header-img {
        color: #000;
        line-height: 68px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 30px;
        font-weight: bold;
        margin-top: 45px;
    }
    
    .textarea-form-notification {
        height: 150px !important;
    }
    
    .button-form-notification {
        height: 80px !important;
    }
    /********************* PAGE *******************/
    /********************* BUTTONS *******************/
    
    .styled-button {
        background-color: rgb(255, 255, 255);
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.54);
        box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
        padding: 0px;
        border-radius: 9px;
        border: 2px solid #3636367d;
        font-size: 14px;
        font-weight: 500;
        font-family: Roboto, sans-serif;
        width: 70%;
        margin-bottom: 20px;
        margin-top: 20px;
        text-align: center;
    }
    
    .div-styled-button {
        margin-right: 10px;
        background: rgb(255, 255, 255);
        padding: 10px;
        border-radius: 10px;
    }
    
    .div-styled-button>img {
        width: 30px;
    }
    
    .text-styled-button {
        padding: 10px 10px 10px 0px;
        font-weight: bold;
        text-align: center;
        width: 100%;
        margin: 0px 0px 0px -40px;
    }
    
    .text-styled-button.no-icon {
        margin: 0px 0px 0px 0px;
    }
    
    .google-button {
        width: 100%;
        text-align: center;
        height: 50px;
        border-radius: 10px !important;
    }
    
    .google-button>span {
        width: 80% !important;
        font-weight: bold !important;
    }
    /********************* BUTTONS *******************/
    /********************* NOTIFICATIONS *******************/
    
    .accept-notifications {
        background: linear-gradient(180deg, #043273 0%, #02224F 100%);
        border: 1px solid #5775FE;
        box-sizing: border-box;
        box-shadow: 0px 4px 18px rgba(2, 34, 79, 0.2);
        border-radius: 10px;
        color: #fff;
        display: inline;
        font-size: 20px;
        font-weight: bold;
        height: 70px;
        margin-bottom: 10px;
        margin-top: 20px;
        width: 100%;
    }
    
    .arrow-back-info {
        position: absolute;
        left: 10px;
        top: 10px;
        width: 20% !important;
    }
    
    .notification-content-title {
        color: #5775FE;
        font-weight: bold;
        margin-bottom: 15px;
        margin-top: 15px;
    }
    
    .file-form-notification {
        background-color: rgb(255, 255, 255);
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.54);
        box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
        padding: 0px;
        border-radius: 9px;
        border: 2px solid #3636367d;
        font-size: 14px;
        font-weight: 500;
        font-family: Roboto, sans-serif;
        width: 100%;
        margin-bottom: -5px;
        /* margin-left: -15px; */
        /* margin-top: 20px; */
        text-align: center;
    }
    
    .div-file-form-notification {
        background: rgb(255, 255, 255);
        border-radius: 10px;
        margin-right: 10px;
        padding: 10px;
    }
    
    .div-file-form-notification>img {
        width: 30px;
    }
    
    .text-file-form-notification {
        padding: 10px 10px 10px 0px;
        font-weight: bold;
        text-align: center;
        width: 100%;
        margin: 0px 0px 0px -40px;
    }
    
    .validate-selection {
        color: #5775fe;
        font-weight: bold;
    }
    
    #details {
        font-size: 18px;
    }
    /*************/
    
    .notification-validation-box {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 4px 18px rgba(2, 34, 79, 0.15);
        display: flex;
        margin-bottom: 20px;
        padding: 7px;
        width: 100% !important;
    }
    
    .notification-validation-box-img {
        display: inline-block;
        vertical-align: middle;
        width: 30%;
    }
    
    .notification-validation-box-img>img {
        border-radius: 10px;
        border: solid #5775fe 3px;
    }
    
    .notification-validation-box-content {
        display: inline-block;
        padding-left: 10px;
        width: 80%;
        display: table;
    }
    
    .notification-validation-box-text {
        color: #000;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 16px;
        display: table-cell;
        text-align: left;
        height: 70px;
        vertical-align: middle;
    }
    
    .selected-impact {
        background-color: #5775fe;
    }
    
    .selected-impact>div>div {
        color: #fff;
    }
    /*************/
    /*************/
    
    .notification-box {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 4px 18px rgba(2, 34, 79, 0.15);
        display: flex;
        margin-left: -14px;
        margin-bottom: 20px;
        padding: 15px;
        width: 110% !important;
    }
    
    .notification-box-img {
        display: inline-block;
        vertical-align: middle;
        width: 20%;
    }
    
    .notification-box-content {
        display: inline-block;
        padding-left: 10px;
        width: 80%;
    }
    
    .notification-box-type {
        border-radius: 10px;
        width: 40%;
        text-align: center;
    }
    
    .notification-box-time {
        color: #000;
        float: right;
        margin-top: -23px;
    }
    
    .notification-box-text {
        color: #000;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 16px;
    }
    
    .notification-box-detail {
        color: #5775FE;
        text-decoration: underline;
        margin-left: -29%;
        margin-top: 24px;
    }
    
    .notification-box-img>.validate {
        border-radius: 10px;
    }
    /*************/
    /********************* NOTIFICATIONS *******************/
    /********************* SPLASH-SCREEN *******************/
    
    .guip-splash-screen {
        background: #001c4f;
        height: 100%;
        padding-top: 1%;
        width: 100%;
    }
    
    body {
        height: 100%;
        min-height: 100%;
    }
    
    html {
        height: 100%;
        min-height: 100%;
    }
    
    #root {
        height: 100%;
        min-height: 100%;
    }
    /********************* SPLASH-SCREEN *******************/
    /********************* SHARE *******************/
    
    .profile-box {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 4px 18px rgba(2, 34, 79, 0.15);
        display: flex;
        margin-left: -14px;
        margin-bottom: 20px;
        padding: 15px;
        width: 110% !important;
    }
    
    .profile-box-img {
        display: inline-block;
        vertical-align: middle;
        width: 20%;
    }
    
    .profile-box-content {
        display: inline-block;
        padding-left: 10px;
        width: 80%;
    }
    
    .profile-box-text {
        color: #000;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 16px;
    }
    
    .profile-box-detail {
        color: #5775FE;
        font-size: 12px;
        margin-left: -80px;
        margin-top: 22px;
        text-align: center;
    }
    
    .article-box-detail.tutorial {
        color: #000;
        text-decoration: none;
    }
    
    .hero {
        width: 100% !important;
        margin-left: 0px !important;
        margin-top: 10px;
    }
    /********************* SHARE *******************/
    /********************* TUTORIAL *******************/
    
    .styled-button-tutorial {
        background: #2E6FB5;
        display: flex !important;
        align-items: center !important;
        color: #fff !important;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px !important;
        padding: 0px !important;
        border-radius: 9px !important;
        border: 2px solid #3636367d !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        font-family: Roboto, sans-serif !important;
        width: 100%;
        margin-bottom: 20px !important;
        margin-top: 20px !important;
        text-align: center !important;
    }
    
    .styled-button-tutorial>a {
        text-align: center;
        padding: 10px;
        width: 100%;
    }
    
    .text-styled-button-tutorial {
        color: #fff !important;
        padding: 10px 10px 10px 0px !important;
        font-weight: bold !important;
        text-align: center !important;
        width: 100% !important;
        margin: 0px 0px 0px 0px !important;
    }
    
    .text-styled-button-tutorial>a {
        color: #fff !important;
    }
    
    .styled-button-tutorial.navigation {
        background: #B2EEFF !important;
    }
    
    .text-styled-button-tutorial.navigation {
        color: #484848 !important;
    }
    
    .ws {
        position: fixed;
        right: 10px;
        bottom: 32px;
        width: 40px;
        z-index: 99999;
    }
    
    .styled-button-tutorial.link-shape-diver {
        width: 60%;
    }
    
    .calc-input {
        border: 2px solid #797979;
        border-radius: 10px;
        display: inline;
        height: 55px;
        margin-bottom: 10px;
        width: 100%;
    }
    
    .download-pdf {
        background: #2E6FB5;
        border: 1px solid #5775FE;
        box-sizing: border-box;
        box-shadow: 0px 4px 18px rgba(2, 34, 79, 0.2);
        border-radius: 10px;
        color: #fff;
        display: inline;
        font-size: 19px;
        font-weight: bold;
        height: 70px;
        margin-bottom: 10px;
        margin-top: 20px;
        width: 100%;
    }
    
    .download-pdf>img {
        margin-left: -50px;
        margin-top: -12px;
    }
    /********************* TUTORIAL *******************/
    
    .notification-validation-box-img.assemble {
        width: 40% !important;
    }
    
    .learn-option {
        display: inline-block;
        text-align: center;
        margin-left: -10px;
    }
    
    .learn-option-name {
        background: #5775FE;
        border-radius: 60px;
        color: #C2D1FF;
        display: inline-block;
        font-size: 38px;
        font-weight: bold;
        height: 84px;
        margin: 10px;
        padding-top: 4px;
        text-align: center !important;
        width: 84px;
        box-shadow: 0px 4px 18px rgba(2, 34, 79, 0.55);
    }
    
    .learn-option-name>div {
        font-size: 11px !important;
        font-weight: normal !important;
        margin-top: -10px;
    }
    /********************* OTHERS (REVIEW) *******************/
    
    .required {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 20px;
        margin-top: -10px;
    }
    
    .create-btn {
        height: 40px;
        background: #32325d;
    }
    
    .arrow {
        float: right
    }
    
    .title-box {
        border: 1px solid #DFDFDF;
        box-sizing: border-box;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.41);
    }
    
    .google-button {
        background-color: rgb(255, 255, 255);
        display: inline-flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.54);
        box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
        padding: 0px;
        border-radius: 2px;
        border: 1px solid transparent;
        font-size: 14px;
        font-weight: bold !important;
        font-family: Roboto, sans-serif;
    }
    
    .div-google-button {
        margin-right: 10px;
        background: rgb(255, 255, 255);
        padding: 10px;
        border-radius: 2px;
    }
    
    .text-google-button {
        padding: 10px 10px 10px 0px;
        font-weight: bold;
    }
    
    .notifications {
        font-family: sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 29px;
        line-height: 121.3%;
        /* or 35px */
        color: #3B4D6A;
    }
    
    .header-news-title {
        font-size: 21px;
        font-weight: lighter;
        color: #484848;
    }
    
    .news-arrow-down {
        display: inline-block;
        color: #2E6FB5;
        font-size: 14px;
        font-weight: lighter;
        text-align: center;
        width: 100%;
    }
    
    .news-footer {
        background: #65DEFF;
        font-family: sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 121.3%;
        color: #3B4D6A;
        height: 400px;
        margin: -10px 0px 30px -30px;
        padding: 0px;
        width: 120%;
        padding: 110px 0px 0px 30px;
        position: relative;
    }
    
    .news-footer>div {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 20px;
    }
    
    .news-footer>#sidebarnav>li {
        border-bottom: none;
    }
    
    .news-footer>img.reflect {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        transform: rotate(180deg);
    }
    
    .title-box {
        color: #363636;
    }
    
    .news-title {
        font-size: 25px;
        font-family: sans-serif;
        font-weight: bold;
        line-height: 150%;
        margin-bottom: 20px;
    }
    
    .news-n-img {
        padding-top: 20px;
    }
    
    .news-n-text {
        font-size: 18px;
    }
    
    .news-footer.n {
        padding: 110px 0px 0px 10px;
        height: 550px;
    }
    /********************* OTHERS *******************/
    
    .logo-name {
        margin-bottom: -12px;
        font-size: 12px;
        margin-top: -15px;
    }
    
    .footer-logotipo {
        margin-left: 55px;
        text-align: left;
        margin-bottom: -10px;
    }
    
    @media (min-width: 500px) {
        .reel div.option {
            width: 18%;
        }
        .footer-content {
            bottom: -200px;
            font-size: 20px;
            width: 100%;
        }
    }
    
    @media (min-width: 900px) {
        .footer-content {
            bottom: -300px;
            font-size: 20px;
            width: 100%;
        }
    }
    
    @media (min-width: 800px) {
        .reel div.option {
            width: 18%;
        }
        .header-home {
            width: 106% !important;
        }
        .page-header {
            width: 105%;
        }
        .notification-box {
            width: 102% !important;
        }
        .article-box {
            width: 102% !important;
        }
        .footer-img {
            width: 103% !important;
        }
        .footer-content {
            bottom: -350px;
            font-size: 24px;
            width: 100%;
        }
        .footer-content>#sidebarnav>li>a>span {
            font-size: 26px;
        }
        .footer-title {}
        .footer-logotipo>img {
            width: 10%;
        }
        #main-wrapper[data-sidebartype='mini-sidebar'] .left-sidebar {
            right: -100%;
        }
        #main-wrapper.show-sidebar .left-sidebar {
            right: 0;
        }
        #main-wrapper[data-layout='vertical'][data-sidebartype='mini-sidebar'] .left-sidebar {
            width: 40%;
        }
        #main-wrapper[data-layout='vertical'][data-sidebartype='mini-sidebar'] .left-sidebar:hover {
            width: 40%;
        }
        #main-wrapper[data-layout='vertical'][data-sidebartype='mini-sidebar'] .sidebar-nav .hide-menu,
        #main-wrapper[data-layout='vertical'][data-sidebartype='mini-sidebar'] .sidebar-nav .has-arrow:after {
            display: block;
        }
        .show-sidebar>.left-sidebar {
            position: absolute !important;
            right: -100px !important;
            z-index: 99999999999 !important;
        }
    }
    /********************* LANDING *******************/
    
    .landing-header {
        background: #02224f;
        margin: -30px;
        text-align: left;
        width: 100%;
        padding-left: 7%;
        padding-top: 20px;
        padding-bottom: 20px !important;
        min-height: 400px;
        position: absolute;
        top: 0%;
    }
    
    .landing-content {
        font-size: 14px;
        font-weight: bold;
        /* min-height: 1600px; */
        /* margin: 100px; */
        width: 100%;
        text-align: justify;
        /* position: absolute; */
        top: -3%;
        padding: 0px 10% 0px 10%;
        /* margin-bottom: 424px;*/
    }
    
    .dark-logo.landing {
        padding-bottom: 20px;
        width: 150px;
        position: relative;
        top: -5%;
    }
    
    .logo-name.landing {
        color: #fff;
        font-size: 20px;
        width: 100%;
        position: relative;
        top: -4%;
        font-weight: normal;
    }
    
    .landing-content-text {
        font-weight: normal;
        padding: 0px;
        margin-top: 0px;
        display: inline-block;
    }
    
    .landing-video {
        float: right;
        position: relative;
        right: 0%;
        top: -16%;
        width: 50%;
        box-shadow: 0px 35px 20px -7px rgba(0, 0, 0, 0.15);
    }
    
    .landing-ws {
        color: #fff;
        font-size: 16px;
        font-weight: lighter;
        float: right;
        position: relative;
        top: -6px;
    }
    
    .landing-ws>img {
        width: 30px;
    }
    
    .landing-mobile-message {
        font-size: 16px;
        line-height: 18px;
        display: flex;
        padding-top: 10px;
        margin-left: -8px;
    }
    
    .landing-mobile-message>div {
        padding-top: 17px;
    }
    
    .landing-footer {
        position: absolute;
        background: #02224f;
        color: #fff;
        height: 120px;
        padding: 20px;
        text-align: center;
        width: 100%;
        bottom: 0px;
        text-align: center;
        font-weight: bold;
        font-size: 12px;
    }
    
    .landing-more-information {
        position: initial;
        display: flow-root;
        bottom: 160px;
        height: 130px;
        left: -30px;
        width: 95%;
        vertical-align: bottom;
    }
    
    .landing-more-information-img1 {
        display: inline-block;
        position: absolute;
        width: 100%;
        bottom: 120px;
    }
    
    .landing-more-information-img2>img {
        position: absolute;
        right: 0px;
        bottom: 120px;
    }
    
    .landing-more-information-text {
        display: inline-block;
        width: 100%;
        bottom: 144px;
        position: absolute;
        margin: 0px 10% 0px 10%;
        width: 80%;
    }
    
    .landing-mobile-message>div>div {
        color: #5775FE;
        display: inline;
    }
    
    .landing-more-information-text>center>div {
        padding-top: 20px;
    }
    
    .landing-more-information-text>center>div>img {
        padding: 10px;
    }
    
    .landing-sponsors {
        width: 100%;
        position: initial;
        bottom: 480px;
        height: 130px;
        padding: 0px 10% 0px 10%;
        height: 416px;
    }
    
    .landing-sponsors>center>div>img {
        padding: 0px 5px 0px 5px;
    }
    
    .landing-bottom {
        position: relative;
        bottom: 0px;
        width: 100%;
        /* margin-left: -30px; */
        left: 0px;
        margin-left: -16%;
        width: 132%;
        margin-top: 70px;
    }
    
    #display img {
        display: block;
    }
    
    .landing-content-title {
        /* padding-top: 2%; */
    }
    
    .landing-content-text>div>img {
        width: 100%;
        margin-left: 0%;
        /*         width: 103.5%;
     margin-left: -1.5%; */
        max-width: 103.5%;
    }
    
    .landing-content-description {
        margin-top: 15px;
    }
    
    .landing-more-information-icons>a {
        padding: 5px;
    }
    
    .landing-more-information-icons>a>img {
        width: 40px;
    }
    
    .video-guip {
        width: 100% !important;
        height: 350px !important;
    }
    
    .landing-option-name {
        /* background: #5775FE; */
        border-radius: 106px;
        color: #C2D1FF;
        display: inline-block;
        font-size: 38px;
        font-weight: bold;
        height: 84px;
        margin: 10px;
        /* padding-top: 4px; */
        text-align: center !important;
        width: 84px;
        box-shadow: 0px 4px 18px rgba(2, 34, 79, 0.55);
    }
    
    .car {
        font-size: 12px;
        color: #000;
        position: absolute;
        width: 200px;
        margin-left: -57px;
        margin-top: 11px;
        text-align: center;
        font-weight: normal;
        /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
    }
    
    .car>b {
        text-align: center !important;
        font-size: 14px;
    }
    
    .elem {
        display: inline-block;
        margin: 0px 100px 0px 100px;
    }
    /*****************************************************/
    
    .logo-video {
        position: relative;
        display: inline-flex;
        width: 100%;
        margin-top: 10px
    }
    
    .logo-message {
        display: block;
        width: 60%;
    }
    
    .landing-video {
        display: block;
        margin-left: 20px;
    }
    
    @media (min-width: 1024px) {
        .page-wrapper>.page-content {
            /*  min-height: 200vh; */
        }
        .landing-more-information {
            width: 99% !important;
        }
        .landing-more-information-img1,
        .landing-more-information-img2 {
            margin-top: -42px;
        }
    }
    
    @media (max-width: 1366px) {
        .landing-header {
            min-height: 18% !important;
        }
        .landing-video {
            top: -17% !important;
        }
        .landing-content {
            /* height: 1546px !important; */
        }
    }
    
    @media (max-width: 370px) {
        .footer-content {
            bottom: -9px;
        }
    }
    
    @media (max-width: 321px) {
        .footer-content {
            bottom: 24px;
        }
    }
    
    @media (min-width: 800px) and (max-width: 1023px) {
        .landing-more-information {
            bottom: 142px !important;
        }
    }
    
    @media (min-width: 1024px) and (max-width: 1174px) {
        .landing-more-information {
            bottom: 135px !important;
        }
    }
    
    @media (min-width: 1175px) and (max-width: 1399px) {
        .landing-more-information {
            bottom: 143px !important;
        }
    }
    
    @media (min-width: 1400px) {
        .landing-more-information {
            bottom: 157px !important;
        }
    }
    
    @media (max-width: 800px) {
        .landing-video {
            width: 100%;
        }
        .landing-content {
            margin-left: 0px;
            margin-right: 0px;
            width: auto !important;
        }
        .landing-ws {
            font-size: 12px;
            float: right;
        }
        .landing-content>.dark-logo {
            width: 40%;
        }
        .landing-content>.logo-name {
            text-align: left;
            width: 100%;
        }
        .landing-header {
            min-height: 21% !important;
        }
        .landing-video {
            top: -1% !important;
        }
        .video-guip {
            width: 100% !important;
            height: 220px !important;
        }
        .landing-content-text {
            margin-top: 0px;
        }
        .landing-content {
            /* height: 1970px !important; */
        }
        .elem {
            /* BE: margin: 0px 100px 105px 100px; */
            margin: 0px 100px 145px 0px;
            display: table-caption;
        }
        .landing-sponsors {
            bottom: 870px;
            height: 1068px;
        }
        .landing-more-information-img1 {
            bottom: 120px;
        }
        .landing-more-information-img2 {
            display: none;
        }
        .logo-video {
            display: inline-block;
        }
        .logo-message {
            width: 100%;
        }
        .landing-video {
            margin-top: 30px;
            width: 100%;
            margin-bottom: 30px;
        }
    }
    
    @media (max-width: 1024px) {
        .landing-content {
            padding: 0px;
        }
    }
    
    @media (max-width: 1000px) {
        .landing-more-information-text {
            bottom: 293px;
        }
    }
    
    @media (max-width: 624px) {
        .landing-sponsors {
            bottom: 1016px;
            height: 1070px;
        }
        .landing-content {
            /* margin-bottom: 505px; */
        }
    }
    
    @media (min-width: 1000px) {
        .landing-sponsors {
            bottom: 637px;
            height: 505px;
        }
    }
    
    @media (min-width: 800px) {
        .landing-sponsors {
            bottom: 575px;
            height: 600px;
        }
    }
    
    @media (min-width: 1150px) {
        .landing-sponsors {
            bottom: 435px;
            height: 470px;
        }
    }
    
    @media (min-width: 801px) and (max-width: 1146px) {
        .elem {
            display: inline-block;
            margin: 0px 80px 0px 80px;
        }
    }
    
    @media (min-width: 801px) and (max-width: 820px) {
        .elem {
            display: inline-block;
            margin: 0px 70px 0px 70px;
        }
    }
    
    @media (max-width: 540px) {
        .landing-content {
            /* margin-bottom: 611px; */
        }
    }
    
    @media (max-width: 414px) {
        .landing-content {
            /* margin-bottom: 796px; */
        }
        .landing-sponsors {
            bottom: 1183px;
            height: 1190px;
        }
    }
    
    @media (max-width: 375px) {
        /*iPhone 678*/
        .landing-content-title>h1 {
            padding-top: 15%;
        }
        .landing-content-title>h1 {
            font-size: 20px;
        }
        .landing-content {
            /* margin-bottom: 800px; */
        }
        .landing-sponsors {
            bottom: 1010px;
        }
        .landing-more-information-text {
            bottom: 279px;
        }
    }
    
    @media (max-width: 320px) {
        .landing-content {
            /* margin-bottom: 1000px; */
        }
        .landing-sponsors {
            bottom: 1060px;
        }
    }
    
    @media (max-width: 280px) {
        .landing-content {
            /* margin-bottom: 1256px; */
        }
        .landing-sponsors {
            bottom: 1060px;
        }
        .learn-option {
            margin-left: -30px;
        }
    }
    /*Las media querys van bajando en ancho*/
    /********************* LANDING *******************/
    /********************* UNBOXING *******************/
    
    .unboxing-header {
        min-height: 177px !important;
    }
    
    .unboxing-main-panel {
        width: 100%;
        background: none;
        /* height: 450px; */
        display: block;
        /*         //Pasar a inline-grid */
    }
    
    .unboxing-main-option-video {
        /* width: 30%; */
        background: none;
        /* height: 400px; */
        display: contents;
        /* margin-left: 1.65%;
     margin-right: 1.68%; */
    }
    
    @media (max-width: 600px) {
        .unboxing-main-panel {
            display: inline-grid;
        }
        .opt1,
        .opt3 {
            width: 100% !important;
            margin-bottom: 30px;
        }
        .opt2 {
            display: none !important;
        }
    }
    
    .unboxing-main-option {
        /* width: 30%; */
        background: none;
        /* height: 400px; */
        display: inline-table;
        /* margin-left: 1.65%;
     margin-right: 1.68%; */
    }
    
    .opt1,
    .opt3 {
        width: 45%;
    }
    
    .opt2 {
        width: 10%;
        padding-left: 1.68%;
        padding-right: 1.68%;
    }
    
    .opt3 {
        text-align: right;
    }
    
    .unboxing-img {
        border-radius: 10px;
        border: 3px solid none;
        box-shadow: 0px 4px 18px rgba(2, 34, 79, 0.2);
    }
    
    .unboxing-img:hover {
        box-shadow: 0px 4px 18px rgba(2, 34, 79, 0.5);
    }
    
    .unboxing-buttons {
        background: linear-gradient(180deg, #043273 0%, #02224F 100%);
        border: 1px solid #5775FE;
        box-sizing: border-box;
        box-shadow: 0px 4px 18px rgba(2, 34, 79, 0.2);
        border-radius: 10px;
        color: #fff;
        display: inline;
        font-size: 15px;
        font-weight: bold;
        height: 37px;
        margin-bottom: 10px;
        margin-top: 20px;
        width: 100%;
    }
    
    .unboxing-main-option>div>a:hover,
    .unboxing-buttons:hover {
        cursor: pointer;
    }
    
    .unboxing-main-panel.video-package {
        text-align: center;
    }
    
    .video-unboxing {
        width: 100% !important;
        height: 350px !important;
    }
    
    .unboxing-main-option.video {
        width: 80%;
    }
    
    .unboxing-bottom {
        position: absolute;
        bottom: 0px;
        width: 100%;
        /* margin-left: -30px; */
        /* left: 0px; */
        margin-left: -11.3%;
        width: 100%;
        margin-top: 70px;
    }
    
    @media (max-width: 1024px) {
        .unboxing-bottom {
            margin-left: -3%;
        }
    }
    
    @media (max-width: 983px) {
        .unboxing-bottom {
            margin-left: -3.3%;
        }
    }
    
    @media (max-width: 893px) {
        .unboxing-bottom {
            margin-left: -3.5%;
        }
    }
    
    @media (max-width: 843px) {
        .unboxing-bottom {
            margin-left: -3.7%;
        }
    }
    
    @media (max-width: 799px) {
        .unboxing-bottom {
            margin-left: -3.9%;
        }
    }
    
    @media (max-width: 773px) {
        .unboxing-bottom {
            margin-left: -4.1%;
        }
    }
    
    @media (max-width: 736px) {
        .unboxing-bottom {
            margin-left: -4.5%;
        }
    }
    
    @media (max-width: 672px) {
        .unboxing-bottom {
            margin-left: -5.1%;
        }
    }
    
    @media (max-width: 594px) {
        .unboxing-bottom {
            margin-left: -5.3%;
        }
    }
    
    @media (max-width: 573px) {
        .unboxing-bottom {
            margin-left: -5.9%;
        }
    }
    
    @media (max-width: 517px) {
        .unboxing-bottom {
            margin-left: -6.2%;
        }
    }
    
    @media (max-width: 493px) {
        .unboxing-bottom {
            margin-left: -6.7%;
        }
    }
    
    @media (max-width: 459px) {
        .unboxing-bottom {
            margin-left: -7.4%;
        }
    }
    
    @media (max-width: 412px) {
        .unboxing-bottom {
            margin-left: -7.8%;
        }
    }
    
    @media (max-width: 395px) {
        .unboxing-bottom {
            margin-left: -8.4%;
        }
    }
    
    @media (max-width: 367px) {
        .unboxing-bottom {
            margin-left: -8.8%;
        }
    }
    
    .landing-content.unboxing {
        margin-bottom: 310px;
    }
    
    .unboxing-buttons-navigate {
        background: linear-gradient(180deg, #043273 0%, #02224F 100%);
        border: 1px solid #5775FE;
        border-radius: 10px;
        box-sizing: border-box;
        box-shadow: 0px 4px 18px rgba(2, 34, 79, 0.2);
        color: #fff;
        display: inline;
        font-size: 10px;
        font-weight: bold;
        height: 37px;
        margin-bottom: 10px;
        margin-top: 20px;
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
    }
    /********************* UNBOXING *******************/
    /*V2*/
    
    .parent {
        display: grid;
        grid-template-columns: repeat(5, 20%);
        grid-template-rows: 4%;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        text-align: center;
        width: 100%;
        height: 8%;
        line-height: normal;
        z-index: 99999999999;
    }
    
    .div1 {
        grid-area: 1 / 1 / 2 / 2;
        background-color: red;
        height: 10vh;
    }
    
    .div2 {
        grid-area: 1 / 2 / 2 / 3;
        background-color: red;
        height: 10vh;
    }
    
    .div3 {
        grid-area: 1 / 3 / 2 / 4;
        background-color: red;
        height: 10vh;
    }
    
    .div4 {
        grid-area: 1 / 4 / 2 / 5;
        background-color: red;
        height: 10vh;
    }
    
    .div5 {
        grid-area: 1 / 5 / 2 / 6;
        background-color: red;
        height: 10vh;
    }
    
    .gridActive {
        color: #fff;
        border-bottom: "solid 1px red";
        border-radius: 10px;
    }
    
    .articles-horizontal {
        display: inline-block;
        margin-right: 55px;
        border-radius: 10px;
    }
    
    .select-version {
        box-sizing: border-box;
        box-shadow: 0px 4px 18px rgba(2, 34, 79, 0.2);
        border-radius: 10px;
        display: inline;
        font-family: Roboto, sans-serif;
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 10px;
        margin-top: 20px;
        width: 78%;
        text-align: center;
        padding: 15px
    }
    
    .select-version.opt-1 {
        background: #bcd3fd;
        color: #4576ff;
        border: 1px solid #bcd3fd;
    }
    
    .select-version.opt-2 {
        background: #96b0ff;
        color: #fff;
        border: 1px solid #96b0ff;
    }
    
    .select-version.opt-3 {
        background: #4576ff;
        color: #fff;
        border: 1px solid #4576ff;
    }
    
    .select-version>span>img {
        padding-bottom: 10px;
    }
    /* HEADER GRID */
    
    .header-grid {
        display: grid;
        grid-template-columns: 20% 20% 20% 20% 20%;
        grid-template-rows: 50% 25%;
        gap: 0px 0px;
        grid-auto-flow: row;
        grid-template-areas: "header-logo-basic header-logo-basic header-logo-basic header-logo-basic header-logo-basic" "header-opt-1 header-opt-2 header-opt-3 header-opt-4 header-opt-5";
        width: 100%;
    }
    
    .header-logo-basic {
        grid-area: header-logo-basic;
        height: 15vh;
        background-color: #02224f;
        text-align: center;
        padding-top: 5px;
        padding-bottom: 15px;
    }
    
    .header-logo-basic>a {
        color: #fff;
        font-family: Roboto, sans-serif;
    }
    
    .header-opt-1 {
        background-color: #95b2fa;
        grid-area: header-opt-1;
        text-align: center;
        border-radius: 15px;
        border: 2px solid #001e4c;
        margin-top: -10px;
        height: 10.7vh;
        padding-top: 7px;
    }
    
    .header-opt-2 {
        background-color: #95b2fa;
        grid-area: header-opt-2;
        text-align: center;
        border-radius: 15px;
        border: 2px solid #001e4c;
        margin-top: -10px;
        height: 10.7vh;
        padding-top: 7px;
    }
    
    .header-opt-3 {
        background-color: #95b2fa;
        grid-area: header-opt-3;
        text-align: center;
        border-radius: 15px;
        border: 2px solid #001e4c;
        margin-top: -10px;
        height: 10.7vh;
        padding-top: 7px;
    }
    
    .header-opt-4 {
        background-color: #95b2fa;
        grid-area: header-opt-4;
        text-align: center;
        border-radius: 15px;
        border: 2px solid #001e4c;
        margin-top: -10px;
        height: 10.7vh;
        padding-top: 7px;
    }
    
    .header-opt-5 {
        background-color: #95b2fa;
        grid-area: header-opt-5;
        text-align: center;
        border-radius: 15px;
        border: 2px solid #001e4c;
        margin-top: -10px;
        height: 10.7vh;
        padding-top: 7px;
    }
    
    .header-grid>a>div {
        color: #fff;
        font-family: Roboto, sans-serif;
        font-size: 11px;
        line-height: normal;
    }
    
    .header-grid>a>div>img {
        margin-bottom: 4px;
    }
    
    .header-opt-active {
        background-color: #4576ff
    }
    
    .comparte {
        background: #4576ff;
        border: 1px solid #5775FE;
        box-sizing: border-box;
        box-shadow: 0px 4px 18px rgba(2, 34, 79, 0.2);
        border-radius: 10px;
        color: #fff;
        display: inline;
        font-size: 14px;
        font-weight: bold;
        height: 90px;
        margin-bottom: 10px;
        margin-top: 20px;
        width: 70%;
        padding: 10px;
    }
    /* prefix */
    
    .input-box {
        display: flex;
        align-items: center;
        max-width: 300px;
        border: none;
        padding-left: 0.5rem;
        overflow: hidden;
        font-family: sans-serif;
    }
    
    .input-box .prefix {
        font-weight: 300;
        font-size: 14px;
        color: #fff;
        background: #5775fe;
        padding: 15px;
        margin-left: -10px;
        border-bottom-left-radius: 12px;
        border-top-left-radius: 12px;
        font-weight: bolder;
    }
    
    .input-box input {
        flex-grow: 1;
        font-size: 14px;
        background: #c2d1ff;
        border: none;
        outline: none;
        padding: 15px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        color: #000;
        font-weight: bolder;
    }
    
    .input-box:focus-within {
        border-color: #777;
    }
    /* / prefix */


    .arrow-articles-horizontal{
        position: absolute;
        z-index: 999;
        width: 21%;
        right: 0px;
        top: 40%;
    }

    .landing-footer > div > div > img{
        width: 5%;
    }
